import { z } from 'zod';

export const OrderLineProductSchema = z.object({
    id: z.number(),
    name: z.string(),
    hasWidth: z.boolean(),
    hasHeight: z.boolean(),
    hasQuantity: z.boolean(),
    context: z.object({
        categoryName: z.string(),
        brandName: z.string(),
    }),
});

export type OrderLineProduct = z.infer<typeof OrderLineProductSchema>;
