import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import React from 'react';
import { formatCurrency } from 'utils/helpers';
import './ScoreboardWidget.scss';

export default function ScoreboardWidget({
    title,
    formatAsCurrency,
    rows,
    tooltip,
}: {
    title: string;
    formatAsCurrency?: boolean;
    rows: {
        id: number;
        name: string;
        value: string | number;
    }[];
    tooltip: React.ReactNode;
}) {
    return (
        <div className="ScoreboardWidget">
            <div className="ScoreboardWidget__Title">
                <h3>{title}</h3>
                {tooltip && (
                    <Tooltip
                        title={tooltip}
                        placement="top"
                        arrow
                    >
                        <span className="ScoreboardWidget__Title__TooltipIcon">
                            <Icons.Info />
                        </span>
                    </Tooltip>
                )}
            </div>
            <div
                role="table"
                className="ScoreboardWidget__Table"
            >
                {rows.map(row => {
                    return (
                        <div
                            role="tablerow"
                            key={row.id}
                            className="ScoreboardWidget__Table__Row"
                        >
                            <div
                                role="tablecell"
                                className="ScoreboardWidget__Table__Cell"
                            >
                                {row.name}
                            </div>
                            <div
                                role="tablecell"
                                className="ScoreboardWidget__Table__Cell ScoreboardWidget__Table__Cell--value"
                            >
                                {(formatAsCurrency
                                    ? formatCurrency(row.value, { trimCents: true })
                                    : row.value) || <>&ndash;</>}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
