import { useEffect } from 'react';

let modalCount = 0;

/** Hook prevents the document body from scrolling while modals are open */
export default function useScrollLock() {
    useEffect(() => {
        modalCount++;
        if (modalCount === 1) {
            document.body.classList.add('scroll-lock');
        }
        return () => {
            modalCount--;
            if (modalCount === 0) {
                document.body.classList.remove('scroll-lock');
            }
        };
    }, []);
}
