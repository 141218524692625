import z from 'zod';

export const DocumentUploadResultDocumentSchema = z.object({
    id: z.number(),
    account_id: z.number(),
    created_at: z.string(),
    direct_upload: z.string(),
    // document_category_id: z.string().nullable(),
    extension: z.string(),
    filename: z.string(),
    mime_type: z.string(),
    name: z.string(),
    size: z.number(),
    type: z.string(),
    updated_at: z.string(),
    url: z.string(),
});

export const DocumentUploadResultSchema = z.object({
    data: z.object({
        uuid: z.string(),
        document: DocumentUploadResultDocumentSchema,
    }),
});

export type DocumentUploadResultDocument = z.infer<typeof DocumentUploadResultDocumentSchema>;
export type DocumentUploadResult = z.infer<typeof DocumentUploadResultSchema>;
