import MyButton from 'components/MyButton/MyButton';
import MySwitchInput from 'components/MySwitchInput/MySwitchInput';
import { Bundle } from 'features/settings/models/Bundle';
import settingsApi from 'features/settings/settings.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import BundleTemplateFieldsEditModal from '../BundleTemplateFieldsEditModal/BundleTemplateFieldsEditModal';
import './BundleGroup.scss';

export default function BundleGroup({ title, bundles }: { title?: string; bundles: Bundle[] }) {
    return (
        <div className="BundleGroup">
            {title && (
                <div className="BundleGroup__Header">
                    <h3 className="BundleGroup__Header__Title">{title}</h3>
                </div>
            )}
            {bundles.map(b => (
                <BundleRow
                    key={b.id}
                    bundle={b}
                    allBundles={bundles}
                />
            ))}
        </div>
    );
}

function BundleRow({ bundle, allBundles }: { bundle: Bundle; allBundles: Bundle[] }) {
    const dialogManager = useDialogManager();
    const [subscribeMutation] = settingsApi.useBundleSubscribeMutation();
    const [unsubscribeMutation] = settingsApi.useBundleUnsubscribeMutation();
    const [isSubscribed, setIsSubscribed] = useState(false);

    const showTemplateFieldsModal = useCallback(async () => {
        await dialogManager.custom(BundleTemplateFieldsEditModal, {
            bundle,
        });
    }, [bundle, dialogManager]);

    useEffect(() => {
        setIsSubscribed(bundle.isSubscribed);
    }, [bundle.isSubscribed]);

    const handleSwitchChange = async (isChecked: boolean) => {
        try {
            if (isChecked) {
                if (bundle.templateFields.length > 0) {
                    await showTemplateFieldsModal();
                } else {
                    setIsSubscribed(true);
                    await subscribeMutation({ bundle, optimistic: true }).unwrap();
                }
            } else {
                setIsSubscribed(false);
                await unsubscribeMutation(bundle).unwrap();
            }
        } catch (e) {
            setIsSubscribed(bundle.isSubscribed);
            throw e;
        }
    };

    const isDisabled = !isSubscribed && allBundles.some(b => b.isSubscribed && b.id !== bundle.id);

    return (
        <div className={coalesceClassNames('BundleGroup__Bundle', isDisabled && 'disabled')}>
            <div className="BundleGroup__Bundle__Header">
                <div className="BundleGroup__Bundle__Header__Name">{bundle.name}</div>
                {bundle.templateFields.length > 0 && bundle.isSubscribed && (
                    <MyButton
                        className="BundleGroup__Bundle__Header__EditButton"
                        size="small"
                        label="Edit"
                        buttonType="Hollow"
                        onClick={() => showTemplateFieldsModal()}
                    />
                )}
                <MySwitchInput
                    className="BundleGroup__Bundle__Header__Switch"
                    checked={isSubscribed}
                    handleInput={handleSwitchChange}
                    disabled={isDisabled}
                />
            </div>
            <div className="BundleGroup__Bundle__Description">{bundle.description}</div>
            {bundle.templateFields.length > 0 && bundle.isSubscribed && (
                <div className="BundleGroup__Bundle__Fields">
                    {bundle.templateFields.map((field, index) => (
                        <div
                            key={`${index} ${field.templateKey}`}
                            className="BundleGroup__Bundle__Fields__Field"
                        >
                            <span className="BundleGroup__Bundle__Fields__Field__Label">
                                {field.label}
                            </span>
                            <span className="BundleGroup__Bundle__Fields__Field__Value">
                                {field.value}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
