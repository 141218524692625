import { FormControl, InputLabel, OutlinedInput, Switch } from '@mui/material';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MyToggleSwitchInput.scss';

export function MyToggleSwitchInput({
    className,
    label,
    value,
    onChange,
}: {
    className?: string;
    label: string;
    value: boolean;
    onChange: (checked: boolean) => void;
}) {
    return (
        <FormControl
            className={coalesceClassNames('MyToggleSwitchInput', className)}
            size="small"
            variant="outlined"
        >
            <InputLabel
                variant="outlined"
                shrink={true}
            >
                {label}
            </InputLabel>

            <OutlinedInput
                notched={true}
                label={label}
                className="MyToggleSwitchInput__OutlinedInput"
                type="hidden"
            />
            <div className="MyToggleSwitchInput__SwitchWrapper">
                <Switch
                    className="MyToggleSwitchInput__Switch"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                />
            </div>
        </FormControl>
    );
}
