import Icons from 'Icons';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyButton from 'components/MyButton/MyButton';
import Numpad from 'components/Numpad/Numpad';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import MeasureSystem from 'features/settings/enums/MeasureSystem';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid, isNone } from 'utils/helpers';
import { formatMeasurement } from 'utils/measurementHelper';
import './PropertyEditNumpad.scss';

export type PropertyEditNumpadProps = {
    className?: string;
    value?: number;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val?: number) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
    allowBlank?: boolean;
    measureSystem?: MeasureSystem;
    allowEighths?: boolean;
    allowSixteenths?: boolean;
};

export default function PropertyEditNumpad({
    className,
    value,
    label,
    hint,
    inlineHint,
    onChange,
    readonly = false,
    disabled = false,
    measureSystem = MeasureSystem.Metric,
    allowEighths = false,
    allowSixteenths = false,
    validationRequired,
    validationCustom,
    allowBlank,
}: PropertyEditNumpadProps) {
    const [isNumpadVisible, setIsNumpadVisible] = useState(false);
    const valueDisplay = useMemo(() => formatMeasurement(value), [value]);

    const handleInput = useCallback(
        (val?: number) => {
            if (isNone(val)) {
                if (allowBlank) {
                    onChange?.(undefined);
                }
            }

            onChange?.(val);
        },
        [allowBlank, onChange],
    );

    // Show/hide the numpad
    const toggleNumpad = useCallback(() => {
        setIsNumpadVisible(!isNumpadVisible);
    }, [isNumpadVisible]);

    const inputId = useRef(generateShortUuid()).current;
    const inputWrapperRef = useRef<HTMLDivElement>(null);

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={value}
            />
        );
    }
    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditNumpad', className)}
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
        >
            <FieldValidator
                value={value}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            >
                {({ revealError }) => (
                    <>
                        <div
                            className="PropertyEditNumpad__InputWrapper"
                            ref={inputWrapperRef}
                        >
                            <MyButton
                                className="PropertyEditNumpad__Button"
                                onClick={toggleNumpad}
                                size="large"
                                disabled={disabled}
                                buttonType="None"
                                disableFocusRipple={true}
                                id={inputId}
                            >
                                <div className="PropertyEditNumpad__Button__Value">
                                    {valueDisplay}
                                </div>
                                <div className="PropertyEditNumpad__Button__Icon">
                                    <Icons.Numpad />
                                </div>
                            </MyButton>
                        </div>
                        <Numpad
                            open={isNumpadVisible}
                            anchorEl={inputWrapperRef.current}
                            close={toggleNumpad}
                            value={value}
                            onSave={val => {
                                handleInput(val);
                                revealError();
                            }}
                            measureSystem={measureSystem}
                            allowEighths={allowEighths}
                            allowSixteenths={allowSixteenths}
                        />
                    </>
                )}
            </FieldValidator>
        </PropertyEdit>
    );
}
