import QuoteriteWelcomeLogoSvg from 'assets/quoterite-welcome-logo.svg';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import authApi from 'features/auth/auth.api';
import { selectDidLogout } from 'features/auth/auth.slice';
import ForgotPasswordForm from 'features/auth/components/ForgotPasswordForm/ForgotPasswordForm';
import LoginForm from 'features/auth/components/LoginForm/LoginForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import './LoginPage.scss';

export default function LoginPage() {
    useEffect(() => {
        if (window.HOSTED_DOMAIN_BACKGROUND_COLOR != null) {
            document
                .querySelector('body')
                ?.style.setProperty(
                    '--override-login-background-color',
                    window.HOSTED_DOMAIN_BACKGROUND_COLOR,
                );
        }
        document.querySelector('body')?.classList.add('body--LoginPage');
        return () => document.querySelector('body')?.classList.remove('body--LoginPage');
    }, []);

    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const toggleForgot = () => {
        setShowForgotPassword(!showForgotPassword);
    };

    const [logoutMutation] = authApi.useLogoutMutation();

    const didLogout = useAppSelector(selectDidLogout);
    const logout = useCallback(async () => {
        await logoutMutation().unwrap();
        window.location.reload();
    }, [logoutMutation]);

    useEffect(() => {
        // When user logs out they are redirected to this page with didLogout = true
        // We then call the logout mutation and reload the page
        // to make sure the app is up to date and any state has been cleared
        if (didLogout) {
            logout();
        }
    }, [didLogout, logout]);

    return (
        <>
            {didLogout ? (
                <div className="LoginPage LoginPage--LoggingOut">
                    <div>Logging out, please wait...</div>
                    <MyLinearProgress delay={0} />
                </div>
            ) : (
                <div className="LoginPage">
                    <img
                        className="LoginPage__Logo"
                        src={window.HOSTED_DOMAIN_LOGO || QuoteriteWelcomeLogoSvg}
                        // set pixel-perfect height for our logo, and prevent pop as image loads in
                        style={window.HOSTED_DOMAIN_LOGO ? undefined : { height: '199px' }}
                    />
                    {showForgotPassword ? (
                        <ForgotPasswordForm close={toggleForgot} />
                    ) : (
                        <LoginForm />
                    )}
                    <div className="LoginPage__ForgotWrapper">
                        <MyButton
                            key={`showForgotButton_${showForgotPassword}`}
                            buttonType="LinkButton"
                            onClick={toggleForgot}
                        >
                            {showForgotPassword ? 'Back to login' : 'Forgot password?'}
                        </MyButton>
                    </div>
                </div>
            )}
        </>
    );
}
