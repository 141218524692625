import { z } from 'zod';

export const PendingUpdatesSchema = z.object({
    hasPendingUpdates: z.boolean(),
    orderUpdates: z.object({
        hasSurchargesUpdates: z.boolean(),
        hasFreightUpdates: z.boolean(),
        hasInstallationUpdates: z.boolean(),
    }),
    products: z.array(
        z.object({
            productId: z.number(),
            productName: z.string(),
            hasCostPaddingUpdates: z.boolean(),
            hasFabricUpdates: z.boolean(),
            hasFreightUpdates: z.boolean(),
            hasInstallationUpdates: z.boolean(),
            hasRuleUpdates: z.boolean(),
            hasSurchargesUpdates: z.boolean(),
            hasPricingUpdates: z.boolean(),
        }),
    ),
});

export type PendingUpdates = z.infer<typeof PendingUpdatesSchema>;
