import { CircularProgress } from '@mui/material';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import Dialog from './Dialog';

export type LoadingDialogProps = {
    className?: string;
};

export default function LoadingDialog({ className }: LoadingDialogProps) {
    return (
        <Dialog className={coalesceClassNames('DialogManager__dialog--loading', className)}>
            <CircularProgress
                className="spinner"
                size={40}
                thickness={4}
            />
        </Dialog>
    );
}
