import { useValidationContext } from 'providers/FormValidation';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { revealError, unregisterField, validateField } from 'store/forms.slice';
import { useAppDispatch } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './FieldValidator.scss';

type FieldValidatorContext = {
    revealError: () => void;
    error: string;
};

type ChildFunction = (ctx: FieldValidatorContext) => JSX.Element;

export default function FieldValidator({
    value,
    validationKey: validationKeyCustom,
    validationRequired,
    validationRegex,
    validationCustom,
    children,
}: {
    value: any;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationRegex?: [RegExp | string, string];
    validationCustom?: string | false;
    children?: React.ReactFragment | ChildFunction;
}) {
    const [displayError, setDisplayError] = useState('');
    const [previousError, setPreviousError] = useState('');

    const validationKeyDefault = useRef(generateShortUuid());
    const validationKey = validationKeyCustom || validationKeyDefault.current;

    // Form Validation
    // Bind validation error state and message to myProps
    const validationCtx = useValidationContext();
    if (validationCtx) {
        const field = validationCtx.fields
            ? validationCtx.fields.find(f => f.key === validationKey)
            : undefined;

        let error = '';
        if (field && field.error && field.isRevealed) {
            error = field.error;
        }
        if (displayError !== error) {
            setPreviousError(displayError);
            setDisplayError(error);
        }
    }

    const dispatch = useAppDispatch();

    // validate on mount and if any key props change
    useEffect(() => {
        if (validationCtx) {
            dispatch(
                validateField({
                    formId: validationCtx.formId,
                    key: validationKey,
                    value,
                    revealImmediately: validationCtx.revealImmediately,
                    validators: {
                        validationRequired,
                        validationRegex,
                        validationCustom,
                    },
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, validationCustom]);

    // cleanup handler
    // unregister fields
    useEffect(
        () => {
            return () => {
                if (validationCtx) {
                    dispatch(unregisterField({ formId: validationCtx.formId, key: validationKey }));
                }
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const ctx: FieldValidatorContext = useMemo(
        () => ({
            revealError: () => {
                if (validationCtx) {
                    dispatch(revealError({ formId: validationCtx.formId, key: validationKey }));
                }
            },
            error: displayError,
        }),
        [dispatch, displayError, validationCtx, validationKey],
    );

    return (
        <>
            {children && typeof children === 'function' ? children(ctx) : children}
            <div
                className={coalesceClassNames(
                    'FieldValidator__ErrorWrapper',
                    displayError ? 'show' : 'hide',
                )}
            >
                <span className="message">{displayError || previousError}</span>
            </div>
        </>
    );
}
