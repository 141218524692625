import DealerSettingsEditable from 'features/settings/components/DealerSettingsEditable/DealerSettingsEditable';
import settingsApi from 'features/settings/settings.api';
import React from 'react';
import { usePageTitle } from 'utils/locationHelpers';

export default function SettingsPage() {
    usePageTitle('Settings');
    const query = settingsApi.useDealerSettingsQuery();

    return (
        <>
            <DealerSettingsEditable
                model={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
            />
        </>
    );
}
