import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import './CardWidget.scss';

export default function CardWidget({
    title,
    category,
    count,
    tooltip,
    value,
    to,
}: {
    title: string;
    category: string;
    tooltip?: React.ReactNode;
    count: number | null;
    value: number | null;
    to: string;
}) {
    return (
        <div className="CardWidget">
            <Link
                to={to}
                className="CardWidget__Link"
            >
                <div className="CardWidget__Header">
                    <div className="CardWidget__Header__Column">
                        <h4 className="CardWidget__Header__Column__Category">{category}</h4>
                        <h3 className="CardWidget__Header__Column__Title">{title}</h3>
                    </div>
                    <div className="CardWidget__Header__Column">
                        <Icons.ChevronRight size={24} />
                    </div>
                </div>
                <div className="CardWidget__Data">
                    <div className="CardWidget__Data__Count">{count ?? <>&ndash;</>}</div>
                    <div className="CardWidget__Data__Value">
                        {formatCurrency(value, { trimCents: true })}
                    </div>
                </div>
            </Link>
            <div className="CardWidget__Tooltip">
                {tooltip && (
                    <Tooltip
                        title={tooltip}
                        placement="bottom"
                        arrow
                    >
                        <span className="CardWidget__Tooltip__Icon">
                            <Icons.Info />
                        </span>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}
