import MyEditModal from 'components/MyEditModal/MyEditModal';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import dealsApi from 'features/deals/deals.api';
import { DealNote } from 'features/deals/models/DealNote';
import Icons from 'Icons';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import './DealNoteEditModal.scss';

export default function DealNoteEditModal({
    dealId,
    model,
    close,
}: {
    dealId: string;
    model: DealNote;
    close?: () => void;
}) {
    const dialogManager = useDialogManager();

    const isNew = !model.id;

    const [addMutation, addMutationState] = dealsApi.useDealNoteAddMutation();
    const [updateMutation, updateMutationState] = dealsApi.useDealNoteUpdateMutation();
    const [archiveMutation] = dealsApi.useDealNoteArchiveMutation();

    const save = useCallback(
        async (editModel: DealNote) => {
            if (isNew) {
                // add new note
                await addMutation({
                    dealId,
                    content: editModel.content,
                }).unwrap();
            } else {
                // update
                await updateMutation({
                    dealId,
                    id: model.id,
                    content: editModel.content,
                }).unwrap();
            }
            close?.();
        },
        [addMutation, close, dealId, isNew, model.id, updateMutation],
    );

    const archiveNote = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Archive Note`,
            message: `Are you sure you want to archive this note?`,
            acceptButtonType: 'Danger',
            acceptLabel: 'Yes, archive now',
        });
        if (model && confirm) {
            await dialogManager.showLoadingWhile(
                archiveMutation({ dealId, id: model.id }).unwrap(),
            );
            close?.();
        }
    }, [archiveMutation, close, dealId, dialogManager, model]);

    const menuItems = useMemo(
        (): MyMenuButtonItem[] => [
            {
                label: 'Archive note',
                IconLeft: Icons.Archive,
                onClick: archiveNote,
            },
        ],
        [archiveNote],
    );

    return (
        <MyEditModal
            className="DealNoteEditModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={addMutationState.isLoading || updateMutationState.isLoading}
            title={isNew ? 'Add Note' : 'Edit Note'}
            mobileTitle={'Back'}
            saveButtonLabel="Save"
            saveButtonType="Primary"
            headerMenuItems={isNew ? undefined : menuItems}
            enableHeaderMenuWhileEditing
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <MyTextInput
                        className="DealNoteEditModal__ContentTextInput"
                        value={editModel.content}
                        multiline
                        handleInput={content => updateField({ content })}
                        disabled={isSaving}
                        autoFocus
                        validationCustom={!editModel.content.trim() && 'Note cannot be empty'}
                    />
                </>
            )}
        </MyEditModal>
    );
}
