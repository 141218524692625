import MyEditModal from 'components/MyEditModal/MyEditModal';
import dealsApi from 'features/deals/deals.api';
import WorkflowPhase from 'features/deals/enums/WorkflowPhase';
import { DealEdit, DealEditFactory } from 'features/deals/models/DealEdit';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DealEditFields from '../DealEditFields/DealEditFields';
import './DealCreateModal.scss';

export default function DealCreateModal({
    workflow,
    close,
}: {
    workflow: WorkflowDetail;
    close?: () => void;
}) {
    const navigate = useNavigate();

    const model = useMemo(
        () =>
            DealEditFactory.create({
                workflowId: workflow.id,
                statusId:
                    workflow.context.statuses.find(s => s.phase === WorkflowPhase.New)?.id ?? '',
            }),
        [workflow.context.statuses, workflow.id],
    );

    const [saveMutation, saveMutationState] = dealsApi.useDealCreateMutation();

    const save = async (editModel: DealEdit) => {
        const id = await saveMutation(editModel).unwrap();
        navigate(`/workflows/${workflow.id}/${id}`);
        close?.();
    };

    return (
        <MyEditModal
            className="DealCreateModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title={`New`}
            mobileTitle={workflow.name}
            saveButtonLabel="Create"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <DealEditFields
                        isNew={true}
                        editModel={editModel}
                        workflow={workflow}
                        isSaving={isSaving}
                        updateField={updateField}
                    />
                </>
            )}
        </MyEditModal>
    );
}
