import { CircularProgress } from '@mui/material';
import Icons from 'Icons';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyButton from 'components/MyButton/MyButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import settingsApi from 'features/settings/settings.api';
import { selectCurrentCountry } from 'features/settings/settings.slice';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditAddress.scss';

const GOOGLE_PLACES_API_KEY = 'AIzaSyARIBG84GP-44nZka394xl3rQL7Cqg8U2Y';

type ValidationState = 'valid' | 'invalid' | 'unknown' | 'pending';

export default function PropertyEditAddress({
    label,
    value,
    className,
    hint,
    inlineHint,
    onChange,
    disabled,
    readonly,
    validateWithTaxJar,
    validationRequired,
}: {
    label: string;
    value: string;
    className?: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val: string, label?: string) => void;
    readonly?: boolean;
    disabled?: boolean;
    validateWithTaxJar?: boolean;
    validationRequired?: string | boolean;
}) {
    const _inputRef = useRef<HTMLInputElement>();
    const inputId = useRef(generateShortUuid()).current;

    const [textValue, setTextValue] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [validationState, setValidationState] = useState<ValidationState>('unknown');
    const [isLoading, setIsLoading] = useState(false);
    const [placePredictions, setPlacePredictions] = useState<
        google.maps.places.AutocompletePrediction[] | null
    >(null);

    const countryCode = useAppSelector(selectCurrentCountry)?.iso2_code.toLowerCase() ?? null;

    useEffect(() => {
        setSelectedAddress(value);
        setValidationState('unknown');
    }, [value]);

    const [validateAddressQuery] = settingsApi.useLazyValidateAddressQuery();

    const { placesAutocompleteService } = usePlacesService({
        apiKey: GOOGLE_PLACES_API_KEY,
    });

    const performPlaceSearch = useDebouncedCallback(
        500,
        (val: string) => {
            setIsLoading(true);
            placesAutocompleteService?.getPlacePredictions(
                {
                    input: val,
                    types: ['address'],
                    region: countryCode,
                    componentRestrictions: {
                        country: countryCode,
                    },
                },
                result => {
                    setPlacePredictions(result ?? []);
                    setIsLoading(false);
                },
            );
        },
        [placesAutocompleteService],
    );

    const handleTextInput = useCallback(
        (val: string) => {
            setTextValue(val);
            setIsLoading(!!val);
            if (val) {
                performPlaceSearch(val);
            }
        },
        [performPlaceSearch],
    );

    const clearSelectedAddress = useCallback(() => {
        setTextValue(selectedAddress);
        setSelectedAddress('');
        onChange?.('');
        setValidationState('unknown');
        requestAnimationFrame(() => {
            _inputRef.current?.focus();
        });
    }, [onChange, selectedAddress]);

    const selectPrediction = useCallback(
        async (item: google.maps.places.AutocompletePrediction, revealError: () => void) => {
            setTextValue(item.description);
            setSelectedAddress(item.description);

            // In the US, we can validate the address using TaxJar
            // We should be doing this for any addresses used for shipping
            const isUS = countryCode === 'us';
            if (isUS && validateWithTaxJar) {
                setValidationState('pending');
                try {
                    const taxJarAddresses = await validateAddressQuery(item.description).unwrap();
                    if (taxJarAddresses.length === 0) {
                        setValidationState('invalid');
                        revealError();
                    } else {
                        onChange?.(item.description);
                        setValidationState('valid');
                    }
                } catch {
                    setValidationState('invalid');
                    revealError();
                }
            } else {
                // AU etc
                onChange?.(item.description);
                setValidationState('valid');
            }
        },
        [countryCode, onChange, validateAddressQuery, validateWithTaxJar],
    );

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={selectedAddress}
                verticalAlign="top"
            />
        );
    }

    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditAddress', className)}
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
            verticalAlign="top"
        >
            <FieldValidator
                value={selectedAddress}
                validationRequired={validationRequired}
                validationCustom={validationState === 'invalid' && 'Unable to validate address'}
            >
                {({ revealError }) => (
                    <>
                        {selectedAddress ? (
                            <>
                                <div className="PropertyEditAddress__SelectedAddressContainer">
                                    <div
                                        className={coalesceClassNames(
                                            'PropertyEditAddress__SelectedAddress',
                                            `PropertyEditAddress__SelectedAddress--${validationState}`,
                                        )}
                                    >
                                        <div className="PropertyEditAddress__SelectedAddress__Label">
                                            {selectedAddress}
                                        </div>
                                        {validationState === 'pending' && (
                                            <CircularProgress size={20} />
                                        )}
                                        {!disabled && (
                                            <MyButton
                                                className="PropertyEditAddress__SelectedAddress__ClearButton"
                                                buttonType="Nude"
                                                IconRight={Icons.Close}
                                                onClick={clearSelectedAddress}
                                                size="small"
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="PropertyEditAddress__TextInputContainer">
                                    <MyTextInput
                                        className="PropertyEditAddress__TextInput"
                                        inputRef={_inputRef}
                                        value={textValue}
                                        handleInput={handleTextInput}
                                        LeftIcon={Icons.Search}
                                        autoComplete="off"
                                        showClearButton
                                        fullWidth
                                        disabled={disabled}
                                    />
                                    {isLoading && (
                                        <CircularProgress
                                            className="PropertyEditAddress__LoadingSpinner"
                                            size={20}
                                        />
                                    )}
                                </div>
                                {textValue && !isLoading && placePredictions && (
                                    <div className="PropertyEditAddress__PredictionsContainer">
                                        {placePredictions.length === 0 ? (
                                            <div className="PropertyEditAddress__PredictionsContainer__Empty">
                                                No results found
                                            </div>
                                        ) : (
                                            placePredictions.map(item => (
                                                <button
                                                    key={item.place_id}
                                                    className="PropertyEditAddress__PlacePredictionItem"
                                                    onClick={() =>
                                                        selectPrediction(item, revealError)
                                                    }
                                                >
                                                    <Icons.Location className="icon" />
                                                    <span className="description">
                                                        {item.description}
                                                    </span>
                                                </button>
                                            ))
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </FieldValidator>
        </PropertyEdit>
    );
}
