import { Portal } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { wait } from 'utils/helpers';

export type ToastProps = {
    className?: string;
    icon?: 'wave' | 'party' | 'error';
    actionText?: string;
    actionLinkTo?: string;
    actionOnClick?: (e: React.UIEvent) => void;
    title?: React.ReactNode;
    message?: React.ReactNode;
    close?: () => void;
};

export default function Toast({
    className,
    title,
    message = '',
    icon = 'wave',
    actionText,
    actionLinkTo,
    actionOnClick,
    close = () => {},
}: ToastProps) {
    const [isHiding, setIsHiding] = useState(false);
    const navigate = useNavigate();

    const hide = useCallback(async () => {
        if (!isHiding) {
            setIsHiding(true);
            await wait(500);
            close();
        }
    }, [isHiding, close]);

    useEffect(() => {
        // auto hide after 4 seconds
        const timeout = setTimeout(() => hide(), 5000);
        return () => clearTimeout(timeout);
    }, [hide]);

    const iconText = {
        wave: '👋',
        party: '🎉',
        error: '😢',
    }[icon];

    const handleTouch = (e: React.UIEvent) => {
        if (!isHiding) {
            const target = e.target as Element;
            if (target.classList.contains('DialogManager__Toast__ActionLink')) {
                if (actionOnClick) {
                    actionOnClick(e);
                }
                if (actionLinkTo) {
                    navigate(actionLinkTo);
                }
            }
            hide();
        }
    };

    return (
        <Portal>
            <div
                className={coalesceClassNames(
                    'DialogManager__Toast',
                    className,
                    isHiding && 'hiding',
                )}
                onClick={handleTouch}
                onMouseDown={handleTouch}
            >
                <div className="DialogManager__Toast__Frame">
                    <div className="icon">{iconText}</div>
                    <div className="DialogManager__Toast__Content">
                        <div className="title">{title}</div>
                        <div className="message">
                            {message}

                            {actionText && (
                                <>
                                    {' '}
                                    <Link
                                        className="DialogManager__Toast__ActionLink LinkText"
                                        to={actionLinkTo || '#'}
                                        onClick={actionOnClick}
                                    >
                                        {actionText}
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
}
