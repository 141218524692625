import Icons from 'Icons';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { OrderLineErrorDisplay } from 'features/orders/enums/OrderLineError';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import SupplierOrdersTable from 'features/purchases/components/SupplierOrdersTable/SupplierOrdersTable';
import useGetProductManufacturerOrders from 'features/purchases/helpers/useGetProductManufacturerOrders';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { QuoteOrderLine } from 'features/quotes/models/QuoteOrderLine';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'utils/helpers';
import { StrictUnion } from 'utils/typeHelpers';
import MeasurementDisplay from '../../../../components/MeasurementDisplay/MeasurementDisplay';
import OrderProductEditModal from '../OrderProductEditModal/OrderProductEditModal';
import OrderProductOptionsModal from '../OrderProductOptionsModal/OrderProductOptionsModal';
import OrderUpdatesMessagePanel from '../OrderUpdatesMessagePanel/OrderUpdatesMessagePanel';
import ProductOptionDisplayGrid from '../ProductOptionDisplayGrid/ProductOptionDisplayGrid';
import ProductPathDisplay from '../ProductPathDisplay/ProductPathDisplay';
import './OrderProductModal.scss';

export default function OrderProductModal({
    model,
    order,
    isLoading,
    isError,
    close,
}: {
    model?: StrictUnion<PurchaseOrderLine | QuoteOrderLine>;
    order?: StrictUnion<PurchaseOrderDetail | QuoteDetail>;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const navigate = useNavigate();
    const dialogManager = useDialogManager();
    const [deleteMutation] = ordersApi.useOrderWindowDeleteMutation();
    const [cloneMutation] = ordersApi.useOrderWindowCloneMutation();

    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [showEditOptionsModal, setShowEditOptionsModal] = useState(false);

    const { detailPath, entityTitle } = useOrderPresentation();

    const isReadOnly = order?.isReadOnly;

    /** Clone product - this actually clones the whole window */
    const cloneProduct = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Clone product',
            message:
                'A copy of this product will be created and added to this order. Are you sure?',
            acceptLabel: 'Yes, clone now',
        });
        if (confirm && model && order) {
            const productId = await dialogManager.showLoadingWhile(
                cloneMutation({
                    orderId: order.externalId ?? order.id,
                    windowId: model.orderWindowId,
                }).unwrap(),
            );
            navigate(`${detailPath}/${order.id}/products/${productId}`);
        }
    }, [dialogManager, model, order, cloneMutation, navigate, detailPath]);

    /** Delete product - this actually deletes the whole window */
    const deleteProduct = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Delete product',
            message: 'Are you sure you want to delete this product?',
            acceptLabel: 'Yes, delete it',
            acceptButtonType: 'Danger',
        });
        if (model && order && confirm) {
            await dialogManager.showLoadingWhile(
                deleteMutation({
                    orderId: order?.externalId ?? order.id,
                    windowId: model.orderWindowId,
                }),
            );
            close?.();
        }
    }, [close, deleteMutation, dialogManager, model, order]);

    const menuItems: MyMenuButtonItem[] = useMemo(
        () => [
            {
                label: 'Delete product',
                IconLeft: Icons.Archive,
                onClick: deleteProduct,
            },
        ],
        [deleteProduct],
    );

    const isOptionsSelected = useMemo(
        () => model?.context.errors.findIndex(e => e === 'OPTIONS_NOT_SELECTED') === -1,
        [model?.context.errors],
    );

    const displayErrors = useMemo(
        () => model?.context.errors.filter(e => e !== 'OPTIONS_NOT_SELECTED'),
        [model?.context.errors],
    );

    return (
        <MyModal
            className="OrderProductModal"
            close={close}
            mobileTitle={entityTitle}
            isLoading={isLoading}
            isError={isError}
            header={
                <>
                    {/* Main header contains title and product path */}
                    <div className="OrderProductModal__Header__Main">
                        <PageHeader
                            className="OrderProductModal__PageHeader"
                            title="Product"
                        >
                            {!isReadOnly && (
                                <>
                                    <MyButton
                                        label="Clone"
                                        title="Create a copy of this product"
                                        IconLeft={Icons.Clone}
                                        buttonType="Hollow"
                                        onClick={cloneProduct}
                                    />
                                    <MyMenuKebabButton menuItems={menuItems} />
                                </>
                            )}
                        </PageHeader>

                        {model && <ProductPathDisplay product={model.context.product} />}
                    </div>

                    {order?.context.pendingUpdates?.hasPendingUpdates && (
                        <OrderUpdatesMessagePanel model={order} />
                    )}

                    {/* Options prompt */}
                    {!isOptionsSelected && !order?.context.pendingUpdates?.hasPendingUpdates && (
                        <MessagePanel
                            messageType={'info'}
                            className="OrderProductModal__OptionsPrompt"
                            actions={
                                <MyButton
                                    className="OrderProductModal__OptionsPrompt__Button"
                                    label="Show options"
                                    IconRight={Icons.ChevronRight}
                                    onClick={() => setShowEditOptionsModal(true)}
                                />
                            }
                        >
                            Specify options to finish setting up this product
                        </MessagePanel>
                    )}

                    {/* Validation errors */}
                    {!isEmpty(displayErrors) && (
                        <MessagePanel messageType={'error'}>
                            {model?.context.errors.map((err, i) => (
                                <div key={i}>{OrderLineErrorDisplay.display(err)}</div>
                            ))}
                        </MessagePanel>
                    )}
                </>
            }
        >
            {model && order && (
                <>
                    <div className="OrderProductModal__Section">
                        <div className="OrderProductModal__Section__Header">
                            <h2>Details</h2>
                            {!isReadOnly && (
                                <MyButton
                                    className="OrderProductModal__Section__EditButton"
                                    label="Edit"
                                    IconLeft={Icons.Edit}
                                    buttonType="Hollow"
                                    size="small"
                                    onClick={() => setShowEditProductModal(true)}
                                />
                            )}
                        </div>

                        <PropertyContainer layout="table">
                            <PropertyDisplay
                                label="Window or group"
                                value={model.windowGroupName}
                            />
                            {model.context.product.hasQuantity && (
                                <PropertyDisplay
                                    label="Quantity"
                                    value={
                                        model.quantity === 1 ? (
                                            '1'
                                        ) : (
                                            <span className="OrderProductModal__QuantityBadge">
                                                {model.quantity}
                                            </span>
                                        )
                                    }
                                />
                            )}
                            {model.context.product.hasWidth && (
                                <PropertyDisplay
                                    label="Width"
                                    value={
                                        <MeasurementDisplay
                                            value={model.context.configuration.width}
                                        />
                                    }
                                />
                            )}
                            {model.context.product.hasHeight && (
                                <PropertyDisplay
                                    label="Height"
                                    value={
                                        <MeasurementDisplay
                                            value={model.context.configuration.height}
                                        />
                                    }
                                />
                            )}
                            <PropertyDisplay
                                label="Notes"
                                value={model.notes}
                                verticalAlign="top"
                            />
                        </PropertyContainer>
                    </div>

                    {order.isPurchase && (
                        <SupplierOrdersSection
                            order={order}
                            productId={model.id}
                        />
                    )}

                    <div className="OrderProductModal__Section">
                        <div className="OrderProductModal__Section__Header">
                            <h2>Options</h2>
                            {!isReadOnly && (
                                <MyButton
                                    className="OrderProductModal__Section__EditButton"
                                    label="Edit"
                                    IconLeft={Icons.Edit}
                                    buttonType="Hollow"
                                    size="small"
                                    onClick={() => setShowEditOptionsModal(true)}
                                />
                            )}
                        </div>
                        {model.context.configuration.optionGroups?.length === 0 && (
                            <div className="OrderProductModal__NoOptions">
                                Options not selected yet
                            </div>
                        )}
                        <div className="OrderProductModal__OptionContainer">
                            <ProductOptionDisplayGrid
                                optionGroups={model.context.configuration.optionGroups}
                            />
                        </div>
                    </div>

                    {showEditProductModal && (
                        <OrderProductEditModal
                            model={model}
                            order={order}
                            close={() => setShowEditProductModal(false)}
                        />
                    )}
                    {showEditOptionsModal && (
                        <OrderProductOptionsModal
                            model={model}
                            close={() => setShowEditOptionsModal(false)}
                        />
                    )}
                </>
            )}
        </MyModal>
    );
}

function SupplierOrdersSection({
    order,
    productId,
}: {
    order: PurchaseOrderDetail;
    productId: number;
}) {
    const orders = useGetProductManufacturerOrders({ order, productId });

    if (!orders || orders.length === 0) {
        return null;
    }

    return (
        <div className="OrderProductModal__Section">
            <div className="OrderProductModal__Section__Header">
                <h2>Supplier Status</h2>
            </div>
            <SupplierOrdersTable
                purchaseOrderId={order.id}
                orders={orders}
            />
        </div>
    );
}
