// MODIFIED FROM https://github.com/rollbar/rollbar-redux-middleware/blob/master/index.js.es6
export const decycle = obj => {
    let cache = [];

    const stringified = JSON.stringify(obj, (_, value) => {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                return undefined;
            }
            cache.push(value);
        }
        return value;
    });
    cache = null;

    return stringified;
};

export const setToValue = (obj, value, path) => {
    if (!obj || typeof obj !== 'object') {
        return;
    }
    path = path.split('.');
    let tmp = obj;
    let i = 0;
    for (let len = path.length; i < len - 1; i++) {
        obj = { ...obj[path[i]] };
        tmp[path[i]] = obj;
        tmp = obj;
    }
    obj[path[i]] = value;
};

export const sanitize = (state, keyPaths) => {
    if (typeof keyPaths === 'function') {
        return keyPaths(state);
    }

    const replacement = '********';
    const updatedState = { ...state };
    for (let i = 0, len = keyPaths.length; i < len; i++) {
        setToValue(updatedState, replacement, keyPaths[i]);
    }
    return updatedState;
};

export const stateForError = (s, ks) => (ks ? decycle(sanitize(s, ks)) : decycle(s));
