import z from 'zod';
import MeasureSystem from '../enums/MeasureSystem';

export const CountrySchema = z.object({
    id: z.number(),
    name: z.string(),
    iso2_code: z.string(),
    iso3_code: z.string(),
    calling_code: z.string(),
    measure_system: z.nativeEnum(MeasureSystem),
    flag: z.string(), // url to image
    native_name: z.string(),
    labor_tax_control: z.number(), // 0 or 1
    currency_code: z.string(),
    currency_symbol: z.string(),
    currency_name: z.string(),
    language_locale: z.string(),
    tax_display: z.string(),
    new_taxing: z.number(), // 0 or 1
});

export type Country = z.infer<typeof CountrySchema>;
