import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './ErrorContent.scss';

export default function ErrorContent({ className }: { className?: string }) {
    return (
        <div className={coalesceClassNames('ErrorContent', className)}>
            <div className="ErrorContent__Icon">😭</div>
            <h2 className="ErrorContent__Title">Sorry, something went wrong</h2>
            <p className="ErrorContent__Message">
                The server might be offline or experiencing issues, please try again later or
                contact technical support.
            </p>
        </div>
    );
}
