import MyButton from 'components/MyButton/MyButton';
import MyPdfViewer from 'components/MyPdfViewer/MyPdfViewer';
import customerQuotesApi from 'features/customerQuotes/customerQuotes.api';
import { CustomerQuoteDetail } from 'features/customerQuotes/models/CustomerQuoteDetail';
import { CustomerQuotePaymentSummary } from 'features/customerQuotes/models/CustomerQuotePaymentSummary';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import './CustomerQuoteMain.scss';

export default function CustomerQuoteMain({
    model,
    paymentSummary,
}: {
    model: CustomerQuoteDetail;
    paymentSummary?: CustomerQuotePaymentSummary;
}) {
    const quoteId = model?.order.tuid.replace(/^\w-/g, '');
    const [acceptMutation, acceptMutationState] =
        customerQuotesApi.useCustomerQuoteAcceptMutation();

    const dialogManager = useDialogManager();
    const acceptQuote = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Accept quote',
            message:
                'By accepting this quote you are confirming the order and agreeing to all terms and conditions. Please ensure all details are correct before agreeing.',
            acceptLabel: 'Accept and place order',
            acceptButtonType: 'Accent',
        });

        if (confirm) {
            await acceptMutation(model.id);
        }
    }, [acceptMutation, dialogManager, model.id]);

    return (
        <div className="CustomerQuoteMain">
            {/* Summary of quote with totals and action buttons */}
            <h1 className="CustomerQuoteMain__Title">
                Your quote #{quoteId} from {model?.company.name}
            </h1>

            <div className="CustomerQuoteMain__Summary">
                <table className="CustomerQuoteMain__Summary__TotalsTable">
                    <tbody>
                        <tr>
                            <td>Total amount</td>
                            <td>{formatCurrency(model?.order.total)}</td>
                        </tr>
                        <tr>
                            <td>Deposit due</td>
                            <td>{formatCurrency(model?.order.depositAmount)}</td>
                        </tr>
                        <tr>
                            <td>Received</td>
                            <td>{formatCurrency(paymentSummary?.totalReceived)}</td>
                        </tr>
                        <tr>
                            <td>Balance</td>
                            <td>{formatCurrency(paymentSummary?.balance)}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="CustomerQuoteMain__Summary__Content">
                    {model.order.convertedAt ? (
                        <>
                            <div className="CustomerQuoteMain__Summary__Content__Message">
                                Quote accepted {formatDateTimeRelative(model.order.convertedAt)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="CustomerQuoteMain__Summary__Content__Message">
                                Please review the details of the quote below and press accept to
                                place the order.
                            </div>
                            <div className="CustomerQuoteMain__Summary__Content__Actions">
                                <MyButton
                                    className="CustomerQuoteMain__Summary__Content__Actions__Button"
                                    label="Accept quote"
                                    buttonType="Accent"
                                    fullWidth
                                    onClick={acceptQuote}
                                    isLoading={acceptMutationState.isLoading}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Quote PDF display */}
            <div className="CustomerQuoteMain__PdfFrame">
                {model?.quotePdf.url && <MyPdfViewer url={model?.quotePdf.url} />}
            </div>
        </div>
    );
}
