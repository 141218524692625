import { faker } from '@faker-js/faker';
import Env from 'config/Env';
import { delay, http, HttpResponse } from 'msw';

const BASE_URL = `${Env.API_BASE_URL}`;

export const ordersHandlers = [
    // Get sales reps
    http.get(`${BASE_URL}/quotes/sales-reps`, async () => {
        const data = faker.helpers.multiple(faker.person.fullName, { count: 10 });
        await delay(500);
        return HttpResponse.json(data);
    }),
];
