import Icons from 'Icons';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { DataTableFilterConfigBase, FilterCompProps } from '../DataTableFilterTypes';

export type SearchFilterConfig<T> = DataTableFilterConfigBase & {
    type: 'search';
    getFields: (item: T) => (string | undefined | null)[];
    size?: 'small' | 'medium';
};

export default function SearchFilter<T>({
    filter,
    onChange,
}: FilterCompProps<SearchFilterConfig<T>>) {
    const [value, setValue] = useState(filter.config.defaultValue || '');

    const handleInput = (val: string) => {
        setValue(val);
        filter.value = val;
        onChange?.(filter);
    };

    useEffect(() => {
        setValue(filter.value);
    }, [filter.value]);

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--SearchFilter',
                filter.config.className,
            )}
        >
            <MyTextInput
                value={value}
                handleInput={handleInput}
                LeftIcon={Icons.Search}
                showClearButton={true}
                size={(filter as any).size}
            />
        </div>
    );
}

/** Default applyFilter function for search filters */
export function applySearchFilter<T>(config: SearchFilterConfig<T>, value: string, item: T) {
    // make sure each token is found on one of the fields
    const tokens = value.trim().split(' ');
    const fields = config.getFields(item);

    // every token must match at least one field
    return tokens.every(token =>
        fields.some(val => {
            return val && val.toLowerCase().indexOf(token.toLowerCase()) > -1;
        }),
    );
}
