import z from 'zod';
import DealerAccountAdjustmentType from '../enums/DealerAccountAdjustmentType';

export const DealerSettingsSchema = z.object({
    companyLogoDocumentId: z.number().nullable(),
    companyLogoDocumentUrl: z.string().nullable(),
    companyDeliveryAddress: z.string().nullable(),
    defaultShippingInstructions: z.string().nullable().optional(),
    companyDetails: z.string().nullable().optional(),
    bindingText: z.string().nullable(),
    depositPercentage: z.number().nullable(),
    taxRatePercentage: z.number().nullable(),
    accountAdjustmentType: z.nativeEnum(DealerAccountAdjustmentType),
    accountAdjustmentValue: z.number(),
});

export type DealerSettings = z.infer<typeof DealerSettingsSchema>;
