import { DateTime } from 'luxon';
import { ApiTagType, api } from 'services/api';
import { CustomerQuoteDetail, CustomerQuoteDetailSchema } from './models/CustomerQuoteDetail';
import {
    CustomerQuotePaymentSummary,
    CustomerQuotePaymentSummarySchema,
} from './models/CustomerQuotePaymentSummary';

const customerQuotesApi = api.injectEndpoints({
    endpoints: build => ({
        customerQuoteDetail: build.query<CustomerQuoteDetail, string>({
            query: id => ({
                url: `/customerquote/${id}`,
                method: 'GET',
                withCredentials: false,
            }),
            transformResponse: (result: unknown) => CustomerQuoteDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.CustomerQuote, id }],
        }),

        customerQuotePaymentSummary: build.query<CustomerQuotePaymentSummary, string>({
            query: id => ({
                url: `/customerquote/${id}/payments`,
                method: 'GET',
                withCredentials: false,
            }),
            transformResponse: (result: unknown) => CustomerQuotePaymentSummarySchema.parse(result),
            providesTags: (res, err, id) => [
                { type: ApiTagType.CustomerQuote, id },
                { type: ApiTagType.CustomerQuotePayment, id },
            ],
        }),

        customerQuoteAccept: build.mutation<void, string>({
            query: id => ({
                url: `/customerquote/${id}/accept`,
                method: 'POST',
                withCredentials: false,
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update
                dispatch(
                    customerQuotesApi.util.updateQueryData('customerQuoteDetail', id, draft => {
                        Object.assign(draft?.order, {
                            convertedAt: DateTime.now().toISO(),
                        });
                    }),
                );

                // invalidate CustomerQuote
                dispatch(
                    customerQuotesApi.util.invalidateTags([{ type: ApiTagType.CustomerQuote, id }]),
                );
            },
        }),
    }),
});

export default customerQuotesApi;
