import { CheckboxProps, FormControl, Switch } from '@mui/material';
import React, { useCallback } from 'react';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import FieldValidator from '../FieldValidator/FieldValidator';
import './MySwitchInput.scss';

export type MySwitchInputProps = {
    checked?: boolean;
    handleInput?: (val: boolean) => void;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
} & CheckboxProps;

export default function MySwitchInput({
    checked = false,
    handleInput,
    validationKey,
    validationRequired,
    validationCustom,
    ...props
}: MySwitchInputProps) {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            handleInput?.(e.target.checked);
        },
        [handleInput],
    );

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
            value={checked}
        >
            {({ revealError, error }) => (
                <FormControl
                    className={coalesceClassNames(
                        'MySwitchInput',
                        props.className,
                        error && 'MySwitchInput--error',
                    )}
                >
                    <Switch
                        {...props}
                        checked={checked}
                        className={coalesceClassNames(
                            'MySwitchInput__Input',
                            props.className && extendClassName(props.className, '__Input'),
                        )}
                        onChange={handleChange}
                        onBlur={revealError}
                    />
                </FormControl>
            )}
        </FieldValidator>
    );
}
