import FieldValidator from 'components/FieldValidator/FieldValidator';
import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MyHtmlEditor.scss';

export default function MyHtmlEditor({
    className,
    value,
    handleInput,
    disabled,
    validationKey,
    validationRequired,
    validationCustom,
}: {
    className?: string;
    value?: string;
    handleInput?: (val: string) => void;
    disabled?: boolean;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
}) {
    const modules = useMemo(
        () => ({
            // This fixes a glitch where an extra line-break gets added before a ul/ol when reopening
            // https://github.com/quilljs/quill/issues/2905
            clipboard: {
                matchVisual: false,
            },
        }),
        [],
    );

    return (
        <FieldValidator
            value={value}
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
        >
            {({ revealError }) => (
                <ReactQuill
                    className={coalesceClassNames('MyHtmlEditor', className)}
                    theme="snow"
                    value={value}
                    readOnly={disabled}
                    modules={modules}
                    onChange={content => {
                        handleInput?.(content);
                        revealError();
                    }}
                />
            )}
        </FieldValidator>
    );
}
