import { StrictUnion } from 'utils/typeHelpers';
import { z } from 'zod';

const ProductOptionBaseSchema = z.object({
    type: z.literal('BASE'),
    value: z.string().nullable(),
    quantity: z.number().nullable(),
    price: z.number().nullable(),
});

export const ProductOptionFabricSchema = ProductOptionBaseSchema.extend({
    type: z.literal('FABRIC'),
    itemFabricWidth: z.number(),
    itemFabricRepeat: z.string(),
    itemName: z.string(),
    brandName: z.string(),
    color: z.string().nullable(),
});

export type ProductOptionBase = z.infer<typeof ProductOptionBaseSchema>;
export type ProductOptionFabric = z.infer<typeof ProductOptionFabricSchema>;

export const ProductOptionSchema = z.union([ProductOptionFabricSchema, ProductOptionBaseSchema]);

// inferring the type from the above union doesn't work properly
// maybe there's another way? But this seems to work for us.
export type ProductOption = StrictUnion<ProductOptionBase | ProductOptionFabric>;
