import OrderProductModal from 'features/orders/components/OrderProductModal/OrderProductModal';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import purchasesApi from 'features/purchases/purchases.api';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrderProductPage() {
    usePageTitle('Product');
    const { orderId = '', productId = '' } = useParams();
    return (
        <PurchaseOrderProductPagePure
            key={productId}
            orderId={parseInt(orderId, 10)}
            productId={parseInt(productId, 10)}
        />
    );
}

function PurchaseOrderProductPagePure({
    orderId,
    productId,
}: {
    orderId: number;
    productId: number;
}) {
    const navigate = useNavigate();
    const [urlParams] = useSearchParams();

    const query = purchasesApi.usePurchaseOrderDetailQuery(orderId);

    const { detailPath } = useOrderPresentation();

    const model = useMemo(
        () => query.currentData?.context.orderLines.find(ol => ol.id === productId),
        [productId, query.currentData?.context.orderLines],
    );

    useEffect(() => {
        if (query.currentData && !query.isFetching && !model) {
            // invalid product id - redirect
            navigate(`${detailPath}/${orderId}`);
        }
    }, [detailPath, model, navigate, orderId, query.currentData, query.isFetching]);

    return productId ? (
        <>
            <OrderProductModal
                model={model}
                order={query.data}
                isLoading={query.isFetching}
                isError={query.isError}
                close={() => navigate(`${detailPath}/${orderId}?${urlParams}`)}
            />
        </>
    ) : null;
}
