import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import SupplierOrderModal from 'features/purchases/components/SupplierOrderModal/SupplierOrderModal';
import purchasesApi from 'features/purchases/purchases.api';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrderSupplierOrderPage() {
    usePageTitle('Manufacturer Order');
    const { manufacturerOrderId = '', orderId = '' } = useParams();
    return (
        <PurchaseOrderSupplierOrderPagePure
            manufacturerOrderId={parseInt(manufacturerOrderId, 10)}
            orderId={parseInt(orderId, 10)}
        />
    );
}

function PurchaseOrderSupplierOrderPagePure({
    manufacturerOrderId,
    orderId,
}: {
    manufacturerOrderId: number;
    orderId: number;
}) {
    const [urlParams] = useSearchParams();
    const navigate = useNavigate();
    const { detailPath } = useOrderPresentation();
    const query = purchasesApi.useManufacturerOrdersQuery(orderId, {
        skip: !orderId,
    });

    const manufacturerOrder = useMemo(() => {
        return query.data?.find(o => o.id === manufacturerOrderId);
    }, [query?.data, manufacturerOrderId]);

    useEffect(() => {
        if (!query.isLoading && !manufacturerOrder) {
            navigate(`${detailPath}/${orderId}?${urlParams}`);
        }
    }, [detailPath, manufacturerOrder, navigate, orderId, query.isLoading, urlParams]);

    return (
        <SupplierOrderModal
            orderId={orderId}
            model={manufacturerOrder}
            close={() => navigate(`${detailPath}/${orderId}?${urlParams}`)}
        />
    );
}
