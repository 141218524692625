import MyHtmlEditor from 'components/MyHtmlEditor/MyHtmlEditor';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import { SafeHTML } from 'components/SafeHtml/SafeHtml';
import React, { useRef } from 'react';
import { BuiltinValidators } from 'store/forms.slice';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditHtml.scss';

export type PropertyEditHtmlProps = {
    className?: string;
    value: any;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val: any) => void;
    readonly?: boolean;
    disabled?: boolean;
} & BuiltinValidators;

export default function PropertyEditHtml({
    className,
    value,
    label,
    hint,
    inlineHint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
}: PropertyEditHtmlProps) {
    const handleInput = (val: any) => {
        onChange?.(val);
    };

    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={<SafeHTML html={value} />}
                verticalAlign="top"
            />
        );
    }

    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditHtml', className)}
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
            verticalAlign="top"
        >
            <MyHtmlEditor
                className={coalesceClassNames(
                    'PropertyEditHtml__MyHtmlEditor',
                    className && extendClassName(className, '__MyHtmlEditor'),
                )}
                handleInput={handleInput}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
                value={value}
                disabled={disabled}
            />
        </PropertyEdit>
    );
}
