import ordersApi, { SALES_REP_NOT_SET } from '../orders.api';

export default function useSalesRepOptions({ includeNotSet }: { includeNotSet?: boolean } = {}) {
    const query = ordersApi.useSalesRepNamesQuery();

    const opts =
        query.data?.map(n => ({
            label: n,
            value: n,
            isPlaceholder: false,
        })) ?? [];

    if (includeNotSet) {
        opts.unshift({
            label: 'None',
            value: SALES_REP_NOT_SET,
            isPlaceholder: true,
        });
    }

    return opts;
}
