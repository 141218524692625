import { z } from 'zod';

export const TaxJarAddressSchema = z.object({
    street: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    country: z.string(),
});

export type TaxJarAddress = z.infer<typeof TaxJarAddressSchema>;
