import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum FlagAppearance {
    Default = 'DEFAULT',
    Warning = 'WARNING',
    Active = 'ACTIVE',
    Success = 'SUCCESS',
    Danger = 'DANGER',
}

export default FlagAppearance;

const labels: EnumDisplayLabels<typeof FlagAppearance> = {
    Default: 'Default',
    Warning: 'Warning',
    Active: 'Active',
    Success: 'Success',
    Danger: 'Danger',
};

export const FlagAppearanceDisplay = createDisplayEnum(FlagAppearance, labels);
