import { Middleware, MiddlewareAPI, isRejected, isRejectedWithValue } from '@reduxjs/toolkit';

import authApi from 'features/auth/auth.api';
import { clearAuth } from 'features/auth/auth.slice';
import { ApiTagType } from 'services/api';
import { isSerializableAxiosError } from 'utils/errors/axiosErrors';
import { SerializedErrorAsError, isValidSerializedError } from 'utils/errors/reduxToolkitErrors';
import { decycle, stateForError } from 'utils/rollbarReduxHelpers';
import { z } from 'zod';
import { showDialogAction } from './showDialogAction';

const apiErrorMiddleware: Middleware = (api: MiddlewareAPI) => next => action => {
    // keyPaths contain what to sanitise
    const keyPaths: string[] = [];
    // first check if this was rejected
    if (isRejected()) {
        // if it was rejected with value, we probably rejected it with some sort of value
        if (isRejectedWithValue(action)) {
            let realError = true;
            if (isSerializableAxiosError(action.payload)) {
                // this is an axios error, lets see if we should handle differently
                const responseStatus = action.payload.response?.status;
                if (responseStatus === 401 || responseStatus === 419) {
                    // just a 401, clear the auth and let it go
                    api.dispatch(clearAuth({ didSessionExpire: true }));
                    realError = false;
                } else if (
                    // in redux-toolkit v1.9.1 onwards we can switch "login" to authApi.endpoints.login.name
                    action.meta.arg.endpointName === 'login' &&
                    responseStatus === 400
                ) {
                    // 400 error on login endpoint is just bad username password, let it go
                    realError = false;
                } else if (action.payload.response.status == null) {
                    // if we have no server status, treat as a client side error
                    realError = false;
                }
            }
            if (realError) {
                // all other error types should show a generic toast and send to rollbar
                // for rejectedWithValue, the error is contained in the payload
                reportErrorToRollbar({
                    error: action.payload,
                    state: stateForError(api.getState(), keyPaths),
                });
                api.dispatch(authApi.util.invalidateTags([ApiTagType.AppVersion]));
                api.dispatch(
                    showDialogAction({
                        type: 'toast',
                        props: {
                            icon: 'error',
                            message:
                                'The server is experiencing issues, please try again later or contact technical support.',
                        },
                    }),
                );
            }
        } else if (
            /**
             * If it was cancelled before execution, `meta.condition` will be true.
             * If it was aborted while running, `meta.aborted` will be true.
             * If neither of those is true, the thunk was not cancelled, it was simply rejected, either by a Promise rejection or `rejectWithValue`.
             * If the thunk was not rejected, both `meta.aborted` and `meta.condition` will be `undefined`.
             */
            action?.meta?.condition === false &&
            action?.meta?.aborted === false
        ) {
            api.dispatch(authApi.util.invalidateTags([ApiTagType.AppVersion]));
            // errors will be handled by RTK query, but we need to log to rollbar
            if (action.error.name === 'ZodError') {
                // Make zod errors more readable
                // This also helps with fingerprinting
                const zodError = parseZodError(action.error, action.meta.arg.endpointName);
                // log to console - this gets around the issue where chrome doesnt print the full message for zod errors
                // see https://github.com/colinhacks/zod/issues/3516
                // eslint-disable-next-line no-console
                console.error(zodError);
                reportErrorToRollbar({
                    error: zodError,
                    state: stateForError(api.getState(), keyPaths),
                    fingerprint: zodError.message,
                });
            } else {
                reportErrorToRollbar({
                    error: action.error,
                    state: stateForError(api.getState(), keyPaths),
                });
            }
        }
    }
    // error handled, keep going
    try {
        return next(action);
    } catch (err: any) {
        reportErrorToRollbar({
            error: err,
            action: decycle(action),
            state: stateForError(api.getState(), keyPaths),
        });
        throw err;
    }
};

function reportErrorToRollbar({
    error,
    action,
    state,
    fingerprint,
}: {
    error: any;
    action?: any;
    state: any;
    fingerprint?: string;
}) {
    let toReport = ['Error', error];
    if (error instanceof Error || error instanceof String) {
        toReport = [error];
    } else if (isValidSerializedError(error)) {
        // return something that extends Error with same fields
        toReport = [new SerializedErrorAsError(error)];
    } else if (isSerializableAxiosError(error)) {
        // don't report axios errors
        return;
    }
    window.ROLLBAR_INSTANCE?.error(...toReport, {
        versionHash: process.env.REACT_APP_GIT_HASH,
        action,
        state,
        fingerprint,
    });
}

export default apiErrorMiddleware;

function parseZodError(error: Error, endpointName: string): Error {
    try {
        const json = JSON.parse(error.message);
        const schema = z.array(
            z.object({
                code: z.string(), // "invalid_type",
                expected: z.string().optional(), // "boolean",
                received: z.string().optional(), // "undefined",
                path: z.array(z.union([z.string(), z.number()])),
                message: z.string(),
            }),
        );

        const zodError = schema.parse(json)[0];

        // replace index numbers in path with n to make errors more consistent and help with fingerprinting
        // i.e data.labels.10.value => data.labels.n.value
        const path = zodError.path.map(p => (typeof p === 'number' ? 'n' : p)).join('.');
        const message = zodError.expected
            ? `endpoint ${endpointName} => "${path}" expected "${zodError.expected}" received "${zodError.received}"`
            : `endpoint ${endpointName} => "${path}" ${zodError.message}`;
        const newErr: Error = { ...error, message };
        return newErr;
    } catch (e) {
        // failed to parse, log this error instead
        return e instanceof Error ? e : new Error(`${e}`);
    }
}
