import z from 'zod';

export const ProductSchema = z.object({
    id: z.number(),
    // is_master: 0,
    name: z.string(),
    category_id: z.number(),
    brand_id: z.number(),
    brand_name: z.string(),
    category_name: z.string(),
    real_id: z.number(),
    has_width: z.number().default(1),
    has_height: z.number().default(1),
    has_quantity: z.number().default(1),
    // master_id: null,
    // is_template: 0,
    // warnings: 0,
});

export type Product = z.infer<typeof ProductSchema>;
