import { dateOnly, dateTimeInstant } from 'utils/dateSchemas';
import { z } from 'zod';
import { ManufacturerOrderLineSchema } from './ManufacturerOrderLine';
import { ManufacturerOrderStatusSchema } from './ManufacturerOrderStatus';

export const ManufacturerOrderSchema = z.object({
    id: z.number(),
    reference: z.string(),
    freight: z.number(),
    eta: z.string().nullable(),
    orderStatusId: z.number(),
    shippingInstructions: z.string().nullable().optional(),
    customerNotes: z.string().nullable().optional(),
    createdAt: dateTimeInstant(),
    updatedAt: dateTimeInstant().nullable(),
    context: z.object({
        orderStatus: ManufacturerOrderStatusSchema,
        requiredDate: dateOnly().optional().nullable(),
        shippingAddress: z.string().optional(),
        shippingMethod: z
            .object({
                id: z.number(),
                name: z.string(),
            })
            .optional(),
        orderLines: z.array(ManufacturerOrderLineSchema),
    }),
});

export type ManufacturerOrder = z.infer<typeof ManufacturerOrderSchema>;
