import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { selectEditModel, setEditModel, updateEditModelField } from 'store/forms.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { generateShortUuid } from 'utils/helpers';
import './EditSection.scss';

type EditSectionContext<T> = {
    editModel: NonNullable<T>;
    isEditing: boolean;
    isSaving: boolean;
    updateField: (data: Partial<T>) => void;
};

type ChildFunction<T> = (ctx: EditSectionContext<T>) => JSX.Element;

export default function EditSection<T>({
    title,
    model,
    children,
    onSave,
    isSaving,
}: {
    title: string;
    model: T;
    children?: React.ReactFragment | ChildFunction<T>;
    onSave?: (model: NonNullable<T>) => Promise<any | void>;
    isSaving?: boolean;
}) {
    const [isEditing, setIsEditing] = useState(false);
    // const [isDirty, setIsDirty] = useState(false);

    const editModelId = useRef(generateShortUuid()).current;
    const editModel = useAppSelector(selectEditModel(editModelId));

    /** Keep track of component unmounting to prevent state update errors after the fact
     * this can happen during async save */
    const didUnmount = useRef(false);
    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setEditModel({ id: editModelId, model }));
        return () => {
            // cleanup
            dispatch(setEditModel({ id: editModelId, model: undefined }));
        };
    }, [model, editModelId, dispatch]);

    const updateField = useCallback(
        (data: Partial<T>) => {
            dispatch(
                updateEditModelField({
                    id: editModelId,
                    data,
                }),
            );
        },
        [dispatch, editModelId],
    );

    const saveChanges = useCallback(async () => {
        let didError = false;
        try {
            const result = await onSave?.(editModel);
            if (result?.error) {
                didError = true;
            }
        } catch (e) {
            didError = true;
        }

        if (!didError && !didUnmount.current) {
            setIsEditing(false);
        }
    }, [editModel, onSave]);

    const cancel = useCallback(() => {
        // reset model
        dispatch(setEditModel({ id: editModelId, model }));
        setIsEditing(false);
    }, [dispatch, editModelId, model]);

    const ctx = useMemo(
        (): EditSectionContext<T> => ({
            isEditing,
            isSaving: false,
            editModel,
            updateField,
        }),
        [editModel, isEditing, updateField],
    );

    return (
        <div className="EditSection">
            <FormValidation submit={saveChanges}>
                {({ handleSubmit }) => (
                    <>
                        <div className="EditSection__Header">
                            <h3 className="EditSection__Header__Title">{title}</h3>
                            <MyButton
                                label="Edit"
                                IconLeft={Icons.Edit}
                                buttonType="Hollow"
                                disabled={isEditing}
                                onClick={() => setIsEditing(true)}
                            />
                        </div>
                        {/* Avoid rendering form when model is null */}
                        {editModel && (
                            <div className="EditSection__Body">
                                {children && typeof children === 'function'
                                    ? children(ctx)
                                    : children}
                            </div>
                        )}
                        {isEditing && (
                            <div className="EditSection__Footer">
                                <MyButton
                                    label="Save"
                                    buttonType="Primary"
                                    onClick={handleSubmit}
                                    isLoading={isSaving}
                                />
                                <MyButton
                                    label="Cancel"
                                    buttonType="Hollow"
                                    onClick={cancel}
                                    disabled={isSaving}
                                />
                            </div>
                        )}
                    </>
                )}
            </FormValidation>
        </div>
    );
}
