import z from 'zod';
import { DocumentFileSchema } from './DocumentFile';

export const DocumentsResultSchema = z.object({
    base_url: z.string(),
    data: z.array(DocumentFileSchema),
    total: z.number(),
});

export type DocumentsResult = z.infer<typeof DocumentsResultSchema>;
