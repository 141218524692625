import { useEffect } from 'react';

let pageTitles: { title: string }[] = [];

/**
 * usePageTitle manages a stack of titles used to populate the browser tab name
 * Titles will be rendered with the last given title at the front, through to earliest given title.
 * When a component is destroyed, the title is removed from the stack.
 *
 * This function must first be called near the application root with a default title, otherwise the title may show as empty.
 */
export function usePageTitle(title?: string) {
    useEffect(() => {
        if (title) {
            // Wrap the title in an object so it has a unique reference that can be used to remove it from the stack
            const obj = { title };
            pageTitles.unshift(obj);
            document.title = pageTitles.map(o => o.title).join(' | ');
            return () => {
                pageTitles = pageTitles.filter(o => o !== obj);
                document.title = pageTitles.map(o => o.title).join(' | ');
            };
        }
        return undefined;
    }, [title]);
}
