import { CircularProgress } from '@mui/material';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import ordersApi from 'features/orders/orders.api';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import purchasesApi from 'features/purchases/purchases.api';
import { Manufacturer } from 'features/settings/models/Manufacturer';
import { selectManufacturer } from 'features/settings/settings.slice';
import Icons from 'Icons';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { formatCurrency } from 'utils/helpers';
import PurchaseOrderReviewModal from '../PurchaseOrderReviewModal/PurchaseOrderReviewModal';
import './PurchaseOrderDetailModalFooter.scss';

export default function PurchaseOrderDetailModalFooter({ model }: { model: PurchaseOrderDetail }) {
    const breakpoints = useBreakpoints();
    const [showReviewModal, setShowReviewModal] = useState(false);

    const manufacturer = useAppSelector(selectManufacturer(model.manufacturerId));
    const isInternalCustomer = manufacturer?.manufacturer_customer_settings?.is_internal;

    /** Total number of products */
    const totalQuantity = model?.totalQuantity ?? 0;
    /** Calculated total applied to this order (ignoring overrideTotal) */
    const totalPrice = model?.totalCost ?? 0;

    return (
        <>
            {totalQuantity > 0 && (
                <div className="PurchaseOrderDetailModalFooter">
                    <div className="PurchaseOrderDetailModalFooter__Content">
                        {totalQuantity} {totalQuantity === 1 ? 'product' : 'products'}, total
                        <span className="PurchaseOrderDetailModalFooter__Content__Price">
                            {formatCurrency(totalPrice)}
                        </span>
                        {!isInternalCustomer && (
                            <TotalFreightCost
                                model={model}
                                manufacturer={manufacturer}
                            />
                        )}
                    </div>
                    {model.status === PurchaseOrderStatus.Submitted ? (
                        <MyButton
                            label="View PDF"
                            buttonType="Hollow"
                            href={model.context.orderDocument?.url}
                            disabled={!model.context.orderDocument}
                            LinkComponent={MyButtonLinkNewTab}
                            IconLeft={Icons.File}
                            fullWidth={breakpoints.isVerySmallOnly}
                        />
                    ) : (
                        <MyButton
                            label="Review Order"
                            buttonType="Primary"
                            disabled={model.isReadOnly || totalQuantity === 0}
                            onClick={() => setShowReviewModal(true)}
                            IconLeft={breakpoints.isVerySmallOnly ? undefined : Icons.Play}
                            fullWidth={breakpoints.isVerySmallOnly}
                        />
                    )}
                </div>
            )}
            {showReviewModal && (
                <PurchaseOrderReviewModal
                    model={model}
                    close={() => setShowReviewModal(false)}
                />
            )}
        </>
    );
}

function TotalFreightCost({
    model,
    manufacturer,
}: {
    model: PurchaseOrderDetail;
    manufacturer?: Manufacturer;
}) {
    const mfOrdersQuery = purchasesApi.useManufacturerOrdersQuery(model.id, {
        skip: model.status === PurchaseOrderStatus.Open,
    });

    const isSubmitted = model.status === PurchaseOrderStatus.Submitted;
    const shippingMethodId = manufacturer?.customer_settings.default_shipping_method_id ?? null;

    const freightEstimateQuery = ordersApi.useOrderFreightEstimateQuery(
        {
            orderId: model.id,
            shippingMethodId: shippingMethodId ?? 0,
            manufacturerId: manufacturer?.id ?? 0,
        },
        { skip: isSubmitted || !shippingMethodId || !manufacturer?.id },
    );

    const isLoading = freightEstimateQuery.isLoading;

    const totalFreight = useMemo(() => {
        if (!isSubmitted) {
            // use estimate from query
            return freightEstimateQuery.data ?? undefined;
        }

        // else calculate freight from manufacturer orders
        return mfOrdersQuery.data?.reduce((sum, order) => sum + order.freight, 0) ?? 0;
    }, [freightEstimateQuery.data, isSubmitted, mfOrdersQuery.data]);

    return (
        <div className="PurchaseOrderDetailModalFooter__TotalFreightCost">
            {isLoading ? (
                <CircularProgress size={24} />
            ) : totalFreight ? (
                <>
                    {' '}
                    + {formatCurrency(totalFreight)} shipping {!isSubmitted && '(est)'}
                </>
            ) : null}
        </div>
    );
}
