import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useUrlQueryState<T extends string = string, D extends T = T>(
    key: string,
    options?: {
        allowedValues?: readonly T[];
        defaultValue?: D;
        clearOnUnmount?: boolean;
    },
) {
    const defaultValue = options?.defaultValue ?? options?.allowedValues?.[0] ?? ('' as T);

    const navigate = useNavigate();

    const setValue = useCallback(
        (val: T, opts?: { replace?: boolean }) => {
            const searchParams = new URLSearchParams(window.location.search);

            if (!val || val === defaultValue) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, val);
            }

            const url = (searchParams as any).size
                ? `${window.location.pathname}?${searchParams}`
                : window.location.pathname;

            navigate(url, opts);
        },
        [defaultValue, key, navigate],
    );

    useEffect(() => {
        return () => {
            if (options?.clearOnUnmount) {
                setValue(defaultValue, { replace: true });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // read the current value from the URL
    // if no allowed values are provided we always accept it
    // else if the value is not in the allowedValues list, use the default value
    const paramValue = new URLSearchParams(window.location.search).get(key) || defaultValue;
    let currentValue: T;

    // if allowedValues are provided, check if the value is in the list
    // if not allowed then revert to the default value
    if (options?.allowedValues && !options.allowedValues.includes(paramValue as T)) {
        currentValue = defaultValue;
    } else {
        currentValue = paramValue as T;
    }

    useEffect(() => {
        // if the currentValue is different from the paramValue
        // that means the paramValue was not allowed and we should update the URL
        if (paramValue !== currentValue) {
            setValue(currentValue, { replace: true });
        }
    }, [currentValue, paramValue, setValue]);

    return [currentValue, setValue] as const;
}
