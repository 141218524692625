import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';

export const PurchaseOrderSchema = z
    .object({
        id: z.number(),
        tuid: z.string(),
        status: z.nativeEnum(PurchaseOrderStatus),
        sidemark: z.string(),
        createdAt: dateTimeInstant(),
        isArchived: z.boolean(),
        salesRep: z.string(),
        customerDetails: z.string(),
        totalCost: z.number(),
        totalQuantity: z.number(),
        manufacturerId: z.number(),
        context: z.object({
            coreStatus: z.object({
                UNSTARTED: z.number(),
                IN_PROGRESS: z.number(),
                ON_HOLD: z.number(),
                COMPLETED: z.number(),
                CANCELLED: z.number(),
            }),
        }),
    })
    .transform(model => {
        return {
            ...model,
            isPurchase: true as const,
            isQuote: false as const,
        };
    });

export type PurchaseOrder = z.infer<typeof PurchaseOrderSchema>;
