import Icons from 'Icons';
import { OrderLineProduct } from 'features/orders/models/OrderLineProduct';
import { Product } from 'features/orders/models/Product';
import React from 'react';
import { StrictUnion } from 'utils/typeHelpers';
import './ProductPathDisplay.scss';

export default function ProductPathDisplay({
    product,
}: {
    product: StrictUnion<Product | OrderLineProduct>;
}) {
    const brandName = product.context?.brandName ?? product.brand_name;
    const categoryName = product.context?.categoryName ?? product?.category_name;
    const productName = product.name;

    return product ? (
        <div className="ProductPathDisplay">
            {brandName} <Icons.ChevronRight /> {categoryName} <Icons.ChevronRight />{' '}
            <strong>{productName}</strong>
        </div>
    ) : null;
}
