import z from 'zod';
import FlagAppearance from '../enums/FlagAppearance';

export const DealFlagValueSchema = z.object({
    workflowFlagId: z.string(),
    workflowFlagName: z.string(),
    workflowFlagValueId: z.string(),
    label: z.string(),
    appearance: z.nativeEnum(FlagAppearance),
    showOnCard: z.boolean(),
});

export type DealFlagValue = z.infer<typeof DealFlagValueSchema>;
