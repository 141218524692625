export const fakeManufacturers = [
    {
        id: 1,
        name: 'Manufacturer One',
    },
    {
        id: 2,
        name: 'Second Manufacturer',
    },
];
