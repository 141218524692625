import { useFullscreenLayout } from 'components/Layout/Layout';
import LayoutScrollArea from 'components/LayoutScrollArea/LayoutScrollArea';
import MyButton from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import DealCreateModal from 'features/deals/components/DealCreateModal/DealCreateModal';
import DealsKanban from 'features/deals/components/DealsKanban/DealsKanban';
import dealsApi from 'features/deals/deals.api';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import Icons from 'Icons';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function DealsPage() {
    const { workflowId } = useParams() as { workflowId: string };
    const workflowDetailQuery = dealsApi.useWorkflowDetailQuery(workflowId ?? '', {
        skip: !workflowId,
    });
    usePageTitle(workflowDetailQuery.data?.name);
    useFullscreenLayout();

    const navigate = useNavigate();

    useEffect(() => {
        if (!workflowDetailQuery.data && !workflowDetailQuery.isFetching) {
            navigate(`/`);
        }
    });
    return (
        <>
            <Content workflow={workflowDetailQuery.data} />
            <Outlet />
        </>
    );
}

function Content({ workflow }: { workflow?: WorkflowDetail }) {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    return (
        <>
            <PageHeader title={workflow?.name}>
                <MyButton
                    onClick={() => setIsCreateModalOpen(true)}
                    label={`Add`}
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                />
            </PageHeader>
            {workflow && (
                <>
                    <LayoutScrollArea>
                        <DealsKanban workflow={workflow} />
                    </LayoutScrollArea>
                    {isCreateModalOpen && (
                        <DealCreateModal
                            workflow={workflow}
                            close={() => setIsCreateModalOpen(false)}
                        />
                    )}
                </>
            )}
        </>
    );
}
