import { faker } from '@faker-js/faker/locale/af_ZA';
import Env from 'config/Env';
import { DateTime } from 'luxon';
import { delay, http, HttpResponse } from 'msw';
import { moveDeal } from './deals.api';
import {
    fakeDealDetails,
    fakeDealNotes,
    fakeWorkFlowDetails as fakeWorkflowDetails,
    fakeWorkflows,
    generateFakeDealDetail,
    getFakeDealsList,
} from './faker/DealsFaker';
import { DealNoteFactory } from './models/DealNote';

const BASE_URL = `${Env.API_BASE_URL}`;

export const dealHandlers = [
    // list workflows
    http.get(`${BASE_URL}/workflows`, async () => {
        await delay(500);
        return HttpResponse.json(fakeWorkflows);
    }),

    http.get(`${BASE_URL}/workflows/:id`, async ({ params }) => {
        const workflow = fakeWorkflowDetails.find(w => w.id === params.id);
        if (!workflow) {
            return new HttpResponse(`Failed to find workflow with id ${params.id}`, {
                status: 400,
            });
        }
        await delay(500);
        return HttpResponse.json(workflow);
    }),

    // list deals
    http.get(`${BASE_URL}/workflows/:id/deals`, async req => {
        const deals = getFakeDealsList(req.params.id as string);
        await delay(500);
        return HttpResponse.json(deals);
    }),

    // Deal details
    http.get(`${BASE_URL}/deals/:id`, async ({ params }) => {
        const deal = fakeDealDetails.find(d => d.id === params.id);
        if (!deal) {
            return new HttpResponse(`Failed to find deal with id ${params.id}`, { status: 400 });
        }
        await delay(500);
        return HttpResponse.json(deal);
    }),

    // Deal create
    http.post(`${BASE_URL}/workflows/:id/deals`, async ({ params, request }) => {
        const body = (await request.json()) as {
            statusId: string;
            customerName: string;
            description: string;
            currentStatusId: string;
        };

        const deal = generateFakeDealDetail(params.id as string);
        const status = fakeWorkflowDetails
            .flatMap(w => w.context.statuses)
            .find(s => s.id === body.statusId);
        if (!status) {
            return new HttpResponse(`Failed to find status with id ${body.statusId}`, {
                status: 400,
            });
        }

        deal.statusId = status.id;
        deal.context.status = status;
        deal.customerName = body.customerName;
        deal.description = body.description;

        fakeDealDetails.unshift(deal);

        await delay(500);
        return HttpResponse.json({
            id: deal.id,
        });
    }),

    // Deal update
    http.post(`${BASE_URL}/deals/:id`, async ({ params, request }) => {
        const body = (await request.json()) as {
            statusId: string;
            customerName: string;
            description: string;
            currentStatusId: string;
        };

        const deal = fakeDealDetails.find(d => d.id === params.id);
        if (!deal) {
            return new HttpResponse(`Failed to find deal with id ${params.id}`, { status: 400 });
        }

        const status = fakeWorkflowDetails
            .flatMap(w => w.context.statuses)
            .find(s => s.id === body.statusId);
        if (!status) {
            return new HttpResponse(`Failed to find status with id ${body.statusId}`, {
                status: 400,
            });
        }

        deal.statusId = status.id;
        deal.context.status = status;
        deal.customerName = body.customerName;
        deal.description = body.description;

        await delay(500);
        return new HttpResponse(null, { status: 200 });
    }),

    // Deal move
    http.post(`${BASE_URL}/deals/:id/move`, async ({ params, request }) => {
        const body = (await request.json()) as {
            targetStatusId: string;
            belowId: string | null;
            currentStatusId: string;
            currentSortOrder: string;
        };

        await delay(500);

        moveDeal({
            dealId: params.id as string,
            deals: fakeDealDetails,
            targetStatusId: body.targetStatusId,
            belowId: body.belowId,
        });

        return new HttpResponse(null, { status: 200 });
    }),

    // Deal archive
    http.post(`${BASE_URL}/deals/:id/archive`, async ({ params }) => {
        const deal = fakeDealDetails.find(d => d.id === params.id);
        if (!deal) {
            return new HttpResponse(`Failed to find deal with id ${params.id}`, { status: 400 });
        }
        deal.isArchived = true;
        return new HttpResponse(null, { status: 200 });
    }),

    // Deal Note List
    http.get(`${BASE_URL}/deals/:dealId/notes`, async ({ params }) => {
        const dealId = params.dealId as string;
        const notes = fakeDealNotes[dealId] ?? [];
        await delay(500);
        return HttpResponse.json(notes);
    }),

    // Deal note add
    http.post(`${BASE_URL}/deals/:dealId/notes`, async ({ params, request }) => {
        const dealId = params.dealId as string;
        let notes = fakeDealNotes[dealId];
        if (!notes) {
            notes = [];
            fakeDealNotes[dealId] = notes;
        }

        // update note
        const body = (await request.json()) as { content: string };
        const note = DealNoteFactory.create({
            id: faker.string.uuid(),
            content: body.content,
            createdAt: DateTime.now().toISO() as string,
            context: {
                createdBy: {
                    id: 69,
                    name: 'Mocked user',
                },
                updatedBy: null,
            },
        });
        notes.push(note);

        await delay(500);
        return HttpResponse.json({
            id: note.id,
        });
    }),

    // Deal Note update
    http.post(`${BASE_URL}/deals/:dealId/notes/:id/update`, async ({ params, request }) => {
        const dealId = params.dealId as string;
        const note = fakeDealNotes[dealId]?.find(n => n.id === params.id);
        if (!note) {
            return new HttpResponse(`Failed to find note with id ${params.id}`, { status: 400 });
        }

        // update note
        const body = (await request.json()) as { content: string };
        note.content = body.content;
        note.updatedAt = DateTime.now().toISO();
        note.context.updatedBy = {
            id: 69,
            name: 'Mocked user',
        };
        note.updatedBy = note.context.updatedBy?.id;

        await delay(500);
        return new HttpResponse(undefined, { status: 200 });
    }),

    // Deal Note archive
    http.post(`${BASE_URL}/deals/:dealId/notes/:id/archive`, async ({ params }) => {
        const dealId = params.dealId as string;
        const index = fakeDealNotes[dealId]?.findIndex(n => n.id === params.id);
        if (index === -1) {
            return new HttpResponse(`Failed to find note with id ${params.id}`, { status: 400 });
        }

        // delete note
        fakeDealNotes[dealId]?.splice(index, 1);
        await delay(500);
        return new HttpResponse(undefined, { status: 200 });
    }),

    // Deal flag update
    http.post(`${BASE_URL}/deals/:dealId/flags/:flagId`, async ({ params, request }) => {
        const dealId = params.dealId as string;
        const deal = fakeDealDetails?.find(d => d.id === dealId);
        if (!deal) {
            return new HttpResponse(`Failed to find deal with id ${dealId}`, { status: 400 });
        }

        const workflowId = deal.workflowId;
        const workflow = fakeWorkflowDetails.find(w => w.id === workflowId);
        if (!workflow) {
            return new HttpResponse(`Failed to find workflow with id ${workflowId}`, {
                status: 400,
            });
        }

        const flagId = params.flagId as string;
        const flag = workflow.context.flags.find(f => f.id === flagId);
        if (!flag) {
            return new HttpResponse(`Failed to find flag with id ${flagId}`, { status: 400 });
        }

        const body = (await request.json()) as { value: string };
        const value = flag.values.find(f => f.id === body.value);
        if (!value) {
            return new HttpResponse(`Failed to find flag value with id ${value}`, { status: 400 });
        }

        const dealFlag = deal.context.flagValues.find(f => f.workflowFlagId === flagId);
        if (!dealFlag) {
            return new HttpResponse(`Failed to find deal flag with id ${flagId}`, { status: 400 });
        }

        // update the flag value
        deal.context.flagValues = deal.context.flagValues.map(f => {
            if (f.workflowFlagId !== flagId) {
                return { ...f };
            }
            // return updated one
            return {
                workflowFlagId: flag.id,
                workflowFlagName: flag.name,
                workflowFlagValueId: flagId,
                label: value.label,
                appearance: value.appearance,
                showOnCard: value.showOnCard,
            };
        });

        await delay(500);
        return new HttpResponse(undefined, { status: 200 });
    }),
];
