import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum DealerAccountAdjustmentType {
    PercentMarkUp = 'PERCENT_MARK_UP',
    PercentMargin = 'PERCENT_MARGIN',
}

export default DealerAccountAdjustmentType;

const labels: EnumDisplayLabels<typeof DealerAccountAdjustmentType> = {
    PercentMarkUp: 'Percent mark up',
    PercentMargin: 'Percent margin',
};

export const DealerAccountAdjustmentTypeDisplay = createDisplayEnum(
    DealerAccountAdjustmentType,
    labels,
);
