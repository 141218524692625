import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum CustomerQuotePaymentStatus {
    Pending = 'PENDING',
    Succeeded = 'SUCCEEDED',
    Failed = 'FAILED',
}

export default CustomerQuotePaymentStatus;

const labels: EnumDisplayLabels<typeof CustomerQuotePaymentStatus> = {
    Pending: 'Pending',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
};

export const CustomerQuotePaymentStatusDisplay = createDisplayEnum(
    CustomerQuotePaymentStatus,
    labels,
);
