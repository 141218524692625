import { DateTime } from 'luxon';

export const dateFilterOptionsAny = [
    {
        label: 'Last 30 days',
        value: '-30',
        minDate: DateTime.now().minus({ days: 30 }).startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Last 7 days',
        value: '-7',
        minDate: DateTime.now().minus({ days: 7 }).startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Today',
        value: 'today',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().endOf('day'),
    },
    {
        label: 'Next 7 days',
        value: '7',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().plus({ days: 7 }).endOf('day'),
    },
    {
        label: 'Next 30 days',
        value: '30',
        minDate: DateTime.now().startOf('day'),
        maxDate: DateTime.now().plus({ days: 30 }).endOf('day'),
    },
    {
        label: 'This month',
        value: 'this-month',
        minDate: DateTime.now().startOf('month'),
        maxDate: DateTime.now().endOf('month'),
    },
    {
        label: 'This year',
        value: 'this-year',
        minDate: DateTime.now().startOf('year'),
        maxDate: DateTime.now().endOf('year'),
    },
    // TODO Custom date range
    // {
    //     label: 'Custom',
    //     value: 'Custom',
    // },
];

export const dateFilterOptionsPast = dateFilterOptionsAny.filter(o =>
    ['-30', '-7', 'today', 'this-month', 'this-year'].includes(o.value),
);

export const dateFilterOptionsFuture = dateFilterOptionsAny.filter(o =>
    ['today', '30', '7', 'this-month', 'this-year'].includes(o.value),
);
