import React, { useEffect, useState } from 'react';
import './MyLinearProgress.scss';
import { LinearProgress } from '@mui/material';
import coalesceClassNames from 'utils/coalesceClassNames';

export default function MyLinearProgress({
    className,
    delay = 500,
    fullWidth,
}: {
    className?: string;
    delay?: number;
    fullWidth?: boolean;
}) {
    // Loading spinner is displayed after isLoading has been true for 0.5 sec
    const [isShown, setIsShown] = useState(false);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const t = setTimeout(() => setIsShown(true), delay);
        return () => clearTimeout(t);
    }, [delay]);

    return (
        <LinearProgress
            className={coalesceClassNames(
                'MyLinearProgress',
                className,
                fullWidth && 'fullWidth',
                isShown && 'show',
            )}
        />
    );
}
