import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import { QuoteOrderLine } from 'features/quotes/models/QuoteOrderLine';
import { StrictUnion } from 'utils/typeHelpers';
import { Product } from './Product';

export type OrderLineEditable = {
    id: number;
    legacyOrderId: number;
    orderWindowId: number;
    productId: number;
    brandId: number | null;
    categoryId: number | null;
    windowGroupName: string;
    width?: number;
    height?: number;
    quantity: number;
    notes: string;
    hasWidth: boolean;
    hasHeight: boolean;
    hasQuantity: boolean;
};

export class OrderLineEditableEditableFactory {
    static createFromOrderLine({
        orderLine,
        legacyOrderId,
    }: {
        orderLine: StrictUnion<PurchaseOrderLine | QuoteOrderLine>;
        legacyOrderId: number;
    }): OrderLineEditable {
        return {
            id: orderLine.id,
            hasHeight: orderLine.context.product.hasHeight,
            hasQuantity: orderLine.context.product.hasQuantity,
            hasWidth: orderLine.context.product.hasWidth,
            height: orderLine.context.configuration.height,
            legacyOrderId,
            notes: orderLine.notes ?? '',
            orderWindowId: orderLine.orderWindowId,
            productId: orderLine.context.product.id,
            quantity: orderLine.quantity,
            width: orderLine.context.configuration.width,
            windowGroupName: orderLine.windowGroupName ?? '',
            // this is not needed when performing edits on an order line
            // we can remove this once add Product routes are also in kotlin
            categoryId: null,
            brandId: null,
        };
    }

    static createFromProduct({
        product,
        legacyOrderId,
    }: {
        product: Product;
        legacyOrderId: number;
    }): OrderLineEditable {
        return {
            id: 0,
            hasHeight: product.has_height === 1,
            hasQuantity: product.has_quantity === 1,
            hasWidth: product.has_width === 1,
            height: undefined,
            legacyOrderId,
            notes: '',
            orderWindowId: 0,
            productId: product.id,
            brandId: product.brand_id,
            categoryId: product.category_id,
            quantity: 1,
            width: undefined,
            windowGroupName: '',
        };
    }
}
