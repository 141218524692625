/** A mapping of enum values to nice human readable labels for displaying in the UI */
export type EnumDisplayLabels<T> = {
    [K in keyof T]: string;
};

type NativeEnumRecord = Record<PropertyKey, string>;

export type DisplayEnum = {
    enumRecord: NativeEnumRecord;

    /** An option list with label/value pairs - ideal for displaying in a drop down list or similar */
    options: { label: string; value: string }[];

    /** A string array of all the enum values possible */
    values: string[];

    /** A helper function which takes an enum value and returns the corresponding option label */
    display: (val?: any) => string | undefined;
};

/** Creates a helper object tied to the enum definition provided
 * Supports a consistent pattern for using and displaying enums throughout the UI
 */
export default function createDisplayEnum<T extends NativeEnumRecord>(
    enumRecord: T,
    labels: EnumDisplayLabels<T>,
): DisplayEnum {
    const options = Object.keys(labels).map(k => ({
        label: labels[k],
        value: enumRecord[k],
    }));

    const values = options.map(x => x.value);

    return {
        enumRecord,
        values,
        options,
        display(val?: any) {
            // labels are keyed by enum key - but value is enum value
            // so look up the enum key for the provided value
            // then use that to get the display label
            const key = Object.keys(enumRecord).find(k => enumRecord[k] === `${val}`);
            if (key) {
                return labels[key];
            }

            // if the value passed in was not found in the labels, just return the value itself
            return key;
        },
    };
}

// example

// enum Fruit {
//     Apple = "APPLE",
//     Banana = "BANANA",
//     Orange = "ORANGE",
// }

// const fruitMapping: EnumToObject<typeof Fruit> = {
//     Apple: "Sweet",
//     Banana: "Tropical",
//     Orange: "Citrus",
// };

// const fruitEnum = createDisplayEnum(Fruit, fruitMapping);

// console.log(fruitEnum.display("APPLE"));
// -> displays 'Sweet'
