import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

export enum ManufacturerOrderCoreStatus {
    Unstarted = 'UNSTARTED',
    InProgress = 'IN_PROGRESS',
    OnHold = 'ON_HOLD',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
}

export default ManufacturerOrderCoreStatus;

const labels: EnumDisplayLabels<typeof ManufacturerOrderCoreStatus> = {
    Unstarted: 'Unstarted',
    InProgress: 'In Progress',
    OnHold: 'On Hold',
    Completed: 'Completed',
    Cancelled: 'Cancelled',
};

export const ManufacturerOrderCoreStatusDisplay = createDisplayEnum(
    ManufacturerOrderCoreStatus,
    labels,
);
