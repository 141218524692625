import { z } from 'zod';
import { ProductOptionGroupSchema } from './ProductOptionGroup';

export const ProductConfigurationSchema = z.object({
    width: z.number(),
    height: z.number(),
    optionGroups: z.array(ProductOptionGroupSchema),
});

export type ProductConfiguration = z.infer<typeof ProductConfigurationSchema>;
