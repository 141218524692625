import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import MyButton from 'components/MyButton/MyButton';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import OrderProductAddModal from 'features/orders/components/OrderProductAddModal/OrderProductAddModal';
import { ProductDimensionDisplay } from 'features/orders/components/ProductDimensionDisplay/ProductDimensionDisplay';
import { OrderLineErrorDisplay } from 'features/orders/enums/OrderLineError';
import { useWindowGroupOptions } from 'features/orders/helpers/orderProductHelper';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import purchasesApi from 'features/purchases/purchases.api';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import './PurchaseOrderProductList.scss';

const COLUMNS = ColumnBuilder<PurchaseOrderLine>()
    .column({
        key: 'product',
        label: 'Product',
        whiteSpace: 'nowrap',
        getValue: item => <ProductDisplay orderLine={item} />,
    })
    .column({
        key: 'windowGroupName',
        label: 'Window or group',
        getValue: item => item.windowGroupName,
    })
    .column({
        key: 'dimensions',
        label: 'Dimensions',
        whiteSpace: 'nowrap',
        getValue: item => <ProductDimensionDisplay orderLine={item} />,
    })
    .column({
        key: 'quantity',
        label: 'Qty',
        align: 'center',
        getValue: item => item.quantity,
    })
    .column({
        key: 'total',
        label: 'Total',
        getValue: item => formatCurrency(item.costPrice),
    })
    .column({
        key: 'options',
        label: 'Options',
        getValue: item => <OptionsDisplay orderLine={item} />,
    })
    .build();

export default function PurchaseOrderProductList({ order }: { order: PurchaseOrderDetail }) {
    const [filteredProducts, setFilteredProducts] = useState<PurchaseOrderLine[]>();
    const [showAddProductModal, setShowAddProductModal] = useState(false);

    const breakpoints = useBreakpoints();

    const windowGroupOptions = useWindowGroupOptions(order.context.orderLines);

    const orders = purchasesApi.useManufacturerOrdersQuery(order.id, {
        skip: order.status !== PurchaseOrderStatus.Submitted,
    });

    // Add the order lines to a lookup table that return the manufacturer order ID.
    // This is used to filter the products by manufacturer order - ol.externalOrderLineId corresponds to the id of a PurchaseOrderLine
    const manufacturerOrderLookup = useMemo(
        () =>
            orders.data?.reduce((acc, o) => {
                o.context.orderLines.forEach(ol => {
                    acc[ol.externalOrderLineId] = o.id.toString();
                });
                return acc;
            }, {} as Record<string, string>) ?? {},
        [orders.data],
    );

    const manufacturerOrderOptions = useMemo(
        () =>
            orders.data?.map(o => ({
                label: (
                    <div className="PurchaseOrderProductList__Filters__SupplierOrderOption">
                        {o.reference} <ManufacturerOrderStatusBadge manufacturerOrder={o} />
                    </div>
                ),
                value: o.id.toString(),
            })) ?? [],
        [orders.data],
    );

    const filters = useMemo(() => {
        return FilterBuilder<PurchaseOrderLine>()
            .filter({
                label: 'Search',
                type: 'search',
                getFields: item => {
                    const result = [
                        item.context.product.name,
                        item.context.product.context.brandName,
                        item.windowGroupName,
                    ];
                    return result;
                },
            })
            .filter(
                manufacturerOrderOptions.length > 0 && {
                    label: 'Supplier Order',
                    type: 'select',
                    defaultValue: '',
                    options: manufacturerOrderOptions,
                    // this isn't used, because we are overriding applyFilter below
                    getField: () => '',
                    applyFilter: (f, value, item) => {
                        if (!value) {
                            return true;
                        }
                        return manufacturerOrderLookup[item.id] === value;
                    },
                },
            )
            .filter(
                order.status !== PurchaseOrderStatus.Submitted && {
                    label: 'Filter',
                    type: 'select',
                    options: [
                        {
                            label: 'All products',
                            value: 'all',
                        },
                        {
                            label: 'With errors',
                            value: 'problems',
                        },
                    ],
                    defaultValue: 'all',
                    allowBlank: false,
                    // this isn't used, because we are overriding applyFilter below
                    getField: () => '',
                    applyFilter: (f, value, item) => {
                        if (value === 'problems') {
                            return item.context.errors.length > 0;
                        }
                        return true;
                    },
                },
            )
            .filter({
                label: 'Window or group',
                type: 'select',
                getField: item => item.windowGroupName,
                options: [
                    { label: '(None)', value: '__NO_LABEL', isPlaceholder: true },
                    ...windowGroupOptions,
                ],
                defaultValue: '',
                allowBlank: true,
                applyFilter: (f, value, item: PurchaseOrderLine) => {
                    if (value === '__NO_LABEL') {
                        return !item.windowGroupName;
                    }
                    return item.windowGroupName?.startsWith(value) ?? false;
                },
            })
            .build();
    }, [windowGroupOptions, order.status, manufacturerOrderLookup, manufacturerOrderOptions]);

    const [urlParams] = useSearchParams();

    return (
        <div className="PurchaseOrderProductList">
            <SectionHeader
                title="Products"
                autoMargin
            />
            {order.context.orderLines.length === 0 ? (
                <div className="PurchaseOrderProductList__Empty">
                    {order.isReadOnly ? (
                        <label>No products added</label>
                    ) : (
                        <>
                            <label>No products added yet</label>
                            <MyButton
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                            />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div className="PurchaseOrderProductList__Actions">
                        {!order.isReadOnly && (
                            <MyButton
                                className="PurchaseOrderProductList__Toolbar__AddButton"
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                                fullWidth={breakpoints.isVerySmallOnly}
                            />
                        )}
                    </div>

                    <div className="PurchaseOrderProductList__Toolbar">
                        <DataTableFilters
                            filters={filters}
                            data={order.context.orderLines}
                            onChange={setFilteredProducts}
                        />
                    </div>
                    {(filteredProducts?.length ?? 0) === 0 ? (
                        <div className="PurchaseOrderProductList__ProductsFilteredOut">
                            No products in this order matched your search.
                        </div>
                    ) : (
                        <div className="PurchaseOrderProductList__Products">
                            <DataTable
                                columns={COLUMNS}
                                data={filteredProducts}
                                zebra="dark"
                                rowLinkTo={item => `products/${item.id}?${urlParams}`}
                                showHeader="narrow"
                            />
                        </div>
                    )}
                </>
            )}

            {showAddProductModal && (
                <OrderProductAddModal
                    order={order}
                    manufacturerId={order.manufacturerId}
                    close={() => setShowAddProductModal(false)}
                />
            )}
        </div>
    );
}

function OptionsDisplay({ orderLine }: { orderLine: PurchaseOrderLine }) {
    const optionsString = useMemo(
        () =>
            orderLine.context.configuration.optionGroups
                .map(og => og.options)
                .map(options => options[options.length - 1]?.value)
                .join(', '),
        [orderLine.context.configuration.optionGroups],
    );
    return (
        <div className="PurchaseOrderProductList__OptionsDisplay">
            {optionsString && (
                <div className="PurchaseOrderProductList__OptionsDisplay__Options">
                    {optionsString}
                </div>
            )}
            {orderLine.notes && (
                <div className="PurchaseOrderProductList__OptionsDisplay__Notes">
                    {orderLine.notes}
                </div>
            )}
            {orderLine.context.errors.map((err, index) => (
                <div
                    key={index}
                    className="PurchaseOrderProductList__OptionsDisplay__Errors"
                >
                    {OrderLineErrorDisplay.display(err)}
                </div>
            ))}
        </div>
    );
}

function ProductDisplay({ orderLine }: { orderLine: PurchaseOrderLine }) {
    return (
        <div className="PurchaseOrderProductList__ProductDisplay">
            <div className="PurchaseOrderProductList__ProductDisplay__Brand">
                {orderLine.context.product.context.brandName}
            </div>
            <div>{orderLine.context.product.name}</div>
        </div>
    );
}
