import { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import { Manufacturer } from 'features/settings/models/Manufacturer';
import { useMemo } from 'react';

export default function useShippingMethodOptions(manufacturer: Manufacturer | null) {
    const shippingMethodOptions = useMemo(
        (): MySelectInputOption[] | undefined =>
            manufacturer?.shipping_methods?.map(sm => ({
                label: sm.name,
                value: `${sm.id}`,
            })),
        [manufacturer],
    );
    return shippingMethodOptions;
}
