import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import PurchaseOrderCreateModal from 'features/purchases/components/PurchaseOrderCreateModal/PurchaseOrderCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrderCreatePage() {
    usePageTitle('Create');
    const navigate = useNavigate();
    const { listPath } = useOrderPresentation();
    return <PurchaseOrderCreateModal close={() => navigate(listPath)} />;
}
