import { faker } from '@faker-js/faker';
import { CustomerQuoteDetail } from '../models/CustomerQuoteDetail';
import { CustomerQuotePaymentSummary } from '../models/CustomerQuotePaymentSummary';

export const generateFakeCustomerQuoteDetail = (): CustomerQuoteDetail => {
    return {
        id: '1',
        isDepositRequired: true,
        order: {
            id: 12345,
            tuid: 'Q-12345',
            customerName: 'John Doe',
            total: 1234,
            depositAmount: 100,
            createdAt: '2024-03-27T00:00:00+1030',
            convertedAt: null,
        },
        quotePdf: {
            id: 12345,
            url: `/smart-quote.pdf`,
        },
        company: {
            id: 12345,
            name: "Pete's Blinds",
        },
    };
};

export const generateFakeCustomerQuotePaymentSummary = (
    quote: CustomerQuoteDetail,
): CustomerQuotePaymentSummary => {
    return {
        id: quote.id,
        totalReceived: 0,
        balance: quote.order.total,
        payments: [],
    };
};

export const fakeCustomerQuoteDetails = faker.helpers.multiple(generateFakeCustomerQuoteDetail, {
    count: 1,
});

export const fakeCustomerQuotePaymentSummaries = fakeCustomerQuoteDetails.map(q =>
    generateFakeCustomerQuotePaymentSummary(q),
);
