import MyMultiSelectInput from 'components/MyMultiSelectInput/MyMultiSelectInput';
import MySelectInput from 'components/MySelectInput/MySelectInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditSelect.scss';

export type PropertyEditSelectProps = {
    value: any;
    className?: string;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val: any, label?: React.ReactNode) => void;
    readonly?: boolean;
    disabled?: boolean;
    options?: { label: React.ReactNode; value: string }[];
    allowBlank?: boolean;
    allowMultiple?: boolean;
    autoFocus?: boolean;
    fullWidth?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
};

export default function PropertyEditSelect({
    value,
    className,
    label,
    hint,
    inlineHint,
    onChange,
    readonly = false,
    disabled = false,
    options,
    allowBlank = false,
    allowMultiple,
    autoFocus,
    fullWidth,
    validationRequired,
    validationCustom,
}: PropertyEditSelectProps) {
    const handleInput = (val: any, lbl?: React.ReactNode) => {
        onChange?.(val, lbl);
    };

    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={value}
                options={options}
            />
        );
    }
    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditSelect', className)}
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
            fullWidth={fullWidth}
        >
            {allowMultiple ? (
                <MyMultiSelectInput
                    className="PropertyEditSelect__Input--MultiSelect"
                    id={inputId}
                    value={value}
                    options={options}
                    disabled={disabled}
                    allowBlank={allowBlank}
                    multiple={allowMultiple}
                    handleInput={handleInput}
                    autoFocus={autoFocus}
                    validationRequired={validationRequiredWithLabel}
                    validationCustom={validationCustom}
                    fullWidth={fullWidth}
                />
            ) : (
                <MySelectInput
                    className="PropertyEditSelect__Input--Select"
                    value={value}
                    options={options}
                    disabled={disabled}
                    allowBlank={allowBlank}
                    multiple={allowMultiple}
                    handleInput={handleInput}
                    autoFocus={autoFocus}
                    fullWidth={fullWidth}
                    validationRequired={validationRequiredWithLabel}
                    validationCustom={validationCustom}
                />
            )}
        </PropertyEdit>
    );
}
