import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import { QuoteOrderLine } from 'features/quotes/models/QuoteOrderLine';
import { useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import { StrictUnion } from 'utils/typeHelpers';

/** Get a comparable string for sorting products by
 * Group name (alphabetical but empty group comes last)
 * Then product name (alphabetical)
 * Then created_at date
 */
const comparisonString = (orderLine: StrictUnion<PurchaseOrderLine | QuoteOrderLine>) => {
    // Replace spaces with underscores in names to better sort across tokens
    // eg "Room/Window" would be sorted after "Room 2/Window" normally,
    // Replacing the spaces puts Room_2 after Room without having to consider splitting on the / delimiter
    const groupName = (orderLine.windowGroupName ?? '').replace(/\s/g, '_');
    const productName = orderLine.context.product.name?.replace(/\s/g, '_');
    const brandName = orderLine.context.product.context.brandName.replace(/\s/g, '_');
    return `${groupName}||${brandName}||${productName}||${orderLine.orderWindowId}`;
};

export const orderProductSort = (
    a: StrictUnion<PurchaseOrderLine | QuoteOrderLine>,
    b: StrictUnion<PurchaseOrderLine | QuoteOrderLine>,
) => {
    return typescriptNaturalSort(comparisonString(a), comparisonString(b));
};

/** Get a list of window or group names for the current order
 * for use in the filter dropdown and the typeahead when entering group name
 * This includes all unique entries but also provides heirarchical grouping
 * eg. "Building/Room/Window" will provide options for "Building", "Building/Room", and "Building/Room/Window"
 */
export const useWindowGroupOptions = (
    orderLines?: StrictUnion<PurchaseOrderLine | QuoteOrderLine>[],
) => {
    const labels = useMemo(() => {
        const tokens = new Set<string>();
        const allProducts = [...(orderLines ?? [])];
        allProducts.sort(orderProductSort);
        allProducts.forEach(product => {
            if (!product.windowGroupName) {
                // Exclude empty labels from list
                return;
            }
            const productTokens = product.windowGroupName.split('/');
            productTokens.reduce((accumulator, currentValue) => {
                accumulator = accumulator ? `${accumulator}/${currentValue}` : currentValue;
                tokens.add(accumulator);
                return accumulator;
            }, '');
        });
        return Array.from(tokens.values()).map(l => ({ label: l, value: l }));
    }, [orderLines]);
    return labels;
};
