import React, { forwardRef } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './StatusPill.scss';

export type StatusPillSize = 'small' | 'medium' | 'large';

// Using forwardRef to allow tooltips to be displayed on the status pill
// otherwise the tooltip would need an additional wrapper element around this component
const StatusPill = forwardRef(
    (
        {
            className,
            children,
            size = 'medium',
            ...props
        }: {
            className?: string;
            children: React.ReactNode;
            size?: StatusPillSize;
        },
        ref: React.Ref<HTMLDivElement>,
    ) => {
        return (
            <div
                {...props}
                ref={ref}
                className={coalesceClassNames('StatusPill', `StatusPill--${size}`, className)}
            >
                {children}
            </div>
        );
    },
);

StatusPill.displayName = 'StatusPill';

export default StatusPill;
