import React, { useContext, useEffect, useState } from 'react';
import { throttle } from 'throttle-debounce';

const BREAK_SMALL = 448; // phone
const BREAK_MEDIUM = 768; // tablet
const BREAK_LARGE = 1280; // large tablet / desktop

type BreakpointsState = {
    currentBreakpoint: 'very-small' | 'small' | 'medium' | 'large';
    isVerySmallOnly: boolean;
    isSmallOnly: boolean;
    isMediumOnly: boolean;
    isLargeOnly: boolean;
    isSmallDown: boolean;
    isSmallUp: boolean;
    isMediumDown: boolean;
    isMediumUp: boolean;
};

export const BreakpointsContext = React.createContext<BreakpointsState>({} as BreakpointsState);
export const useBreakpoints = () => useContext(BreakpointsContext);

export default function Breakpoints({ children }: { children?: React.ReactFragment }) {
    const [state, setState] = useState<BreakpointsState>({} as BreakpointsState);

    useEffect(() => {
        const onResizeThrottled = throttle(200, () => {
            const size = document.documentElement.clientWidth;
            const isVerySmallOnly = size < BREAK_SMALL;
            const isSmallOnly = size < BREAK_MEDIUM && size >= BREAK_SMALL;
            const isMediumOnly = size >= BREAK_MEDIUM && size < BREAK_LARGE;

            const currentBreakpoint = isVerySmallOnly
                ? 'very-small'
                : isSmallOnly
                ? 'small'
                : isMediumOnly
                ? 'medium'
                : 'large';

            // update state only if breakpoint has changed
            if (currentBreakpoint !== state.currentBreakpoint) {
                const newState: BreakpointsState = {
                    currentBreakpoint,
                    isVerySmallOnly,
                    isSmallOnly,
                    isMediumOnly,
                    isLargeOnly: size >= BREAK_LARGE,
                    isSmallDown: size < BREAK_MEDIUM,
                    isSmallUp: size >= BREAK_SMALL,
                    isMediumDown: size < BREAK_LARGE,
                    isMediumUp: size >= BREAK_MEDIUM,
                };

                setState(newState);
            }
        });
        onResizeThrottled();
        window.addEventListener('resize', onResizeThrottled);
        return () => window.removeEventListener('resize', onResizeThrottled);
    }, [state]);

    return <BreakpointsContext.Provider value={state}>{children}</BreakpointsContext.Provider>;
}
