import MySelectInput from 'components/MySelectInput/MySelectInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import {
    CriteriaCompProps,
    DataTableCriteriaFieldBase,
    DataTableCriteriaSelectOption,
} from '../DataTableCriteriaTypes';

export type SelectCriteriaFieldConfig = DataTableCriteriaFieldBase & {
    type: 'select';
    options?: DataTableCriteriaSelectOption[];
    allowBlank?: boolean;
};

export default function SelectFilter({
    field,
    onChange,
}: CriteriaCompProps<SelectCriteriaFieldConfig>) {
    const handleInput = (val: string) => {
        field.value = val;
        onChange?.(field);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableCriteria__Field',
                'DataTableCriteria__Field--Select',
                field.config.field && `DataTableCriteria__Field--${field.config.field}`,
            )}
        >
            <MySelectInput
                label={field.config.label}
                value={field.value}
                options={field.config.options}
                handleInput={handleInput}
                allowBlank={field.config.allowBlank}
            />
        </div>
    );
}
