import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store/store';
import { isEmpty, isNone, mergeDeep } from 'utils/helpers';
import regexs from 'utils/regexs';

export type FormFieldInfo = {
    key: string;
    error: string;
    value: any;
    initialValue: any;
    isRevealed: boolean;
};

export type FormState = {
    id: string;
    isValid: boolean;
    isDirty: boolean;
    fields: FormFieldInfo[];
};

export type BuiltinValidators = {
    validationRequired?: string | boolean;
    validationRegex?: [RegExp | string, string];
    validationCustom?: string | false;
};

// specify eg validationRegex='email' to use these builtin regexs
const builtinRegexs: { [key: string]: RegExp } = {
    email: regexs.email,
    emailAllowBlank: regexs.emailAllowBlank,
    phone: regexs.phone,
    phoneAllowBlank: regexs.phoneAllowBlank,
};

const initialState = {
    forms: {},
    editModels: {},
} as {
    forms: { [id: string]: FormState | undefined };
    editModels: { [id: string]: any };
};

/** Validate a value against the provided validators and return the first error encountered */
const getError = (val: any, validators: BuiltinValidators) => {
    if (validators.validationRequired && isEmpty(val)) {
        return (
            validators.validationRequired &&
            (validators.validationRequired === true
                ? 'This field is required'
                : validators.validationRequired)
        );
    }
    if (validators.validationRegex) {
        // eslint-disable-next-line prefer-const
        const [regexArg, message] = validators.validationRegex;
        let regex: RegExp | null = null;
        if (regexArg && typeof regexArg === 'string') {
            regex = builtinRegexs[regexArg] || new RegExp(regexArg);
        }

        if (regex && !regex.test(val || '')) {
            return message || 'Please enter a valid value';
        }
    }
    if (validators.validationCustom) {
        return validators.validationCustom;
    }
    // all good
    return '';
};

const slice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        setEditModel: (
            state,
            action: PayloadAction<{
                id: string;
                model: any;
            }>,
        ) => {
            if (isNone(action.payload.model)) {
                delete state.editModels[action.payload.id];
            } else {
                const modelCopy = action.payload
                    ? JSON.parse(JSON.stringify(action.payload.model))
                    : undefined;
                state.editModels[action.payload.id] = modelCopy;
            }
        },
        updateEditModelField(
            state,
            action: PayloadAction<{
                id: string;
                data: any;
            }>,
        ) {
            mergeDeep(state.editModels[action.payload.id], action.payload.data);
        },
        /** Runs validation for a field when the value changes
         * If the form + field is not registered yet, this will also register it */
        validateField: (
            state,
            action: PayloadAction<{
                formId: string;
                /** The validationKey of the field */
                key: string;
                value: any;
                /** If true then new fields will be marked as isRevealed:true immediately when registered */
                revealImmediately?: boolean;
                validators: BuiltinValidators;
            }>,
        ) => {
            let form = state.forms[action.payload.formId];
            if (!form) {
                // set up new form state now
                form = {
                    id: action.payload.formId,
                    isValid: true,
                    isDirty: false,
                    fields: [],
                };
                state.forms[action.payload.formId] = form;
            }
            let field = form.fields.find(f => f.key === action.payload.key);
            if (!field) {
                field = {
                    key: action.payload.key,
                    initialValue: action.payload.value,
                    value: action.payload.value,
                    isRevealed: action.payload.revealImmediately || false,
                    error: '',
                };

                form.fields.push(field);
            }

            field.value = action.payload.value;
            field.error = getError(action.payload.value, action.payload.validators);

            // update form isValid/Dirty states
            form.isValid = form.fields.every(f => !f.error);
            form.isDirty = form.fields.some(f => {
                return f?.value !== f?.initialValue;
            });
        },
        unregisterField(state, action: PayloadAction<{ formId: string; key: string }>) {
            const form = state.forms[action.payload.formId];
            if (form) {
                form.fields = form.fields.filter(f => f.key !== action.payload.key);
                state.forms[action.payload.formId] = form;

                if (form.fields.length === 0) {
                    delete state.forms[action.payload.formId];
                }
            }
        },
        revealError(state, action: PayloadAction<{ formId: string; key: string }>) {
            const form = state.forms[action.payload.formId];
            if (form) {
                const field = form.fields.find(f => f.key === action.payload.key);
                if (field) {
                    field.isRevealed = true;
                }
            }
        },
        revealAllErrors(state, action: PayloadAction<{ formId: string; key: string }>) {
            const form = state.forms[action.payload.formId];
            if (form) {
                form.fields.forEach(f => {
                    f.isRevealed = true;
                });
            }
        },
    },
    // extraReducers: builder => {

    // },
});

export const {
    setEditModel,
    updateEditModelField,
    validateField,
    unregisterField,
    revealError,
    revealAllErrors,
} = slice.actions;

export default slice.reducer;

export const selectFormState = (formId: string) => (state: RootState) => state.forms.forms[formId];
export const selectEditModel = (id: string) => (state: RootState) => state.forms.editModels[id];
