import { z } from 'zod';
import { ProductConfigurationSchema } from '../../orders/models/ProductConfiguration';

export const ManufacturerOrderLineSchema = z.object({
    id: z.number(),
    externalOrderLineId: z.number(),
    manufacturerOrderId: z.number(),
    notes: z.string().nullable(),
    quantity: z.number(),
    sellPrice: z.number(),
    context: z.object({
        configuration: ProductConfigurationSchema,
    }),
});

export type ManufacturerOrderLine = z.infer<typeof ManufacturerOrderLineSchema>;
