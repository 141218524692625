import PageHeader from 'components/PageHeader/PageHeader';
import { WidgetDisplay } from 'features/dashboard/components/WidgetDisplay/WidgetDisplay';
import dashboardApi from 'features/dashboard/dashboard.api';
import React from 'react';
import { Outlet } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function DashboardPage() {
    usePageTitle('Dashboard');
    const query = dashboardApi.useDashboardDataQuery();
    return (
        <>
            <PageHeader title="Dashboard" />
            <WidgetDisplay
                data={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
            />
            <Outlet />
        </>
    );
}
