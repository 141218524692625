import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import authApi from 'features/auth/auth.api';
import { selectDidLoginError, selectDidSessionExpire } from 'features/auth/auth.slice';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import './LoginForm.scss';

export default function LoginForm() {
    const [login, loginState] = authApi.useLoginMutation();
    const didSessionExpire = useAppSelector(selectDidSessionExpire);
    const didLoginError = useAppSelector(selectDidLoginError);

    const submit = async (username: string, password: string) => {
        login({ username, password });
    };

    return (
        <LoginFormPure
            submit={submit}
            didSessionExpire={didSessionExpire}
            isLoading={loginState.isLoading}
            didError={loginState.isError || didLoginError}
        />
    );
}

export function LoginFormPure({
    submit,
    isLoading,
    didSessionExpire,
    didError,
}: {
    submit?: (username: string, password: string) => Promise<any>;
    isLoading: boolean;
    didSessionExpire: boolean;
    didError: boolean;
}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const submitForm = useCallback(async () => {
        await submit?.(username, password);
    }, [submit, username, password]);

    return (
        <FormValidation submit={submitForm}>
            {({ handleSubmit }) => (
                <form
                    noValidate
                    className="Login__Form"
                    onSubmit={handleSubmit}
                >
                    {didSessionExpire && (
                        <MessagePanel messageType="warning">
                            Your session has expired, please log in again.
                        </MessagePanel>
                    )}
                    {didError && (
                        <MessagePanel messageType="error">
                            Login failed. Please check your username and password and try again.
                        </MessagePanel>
                    )}

                    <MyTextInput
                        name="username"
                        label="Username"
                        type="email"
                        value={username}
                        handleInput={setUsername}
                        validationRequired="Please enter your username"
                        fullWidth
                        shrinkLabel
                        autoFocus
                        disabled={isLoading}
                        size="medium"
                        margin="dense"
                    />
                    <MyTextInput
                        name="password"
                        label="Password"
                        type="password"
                        value={password}
                        handleInput={setPassword}
                        validationRequired={'Please enter your password'}
                        fullWidth
                        shrinkLabel
                        disabled={isLoading}
                        size="medium"
                        margin="dense"
                    />
                    <MyButton
                        buttonType="Accent"
                        size="large"
                        type="submit"
                        isLoading={isLoading}
                    >
                        Login
                    </MyButton>
                </form>
            )}
        </FormValidation>
    );
}
