import z from 'zod';

export const UserSchema = z.object({
    id: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    country_code: z.string(),
    admin_company_id: z.string(),
});

export type User = z.infer<typeof UserSchema>;

export function getUserFullName(user?: User) {
    return `${user?.first_name} ${user?.last_name}`.trim();
}

export function getUserInitials(user?: User) {
    const first = user?.first_name.charAt(0) || '';
    const last = user?.last_name.charAt(0) || '';
    return `${first}${last}`.toUpperCase();
}
