import { ApiTagType, api } from 'services/api';
import { DashboardData, DashboardDataSchema } from './models/DashboardData';

export const dashboardApi = api.injectEndpoints({
    endpoints: build => ({
        /** Get the list of product document categories */
        dashboardData: build.query<DashboardData, void>({
            query: () => ({
                url: `/dashboard`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => DashboardDataSchema.parse(result),
            providesTags: [ApiTagType.PurchaseOrder, ApiTagType.Quote],
        }),
    }),
});

export default dashboardApi;
