import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Icons from 'Icons';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconType } from 'react-icons';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import './MyTextInput.scss';

type MyTextInputProps = {
    value?: string;
    handleInput?: (val: string) => void;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationRegex?: [RegExp | string, string];
    validationCustom?: string | false;
    autoFocus?: boolean;
    inputRef?: React.Ref<any>;
    maxLength?: number;
    shrinkLabel?: boolean;
    // min/max for type=number or date only
    min?: number | string;
    max?: number | string;
    LeftIcon?: IconType;
    RightIcon?: IconType;
    showClearButton?: boolean;
} & TextFieldProps;

export default function MyTextInput({
    // manually specify fields that we dont want to pass in to TextField
    value,
    handleInput,
    validationKey,
    validationRequired,
    validationRegex,
    validationCustom,
    autoFocus = false,
    inputRef,
    maxLength,
    shrinkLabel,
    min,
    max,
    LeftIcon,
    RightIcon,
    showClearButton = false,
    size = 'small',
    margin = 'none',
    ...props
}: MyTextInputProps) {
    const myProps = {
        margin: 'normal',
        ...props,
    };

    const _inputRef = useRef<HTMLInputElement>();

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value;
            if (maxLength && val.length > maxLength) {
                e.target.value = e.target.value.substring(0, maxLength);
            }
            props.onChange?.(e);
            handleInput?.(e.target.value);
        },
        [handleInput, maxLength, props],
    );

    const clearText = useCallback(() => {
        handleInput?.('');
        requestAnimationFrame(() => {
            _inputRef.current?.focus();
        });
    }, [handleInput]);

    const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
    const toggleRevealPassword = () => {
        setIsPasswordRevealed(!isPasswordRevealed);
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (autoFocus) {
            const t = setTimeout(() => {
                _inputRef.current?.focus();
            }, 100);
            return () => clearTimeout(t);
        }
    }, [autoFocus, inputRef]);

    return (
        <FieldValidator
            value={value}
            validationKey={validationKey || (props.name as string)}
            validationRequired={validationRequired}
            validationRegex={validationRegex}
            validationCustom={validationCustom}
        >
            {({ revealError, error }) => (
                <TextField
                    {...myProps}
                    value={value}
                    error={!!(props.error || error)}
                    size={size}
                    margin={margin}
                    className={coalesceClassNames(
                        'MyTextInput',
                        error && 'MyTextInput--error',
                        props.label ? 'MyTextInput--with-label' : 'MyTextInput--no-label',
                        props.type && `MyTextInput--type-${props.type}`,
                        props.className,
                        isEmpty(value) && 'MyTextInput--empty',
                    )}
                    inputRef={ref => {
                        _inputRef.current = ref;

                        if (inputRef) {
                            (inputRef as React.MutableRefObject<any>).current = ref;
                        }
                    }}
                    type={isPasswordRevealed ? 'text' : props.type}
                    inputProps={props.type === 'number' ? { min, max } : undefined}
                    InputProps={{
                        startAdornment: LeftIcon ? (
                            <InputAdornment position="start">
                                <LeftIcon className="MyTextInput__LeftIcon" />
                            </InputAdornment>
                        ) : undefined,
                        endAdornment: (RightIcon ||
                            props.type === 'password' ||
                            (value && showClearButton)) && (
                            <>
                                {RightIcon && (
                                    <InputAdornment position="start">
                                        <RightIcon className="MyTextInput__RightIcon" />
                                    </InputAdornment>
                                )}
                                {props.type === 'password' ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleRevealPassword}
                                            onMouseDown={toggleRevealPassword}
                                            edge="end"
                                        >
                                            {isPasswordRevealed ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ) : value && showClearButton ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            className="MyTextInput__ClearButton"
                                            aria-label="clear"
                                            onClick={clearText}
                                            onMouseDown={toggleRevealPassword}
                                            edge="end"
                                        >
                                            <Icons.Clear />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null}
                            </>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: shrinkLabel,
                    }}
                    onChange={handleChange}
                    onBlur={(e: any) => {
                        props.onBlur?.(e);
                        revealError();
                    }}
                />
            )}
        </FieldValidator>
    );
}
