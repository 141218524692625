import Env from 'config/Env';
import { DateTime } from 'luxon';
import { http, HttpResponse } from 'msw';
import { wait } from 'utils/helpers';
import {
    fakeCustomerQuoteDetails,
    fakeCustomerQuotePaymentSummaries,
} from './faker/CustomerQuoteFaker';

const BASE_URL = `${Env.API_BASE_URL}`;
export const customerQuotesHandlers = [
    http.get(`${BASE_URL}/customerquote/:id`, async ({ params }) => {
        const id = params.id as string;
        const result = fakeCustomerQuoteDetails.find(q => `${q.id}` === id);
        if (!result) {
            return new HttpResponse(`Failed to find customer quote with id ${params.id}`, {
                status: 400,
            });
        }
        await wait(2000);
        return HttpResponse.json(result);
    }),

    http.get(`${BASE_URL}/customerquote/:id/payments`, async ({ params }) => {
        const id = params.id as string;
        const result = fakeCustomerQuotePaymentSummaries.find(q => `${q.id}` === id);
        if (!result) {
            return new HttpResponse(`Failed to find customer quote with id ${params.id}`, {
                status: 400,
            });
        }
        await wait(2000);
        return HttpResponse.json(result);
    }),

    http.post(`${BASE_URL}/customerquote/:id/accept`, async ({ params }) => {
        const id = params.id as string;
        const record = fakeCustomerQuoteDetails.find(q => `${q.id}` === id);
        if (!record) {
            return new HttpResponse(`Failed to find customer quote with id ${params.id}`, {
                status: 400,
            });
        }
        record.order.convertedAt = DateTime.now().toISO();
        await wait(2000);
        return new HttpResponse(null, { status: 200 });
    }),
];
