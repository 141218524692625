import { createAction } from '@reduxjs/toolkit';
import { type AlertProps } from 'components/Dialogs/Alert';
import { type ToastProps } from 'components/Dialogs/Toast';

export type ShowDialogProps =
    | {
          type: 'alert';
          props: AlertProps;
      }
    | {
          type: 'toast';
          props: ToastProps;
      };

export const showDialogAction = createAction<ShowDialogProps>('showDialogAction');
