import OrderPresentation from 'features/orders/providers/OrderPresentation';
import QuoteDetailModal from 'features/quotes/components/QuoteDetailModal/QuoteDetailModal';
import quotesApi from 'features/quotes/quotes.api';
import React, { useCallback } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function DealQuotePage() {
    const { quoteId, workflowId, dealId } = useParams() as {
        quoteId: string;
        workflowId: string;
        dealId: string;
    };

    const orderQuery = quotesApi.useQuoteDetailQuery(quoteId);

    usePageTitle(orderQuery.data?.tuid);

    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate(`..`);
    }, [navigate]);

    return quoteId ? (
        <OrderPresentation
            isQuotes={true}
            listPath={`/workflows/${workflowId}/${dealId}`}
            detailPath={`/workflows/${workflowId}/${dealId}/quotes`}
        >
            <QuoteDetailModal
                model={orderQuery.data}
                isLoading={orderQuery.isLoading}
                isError={orderQuery.isError}
                close={handleClose}
            />
            <Outlet />
        </OrderPresentation>
    ) : null;
}
