import OrderProductModal from 'features/orders/components/OrderProductModal/OrderProductModal';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import quotesApi from 'features/quotes/quotes.api';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function DealQuoteProductPage() {
    usePageTitle('Product');
    const { quoteId = '', productId = '' } = useParams();
    return (
        <QuoteProductPagePure
            key={productId}
            id={quoteId}
            productId={parseInt(productId, 10)}
        />
    );
}

function QuoteProductPagePure({ id, productId }: { id: string; productId: number }) {
    const navigate = useNavigate();
    const { detailPath } = useOrderPresentation();

    const query = quotesApi.useQuoteDetailQuery(id);

    const model = useMemo(
        () => query.currentData?.context.orderLines.find(ol => ol.id === productId),
        [query.currentData, productId],
    );

    useEffect(() => {
        if (query.currentData && !query.isFetching && !model) {
            // invalid product id - redirect
            navigate(`${detailPath}/${id}`);
        }
    }, [detailPath, id, model, navigate, query.currentData, query.isFetching]);

    return productId ? (
        <>
            <OrderProductModal
                model={model}
                order={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                close={() => navigate(`${detailPath}/${id}`)}
            />
        </>
    ) : null;
}
