import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import 'config/i18n';

import { initMockServiceWorker } from 'mocks';
import reportWebVitals from 'reportWebVitals';

import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'utils/posthogHelpers';

const options: Partial<PostHogConfig> = {
    api_host: window.POSTHOG_HOST,
};

// avoid console errors on startup by deffering rendering
// until after the initialization of the mock service worker
// taken from https://github.com/mswjs/msw/issues/2053
initMockServiceWorker().then(() => {
    ReactDOM.render(
        <React.StrictMode>
            <PostHogProvider
                enabled={window.POSTHOG_ENABLED}
                apiKey={window.POSTHOG_API_KEY}
                options={options}
            >
                <App />
            </PostHogProvider>
        </React.StrictMode>,
        document.getElementById('root'),
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});
