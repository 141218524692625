import MyAutocompleteInput, {
    MyAutocompleteInputOption,
} from 'components/MyAutocompleteInput/MyAutocompleteInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import { DataTableFilterConfigBase, FilterCompProps } from '../DataTableFilterTypes';

export type AutocompleteFilterConfig<T> = DataTableFilterConfigBase & {
    type: 'autocomplete';
    getField: (item: T) => string | null | undefined;
    options?: MyAutocompleteInputOption[];
};

export default function AutocompleteFilter<T>({
    filter,
    onChange,
}: FilterCompProps<AutocompleteFilterConfig<T>>) {
    const handleChange = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--AutocompleteFilter',
                filter.config.className,
            )}
        >
            <MyAutocompleteInput
                label={filter.config.label}
                value={filter.value}
                options={filter.config.options}
                onChange={handleChange}
            />
        </div>
    );
}

/** Default applyFilter function for autocomplete filters */
export function applyAutocompleteFilter<T>(
    config: AutocompleteFilterConfig<T>,
    value: string,
    item: T,
) {
    let val = config.getField(item);
    if (isEmpty(val)) {
        val = '';
    }
    return String(val) === value;
}
