import React from 'react';
import Icons from 'Icons';
import MyMenuButton, { MyMenuButtonProps } from 'components/MyMenuButton/MyMenuButton';
import './MyMenuKebabButton.scss';
import coalesceClassNames from 'utils/coalesceClassNames';

export default function MyMenuKebabButton(props: MyMenuButtonProps) {
    return (
        <MyMenuButton
            IconRight={Icons.MenuDots}
            buttonType="Hollow"
            {...props}
            className={coalesceClassNames('MyMenuKebabButton', props.className)}
        />
    );
}
