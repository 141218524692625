import z from 'zod';

export const ProductDocumentCategorySchema = z.object({
    id: z.number(),
    category: z.string(),
    is_active: z.number(), // 0 or 1,
    sort_order: z.number(),
});

export type ProductDocumentCategory = z.infer<typeof ProductDocumentCategorySchema>;
