import { ModulesConfigSchema } from 'features/settings/models/ModulesConfigSchema';
import z from 'zod';
import { CompanySchema } from './Company';
import { CountrySchema } from './Country';
import { ManufacturerSchema } from './Manufacturer';

export const AccountSettingsSchema = z.object({
    main_company: CompanySchema,
    country: CountrySchema,
    manufacturers: z.array(ManufacturerSchema),
    modules: ModulesConfigSchema,
});

export type AccountSettings = z.infer<typeof AccountSettingsSchema>;
