import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import React from 'react';
import './MyPdfViewer.scss';

export default function MyPdfViewer({ url }: { url: string }) {
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div className="MyPdfViewer">
                <Viewer
                    fileUrl={url}
                    defaultScale={SpecialZoomLevel.PageWidth}
                />
            </div>
        </Worker>
    );
}
