import z from 'zod';
import FlagAppearance from '../enums/FlagAppearance';

export const FlagValueSchema = z.object({
    id: z.string(),
    label: z.string(),
    appearance: z.nativeEnum(FlagAppearance),
    showOnCard: z.boolean(),
    isDefault: z.boolean(),
});

export type FlagValue = z.infer<typeof FlagValueSchema>;
