import z from 'zod';

export const ManufacturerShippingMethodSchema = z.object({
    id: z.number(),
    name: z.string(),
    address: z.string().nullable(),

    // not used for now
    // type: z.enum(['FIXED', 'PERCENT', 'SCRIPT']),
    // value: z.string().nullable(),
});

export type ManufacturerShippingMethod = z.infer<typeof ManufacturerShippingMethodSchema>;
