import MyMultiEmailInput from 'components/MyMultiEmailInput/MyMultiEmailInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import { BuiltinValidators } from 'store/forms.slice';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditMultiEmail.scss';

export type PropertyEditMultiEmailProps = {
    value: string;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val: any) => void;
    readonly?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
} & BuiltinValidators;

export default function PropertyEditMultiEmail({
    value,
    label,
    hint,
    inlineHint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
}: PropertyEditMultiEmailProps) {
    const handleInput = (val: any) => {
        onChange?.(val);
    };

    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={value?.split(',').join(', ')}
                verticalAlign={'top'}
            />
        );
    }

    return (
        <PropertyEdit
            className="PropertyEditMultiEmail"
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
            verticalAlign="top"
        >
            <MyMultiEmailInput
                className="PropertyEditMultiEmail__Input"
                id={inputId}
                value={value}
                disabled={disabled}
                handleInput={handleInput}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            />
        </PropertyEdit>
    );
}
