import MeasureSystem from 'features/settings/enums/MeasureSystem';
import { selectCurrentCountry } from 'features/settings/settings.slice';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { formatMeasurement } from 'utils/measurementHelper';
import './MeasurementDisplay.scss';

export default function MeasurementDisplay({
    value,
    showMetricUnit = false,
}: {
    value: number;
    showMetricUnit?: boolean;
}) {
    const strValue = formatMeasurement(value);
    const [whole, fraction] = strValue.split(' ');
    const [numerator, denominator] = fraction ? fraction.split('/') : [];

    const country = useAppSelector(selectCurrentCountry);

    return (
        <span className="MeasurementDisplay">
            {whole}
            {fraction && (
                <>
                    &nbsp;
                    {numerator}&#8260;{denominator}
                </>
            )}
            {/* Unit */}
            {country?.measure_system === MeasureSystem.Imperial ? (
                <span className="unit">"</span>
            ) : (
                showMetricUnit && <span className="unit">mm</span>
            )}
        </span>
    );
}
