import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MessagePanel.scss';

export default function MessagePanel({
    className,
    messageType = 'info',
    children,
    shake = false,
    title,
    actions,
    autoMargin,
}: {
    className?: string;
    messageType?: 'info' | 'warning' | 'error';
    children?: React.ReactFragment;
    shake?: boolean;
    title?: React.ReactFragment;
    actions?: React.ReactFragment;
    autoMargin?: boolean;
}) {
    return (
        <div
            className={coalesceClassNames(
                'MessagePanel',
                `MessagePanel--${messageType}`,
                actions && `MessagePanel--with-actions`,
                autoMargin && `MessagePanel--auto-margin`,
                shake && `MessagePanel--shake`,
                className,
            )}
        >
            <div className="MessagePanel__Inner">
                {title && <h2 className="MessagePanel__Inner__Title">{title}</h2>}
                <div className="MessagePanel__Inner__Content">{children}</div>
                {actions && <div className="MessagePanel__Inner__Actions">{actions}</div>}
            </div>
        </div>
    );
}
