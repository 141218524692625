import Icons from 'Icons';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import PurchaseOrdersTable from 'features/purchases/components/PurchaseOrdersTable/PurchaseOrdersTable';
import React from 'react';
import { Outlet } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrdersPage() {
    usePageTitle('Orders');

    return (
        <OrderPresentation
            isQuotes={false}
            listPath="/orders"
            detailPath="/orders"
        >
            <PageHeader title="Orders">
                <MyButton
                    label="New order"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    href="new"
                    LinkComponent={MyButtonLink}
                />
            </PageHeader>

            <PurchaseOrdersTable />

            <Outlet />
        </OrderPresentation>
    );
}
