import z from 'zod';
import WorkflowPhase from '../enums/WorkflowPhase';

export const WorkflowStatusSchema = z.object({
    id: z.string(),
    name: z.string(),
    phase: z.nativeEnum(WorkflowPhase),
});

export type WorkflowStatus = z.infer<typeof WorkflowStatusSchema>;
