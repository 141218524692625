import { CssBaseline, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import 'App.scss';
import AppRoutes from 'AppRoutes';
import Theme from 'Theme';
import AppUpdater from 'providers/AppUpdater';
import Breakpoints from 'providers/Breakpoints';
import DialogManager from 'providers/DialogManager';
import MyRollbarProvider from 'providers/MyRollbarProvider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import { history, store } from 'store/store';

export default function App() {
    return (
        <>
            <MyRollbarProvider>
                <ReduxProvider store={store}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={Theme}>
                            <CssBaseline />
                            <HistoryRouter
                                history={history}
                                basename="/"
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterLuxon}
                                    adapterLocale="en-AU"
                                >
                                    <Breakpoints>
                                        <DialogManager>
                                            <AppUpdater>
                                                <AppRoutes />
                                            </AppUpdater>
                                        </DialogManager>
                                    </Breakpoints>
                                </LocalizationProvider>
                            </HistoryRouter>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </ReduxProvider>
            </MyRollbarProvider>
        </>
    );
}
