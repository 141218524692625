import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import MyButton from 'components/MyButton/MyButton';
import { MyToggleSwitchInput } from 'components/MyToggleSwitchInput/MyToggleSwitchInput';
import { DealDetail } from 'features/deals/models/DealDetail';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import QuoteCreateModal from 'features/quotes/components/QuoteCreateModal/QuoteCreateModal';
import QuoteStatusBadge from 'features/quotes/components/QuoteStatusBadge/QuoteStatusBadge';
import QuoteStatus from 'features/quotes/enums/QuoteStatus';
import { Quote } from 'features/quotes/models/Quote';
import quotesApi from 'features/quotes/quotes.api';
import Icons from 'Icons';
import React, { useMemo, useState } from 'react';
import { formatCurrency } from 'utils/helpers';
import './DealQuotesTab.scss';

const COLUMNS = ColumnBuilder<Quote>()
    .column({
        key: 'tuid',
        label: 'Quote',
        isSortable: true,
        getValue: item => item.tuid,
    })
    .column({
        key: 'status',
        label: 'Status',
        isSortable: false,
        getValue: () => QuoteStatus.Draft,
        renderValue: (val, item) => <StatusCell model={item} />,
    })
    .column({
        key: 'products',
        label: 'Products',
        isSortable: false,
        getValue: item => item.context.orderLineCount || null,
        emptyDash: true,
        align: 'center',
    })
    .column({
        key: 'quantity',
        label: 'Total qty',
        isSortable: false,
        getValue: item => item.context.totalQuantity || null,
        emptyDash: true,
        align: 'center',
    })
    .column({
        key: 'total',
        label: 'Total price',
        isSortable: true,
        getValue: item => {
            if (item.context.orderLineCount === 0) {
                return null;
            }
            return item.totalSellPriceOverride ?? item.totalSellPrice;
        },
        renderValue: (value, item) => {
            if (item.totalSellPriceOverride === null) {
                return formatCurrency(value);
            }
            return (
                <>
                    <s>{formatCurrency(item.totalSellPrice)}</s>{' '}
                    {formatCurrency(item.totalSellPriceOverride)}
                </>
            );
        },
        emptyDash: true,
    })
    .build();

export function DealQuotesTab({ model }: { model: DealDetail }) {
    const [showArchived, setShowArchived] = useState(false);
    const filters = useMemo(() => {
        return FilterBuilder<Quote>()
            .filter({
                label: 'Search',
                type: 'search',
                getFields: item => [item.tuid, item.description],
                defaultValue: '',
            })
            .build();
    }, []);

    const [filteredData, setFilteredData] = useState<Quote[]>();
    const query = quotesApi.useQuoteListQuery({
        dealId: model.id,
        archived: showArchived,
    });

    const [showAddQuoteModal, setShowAddQuoteModal] = React.useState(false);

    return (
        <>
            <div className="DealQuotesTab__Topbar">
                <MyButton
                    label="Add quote"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    onClick={() => setShowAddQuoteModal(true)}
                />
            </div>

            <DataTableFilters
                filters={filters}
                data={query.data}
                onChange={setFilteredData}
                className="DealQuotesTab__Filters"
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
            >
                <MyToggleSwitchInput
                    label="Archived"
                    value={showArchived}
                    onChange={setShowArchived}
                />
            </DataTableFilters>
            <DataTable
                className="DealQuotesTab__DataTable"
                columns={COLUMNS}
                isLoading={query.isLoading}
                isRefreshing={query.isFetching}
                isError={query.isError}
                data={filteredData}
                showHeader="narrow"
                zebra="dark"
                rowLinkTo={quote => `quotes/${quote.id}`}
                emptyState="No quotes found"
            />

            {showAddQuoteModal && (
                <OrderPresentation
                    isQuotes={true}
                    listPath={`/workflows/${model.workflowId}/${model.id}`}
                    detailPath={`/workflows/${model.workflowId}/${model.id}/quotes`}
                >
                    <QuoteCreateModal
                        dealId={model.id}
                        close={() => setShowAddQuoteModal(false)}
                    />
                </OrderPresentation>
            )}
        </>
    );
}

function StatusCell({ model }: { model: Quote }) {
    return (
        <div className="DealQuotesTab__StatusCell">
            <QuoteStatusBadge
                status={model.status}
                size="small"
            />
            {model.isArchived && <ArchivedBadge size="small" />}
        </div>
    );
}
