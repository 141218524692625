import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './PageHeader.scss';

export default function PageHeader({
    className,
    title,
    titleContext,
    subtitle,
    children,
}: {
    className?: string;
    title: React.ReactNode;
    titleContext?: React.ReactNode;
    subtitle?: React.ReactNode;
    children?: React.ReactFragment;
}) {
    return (
        <div className={coalesceClassNames('PageHeader', className)}>
            <div className="PageHeader__TitleContainer">
                <h1 className="PageHeader__TitleContainer__TitleWrapper">
                    <div className="PageHeader__TitleContainer__Title">{title}</div>
                    {titleContext && (
                        // The wrapper contains the separator symbol and sets up margins
                        <div className="PageHeader__TitleContainer__TitleContext">
                            {titleContext}
                        </div>
                    )}
                </h1>
                {subtitle && <div className="PageHeader__TitleContainer__Subtitle">{subtitle}</div>}
            </div>
            {children && <div className="PageHeader__ContentContainer">{children}</div>}
        </div>
    );
}
