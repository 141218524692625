import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { useCallback, useEffect, useState } from 'react';
import useDebouncedCallback from './useDebouncedCallback';

/** This hook creates a series of props and callbacks
 * which are used where back-end filtering, paging and sorting is used in a single api call
 * When using with a DataTable you might consider using `useDataTableDynamicQuery` instead which wraps this hook
 */
export default function useDynamicQueryParams<T extends DynamicQueryParams>(
    initialValues: T['criteria'],
    options?: {
        pageSize?: number;
        uppercaseSortBy?: boolean;
        defaultSort?: T['sort'];
    },
) {
    const pagingDefaults: T['paging'] = {
        limit: options?.pageSize ?? 100,
        skip: 0,
    };

    const [criteria, setCriteriaInternal] = useState<T['criteria']>(initialValues);
    const [sort, setSort] = useState<T['sort']>(options?.defaultSort);
    const [paging, setPaging] = useState<T['paging']>(pagingDefaults);

    // Clear paging when criteria changes
    const setCriteria = useCallback(
        (data: T['criteria']) => {
            const json1 = JSON.stringify(data);
            const json2 = JSON.stringify(criteria);
            if (json1 !== json2) {
                setCriteriaInternal(data);
                setPaging({
                    ...paging,
                    skip: 0,
                });
            }
        },
        [criteria, paging, setPaging],
    );

    /** Keep default criteria up to date */
    useEffect(
        () => {
            setCriteria(initialValues);
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [initialValues],
    );

    const buildQueryParams = useCallback((): T => {
        const sortUppercased = sort
            ? {
                  propertyKey: sort.propertyKey.toUpperCase(),
                  direction: sort.direction,
              }
            : undefined;

        const _queryParams: T = {
            criteria,
            sort: options?.uppercaseSortBy ? sortUppercased : sort,
            paging,
        } as T;

        return _queryParams;
    }, [criteria, options?.uppercaseSortBy, paging, sort]);

    const [queryParams, setQueryParams] = useState<T>(buildQueryParams());

    /** Debounce the setQueryParams calls to prevent the table from querying multiple times
     * especially when on a page num > 1 and criteria changes */
    const setQueryParamsDebounced = useDebouncedCallback(
        300,
        (data: T) => {
            setQueryParams(data);
        },
        [],
    );

    useEffect(
        () => {
            const _queryParams = buildQueryParams();
            setQueryParamsDebounced(_queryParams);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [criteria, sort, paging],
    );

    return [queryParams, setCriteria, setSort, paging, setPaging] as const;
}
