import z from 'zod';
import { CustomerQuotePaymentSchema } from './CustomerQuotePayment';

export const CustomerQuotePaymentSummarySchema = z.object({
    id: z.string(),
    totalReceived: z.number(),
    balance: z.number(),
    payments: z.array(CustomerQuotePaymentSchema),
});

export type CustomerQuotePaymentSummary = z.infer<typeof CustomerQuotePaymentSummarySchema>;
