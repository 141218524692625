import z from 'zod';

export const ProductDetailSchema = z.object({
    product_id: z.object({
        id: z.number(),
        brand_id: z.number(),
    }),
    price_grids: z.array(
        z.object({
            id: z.number(),
            width: z.number(),
            height: z.number(),
            price: z.number().nullable(),
        }),
    ),
    product_brand: z.object({
        id: z.number(),
        name: z.string(),
        is_sixteen_disabled: z.number(), // 0 or 1
        is_eight_disabled: z.number(), // 0 or 1
    }),
});

export type ProductDetail = z.infer<typeof ProductDetailSchema>;
