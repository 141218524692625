import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import QuoteStatus from '../enums/QuoteStatus';

export const QuoteSchema = z
    .object({
        id: z.string(),
        externalId: z
            .union([z.string(), z.number()])
            .transform(value => (typeof value === 'string' ? parseInt(value, 10) : value)),
        tuid: z.string(),
        createdAt: dateTimeInstant(),
        description: z.string(),
        status: z.nativeEnum(QuoteStatus),
        totalSellPrice: z.number(),
        totalSellPriceOverride: z.number().nullable(),
        isArchived: z.boolean(),
        context: z.object({
            totalQuantity: z.number(),
            orderLineCount: z.number(),
        }),
    })
    .transform(data => ({
        ...data,
        isQuote: true as const,
        isPurchase: false as const,
    }));

export type Quote = z.infer<typeof QuoteSchema>;
