import OrderLineError from 'features/orders/enums/OrderLineError';
import { OrderLineProductSchema } from 'features/orders/models/OrderLineProduct';
import { z } from 'zod';
import { ProductConfigurationSchema } from '../../orders/models/ProductConfiguration';

export const PurchaseOrderLineSchema = z.object({
    id: z.number(),
    windowGroupName: z.string().nullable(),
    orderWindowId: z.number(),
    quantity: z.number(),
    costPrice: z.number(),
    notes: z.string().nullable().optional(),
    context: z.object({
        configuration: ProductConfigurationSchema,
        errors: z.array(z.nativeEnum(OrderLineError)).default([]),
        product: OrderLineProductSchema,
    }),
});

export type PurchaseOrderLine = z.infer<typeof PurchaseOrderLineSchema>;
