import React, { PropsWithChildren, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { PostHogFeature, PostHogProvider } from 'posthog-js/react';
import posthog, { JsonType, PostHogConfig } from 'posthog-js';
import { PostHogFeatureProps } from 'posthog-js/react/dist/types/components/PostHogFeature';

type PostHogProviderProps = {
    enabled: boolean;
    apiKey: string;
    options: Partial<PostHogConfig>;
};
const PostHogProviderWrapper = (props: PropsWithChildren<PostHogProviderProps>) => {
    const { enabled, apiKey, options, children } = props;
    if (!enabled) return <>{children}</>;
    return (
        <PostHogProvider
            apiKey={apiKey}
            options={options}
        >
            {children}
        </PostHogProvider>
    );
};

function useFeatureFlagEnabled(flag: string): undefined | boolean {
    const [flagEnabled, setFlagEnabled] = useState<undefined | boolean>(undefined);
    useEffect(() => {
        if (window.POSTHOG_ENABLED) {
            posthog.onFeatureFlags(() => {
                setFlagEnabled(posthog.isFeatureEnabled(flag));
            });
        } else {
            // if post hog not enabled, assume flag enabled
            setFlagEnabled(true);
        }
    }, [flag]);

    return flagEnabled;
}

function useFeatureFlagVariantKey(flag: string): undefined | string {
    const [flagVariantKey, setFlagVariantKey] = useState<undefined | string>(undefined);
    useEffect(() => {
        if (window.POSTHOG_ENABLED) {
            posthog.onFeatureFlags(() => {
                const flagVariantKeyValue = posthog.getFeatureFlag(flag);
                if (typeof flagVariantKeyValue === 'string') {
                    setFlagVariantKey(flagVariantKeyValue);
                } else {
                    setFlagVariantKey('');
                }
            });
        } else {
            setFlagVariantKey('');
        }
    }, [flag]);
    return flagVariantKey;
}

function useFeatureFlagPayload(flag: string): undefined | JsonType {
    const [flagPayload, setFlagPayload] = useState<undefined | JsonType>(undefined);
    useEffect(() => {
        if (window.POSTHOG_ENABLED) {
            posthog.onFeatureFlags(() => {
                setFlagPayload(posthog.getFeatureFlagPayload(flag));
            });
        } else {
            setFlagPayload('');
        }
    }, [flag]);
    return flagPayload;
}

function useActiveFeatureFlags(): undefined | string[] {
    const [activeFlags, setActiveFlags] = useState<undefined | string[]>(undefined);
    useEffect(() => {
        if (window.POSTHOG_ENABLED) {
            posthog.onFeatureFlags(flags => {
                setActiveFlags(flags);
            });
        } else {
            setActiveFlags([]);
        }
    }, []);
    return activeFlags;
}

const PostHogFeatureWrapper = (props: PostHogFeatureProps) => {
    const { flag, match, children } = props;
    if (!window.POSTHOG_ENABLED) {
        if (typeof children === 'function') {
            return <>{children({})}</>;
        }
        return <>{children}</>;
    }
    return (
        <PostHogFeature
            flag={flag}
            match={match}
        >
            {children}
        </PostHogFeature>
    );
};

export {
    PostHogProviderWrapper as PostHogProvider,
    PostHogFeatureWrapper as PostHogFeature,
    useFeatureFlagEnabled,
    useFeatureFlagVariantKey,
    useFeatureFlagPayload,
    useActiveFeatureFlags,
};
