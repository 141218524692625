import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import { DataTableFilterConfigBase, FilterCompProps } from '../DataTableFilterTypes';

export type TextFilterConfig<T> = DataTableFilterConfigBase & {
    type: 'text';
    getField: (item: T) => string | null | undefined;
    exact?: boolean;
};

export default function TextFilter<T>({ filter, onChange }: FilterCompProps<TextFilterConfig<T>>) {
    const [value, setValue] = useState(filter.config.defaultValue || '');

    const handleInput = (val: string) => {
        setValue(val);
        filter.value = val;
        onChange?.(filter);
    };

    useEffect(() => {
        setValue(filter.value);
    }, [filter.value]);

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--TextFilter',
                filter.config.className,
            )}
        >
            <MyTextInput
                label={filter.config.label}
                value={value}
                handleInput={handleInput}
                showClearButton={true}
            />
        </div>
    );
}

/** Default applyFilter function for text filters */
export function applyTextFilter<T>(config: TextFilterConfig<T>, value: string, item: T) {
    const val = config.getField(item);
    if (isEmpty(val)) {
        return false;
    }
    return config.exact
        ? String(val).toLowerCase() === value.toLowerCase()
        : String(val).toLowerCase().indexOf(value.toLowerCase()) > -1;
}
