import DocumentFriendlyType from 'features/documents/enums/DocumentFriendlyType';
import React from 'react';
import {
    AiOutlineFile,
    AiOutlineFileExcel,
    AiOutlineFileImage,
    AiOutlineFilePdf,
    AiOutlineFileText,
    AiOutlineFileWord,
} from 'react-icons/ai';
import coalesceClassNames from 'utils/coalesceClassNames';
import './DocumentFileIcon.scss';

export default function DocumentFileIcon({
    type,
    className,
}: {
    type?: DocumentFriendlyType;
    className?: string;
}) {
    return (
        <div
            className={coalesceClassNames(
                'DocumentFileIcon',
                `DocumentFileIcon--${type}`,
                className,
            )}
        >
            {type === DocumentFriendlyType.Word ? (
                <AiOutlineFileWord />
            ) : type === DocumentFriendlyType.Excel ? (
                <AiOutlineFileExcel />
            ) : type === DocumentFriendlyType.Pdf ? (
                <AiOutlineFilePdf />
            ) : type === DocumentFriendlyType.Image ? (
                <AiOutlineFileImage />
            ) : type === DocumentFriendlyType.Text ? (
                <AiOutlineFileText />
            ) : (
                <AiOutlineFile />
            )}
        </div>
    );
}
