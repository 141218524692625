import { Modal } from '@mui/material';
import useScrollLock from 'hooks/useScrollLock';
import React, { useRef } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';

export type DialogProps = {
    className?: string;
    children?: React.ReactNode;
    title?: React.ReactNode;
    message?: React.ReactNode;
    buttons?: React.ReactNode;
    containerSelect?: string;
    allowSubtrateClose?: boolean;
    close?: () => void;
};

export default function Dialog({
    className,
    children,
    title,
    message,
    buttons,
    containerSelect = '#modal-root-top',
    allowSubtrateClose = true,
    close,
}: DialogProps) {
    // prevent body scroll while dialog is open
    useScrollLock();

    const renderContent = () => {
        if (!isEmpty(children)) {
            return children;
        }

        return (
            <div className={coalesceClassNames('DialogManager__dialog__main', className)}>
                <div className="DialogManager__dialog__header">
                    {title && <div className="DialogManager__dialog__title">{title}</div>}
                </div>
                {message && <div className="DialogManager__dialog__message">{message}</div>}
                {buttons && <div className="DialogManager__dialog__buttons">{buttons}</div>}
            </div>
        );
    };

    const modalRoot = useRef(document.querySelector(containerSelect));

    const handleClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (!allowSubtrateClose && reason === 'backdropClick') {
            return;
        }
        close?.();
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            closeAfterTransition
            container={modalRoot.current}
        >
            <div className={coalesceClassNames('DialogManager__dialog', className)}>
                {renderContent()}
            </div>
        </Modal>
    );
}
