import MyEditModal from 'components/MyEditModal/MyEditModal';

import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import BundleOperator from 'features/settings/enums/BundleOperator';
import { Bundle, BundleTemplateField } from 'features/settings/models/Bundle';
import settingsApi from 'features/settings/settings.api';
import React, { useMemo } from 'react';
import './BundleTemplateFieldsEditModal.scss';

export default function BundleTemplateFieldsEditModal({
    bundle,
    close,
}: {
    bundle: Bundle;
    close?: () => void;
}) {
    const [subscribeMutation, subscribeMutationState] = settingsApi.useBundleSubscribeMutation();

    const save = async (editModel: Bundle) => {
        await subscribeMutation({ bundle: editModel, optimistic: false }).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="BundleTemplateFieldsEditModal"
            model={bundle}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={subscribeMutationState.isLoading}
            title={`Bundle Settings`}
            titleContext={bundle.name}
            subtitle="Please enter all fields below to subscribe to this bundle."
            saveButtonLabel="Subscribe"
        >
            {({ editModel, updateField, isSaving }) => (
                <PropertyContainer
                    layout="table"
                    fullWidthInputs={true}
                >
                    {editModel.templateFields?.map((field, index) => (
                        <TemplateField
                            key={`${index} ${field.templateKey}`}
                            field={field}
                            editModel={editModel}
                            updateField={updateField}
                            isSaving={isSaving}
                        />
                    ))}
                </PropertyContainer>
            )}
        </MyEditModal>
    );
}

function TemplateField({
    field,
    editModel,
    updateField,
    isSaving,
}: {
    field: BundleTemplateField;
    editModel: Bundle;
    updateField: (val: Partial<Bundle>) => void;
    isSaving: boolean;
}) {
    const validationCustom = useMemo(() => {
        if (field.operator === BundleOperator.Factor) {
            if (field.value === null) {
                return 'This field is required';
            }
            if (field.value > 1) {
                return 'This field must be between 0 and 1';
            }
        }
        if (field.operator === BundleOperator.Currency) {
            if (field.value === null) {
                return 'This field is required';
            }
            if (field.value < 0) {
                return 'This field must be greater than 0';
            }
        }

        return undefined;
    }, [field.operator, field.value]);

    return (
        <PropertyEditNumber
            className="BundleTemplateFieldsEditModal__TemplateField__Field"
            label={field.label}
            value={field.value ?? undefined}
            onChange={val => {
                const templateFields = editModel.templateFields?.map(f => {
                    if (f.templateKey === field.templateKey) {
                        return { ...f, value: val ?? null };
                    }
                    return f;
                });
                updateField({ templateFields });
            }}
            min={0}
            max={field.operator === BundleOperator.Factor ? 1 : undefined}
            disabled={isSaving}
            validationRequired={true}
            validationCustom={validationCustom}
            units={field.operator === BundleOperator.Currency ? 'money' : undefined}
            allowDecimals={true}
        />
    );
}
