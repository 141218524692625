import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import { PurchaseOrderEditable } from 'features/purchases/models/PurchaseOrderEditable';
import { selectIsMultiSupplier, selectManufacturers } from 'features/settings/settings.slice';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import './PurchaseOrderEditFields.scss';

export default function PurchaseOrderEditFields({
    editModel,
    isSaving,
    updateField,
    canEditManufacturer,
}: {
    editModel: PurchaseOrderEditable;
    isSaving: boolean;
    updateField: (data: Partial<PurchaseOrderEditable>) => void;
    canEditManufacturer?: boolean;
}) {
    const salesRepOptions = useSalesRepOptions();
    const manufacturers = useAppSelector(selectManufacturers);
    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);
    const manufacturerOptions = manufacturers?.map(manufacturer => ({
        label: manufacturer.name,
        value: `${manufacturer.id}`,
    }));

    return (
        <div className="PurchaseOrderEditFields">
            <PropertyContainer>
                {isMultiSupplier && (
                    <PropertyEditSelect
                        label="Supplier"
                        value={`${editModel.manufacturer_id}`}
                        options={manufacturerOptions}
                        onChange={manufacturerId =>
                            updateField({
                                manufacturer_id: parseInt(manufacturerId, 10),
                            })
                        }
                        fullWidth
                        disabled={isSaving || !canEditManufacturer}
                    />
                )}
                <PropertyEditAutocomplete
                    label="Ordered By"
                    value={editModel.sales_rep_override ?? ''}
                    options={salesRepOptions}
                    onChange={sales_rep_override => updateField({ sales_rep_override })}
                    disabled={isSaving}
                    freeSolo={true}
                />

                <PropertyEditText
                    label="Sidemark"
                    value={editModel.sidemark ?? ''}
                    onChange={sidemark => updateField({ sidemark })}
                    disabled={isSaving}
                />

                <PropertyEditText
                    label="Notes"
                    value={editModel.details ?? ''}
                    onChange={details => updateField({ details })}
                    multiline={true}
                    disabled={isSaving}
                />
            </PropertyContainer>
        </div>
    );
}
