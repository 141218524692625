import { mergeDeep } from 'utils/helpers';

export type QuoteEditable = {
    id: string;
    description: string;
};

export class QuoteEditableFactory {
    static create(data?: Partial<QuoteEditable>): QuoteEditable {
        const defaults: QuoteEditable = {
            id: '',
            description: '',
        };

        const mergedData = mergeDeep(defaults, data);

        return mergedData;
    }
}
