import { fixDateStringToIso } from 'utils/dateHelpers';
import z from 'zod';
import DocumentFriendlyType from '../enums/DocumentFriendlyType';

export const DocumentFileSchema = z.object({
    id: z.number(),
    brand_id: z.number().nullable(),
    filename: z.string(),
    extension: z.string(),
    mime_type: z.string(),
    size: z.string(),
    type: z.nativeEnum(DocumentFriendlyType),
    name: z.string(),
    document_category_id: z.number().nullable(),
    created_by: z.number().nullable(),
    updated_by: z.number().nullable(),
    created_at: z.string().transform(val => fixDateStringToIso(val, 'yyyy-MM-dd HH:mm:ss')),
    updated_at: z.string().transform(val => fixDateStringToIso(val, 'yyyy-MM-dd HH:mm:ss')),
    direct_upload: z.number(),
    employee_download_count: z.number(),
    client_download_count: z.number(),
    link: z.string().nullable(),
    document_type: z.string(),
    url: z.string(),
    // manufacturer_id and manufacturer_name are optional because they are only present in manufacturer documents
    manufacturer_id: z.number().optional(),
    manufacturer_name: z.string().optional(),
});

export type DocumentFile = z.infer<typeof DocumentFileSchema>;
