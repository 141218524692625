import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { QuoteEditable, QuoteEditableFactory } from 'features/quotes/models/QuoteEditable';
import quotesApi from 'features/quotes/quotes.api';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import QuoteEditFields from '../QuoteEditFields/QuoteEditFields';
import './QuoteCloneModal.scss';

export default function QuoteCloneModal({
    order,
    close,
}: {
    order: QuoteDetail;
    close?: () => void;
}) {
    const model = useMemo(
        () =>
            QuoteEditableFactory.create({
                id: order.id,
                description: order.description,
            }),
        [order],
    );

    const [cloneMutation, cloneMutationState] = quotesApi.useQuoteCloneMutation();

    const navigate = useNavigate();
    const save = useCallback(
        async (editModel: QuoteEditable) => {
            const id = await cloneMutation({
                id: order.id,
                description: editModel.description,
            }).unwrap();
            navigate(`/quotes/${id}`);
            close?.();
        },
        [cloneMutation, order.id, navigate, close],
    );

    return (
        <MyEditModal
            className="QuoteCloneModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={cloneMutationState.isLoading}
            title="Clone quote"
            subtitle="Copy all products to a new quote."
            mobileTitle="Quote"
            saveButtonLabel="Create clone"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <MessagePanel
                        className="QuoteCloneModal__MessagePanel"
                        messageType="info"
                    >
                        <p>All fields are optional when cloning a quote.</p>
                    </MessagePanel>

                    <QuoteEditFields
                        editModel={editModel}
                        isSaving={isSaving}
                        updateField={updateField}
                    />
                </>
            )}
        </MyEditModal>
    );
}
