import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum PurchaseOrderStatus {
    Open = 'OPEN',
    Submitted = 'SUBMITTED',
}

export default PurchaseOrderStatus;

const labels: EnumDisplayLabels<typeof PurchaseOrderStatus> = {
    Open: 'Open',
    Submitted: 'Submitted',
};

export const PurchaseOrderStatusDisplay = createDisplayEnum(PurchaseOrderStatus, labels);
