import { delay, http, HttpResponse } from 'msw';

import Env from 'config/Env';

const BASE_URL = `${Env.API_BASE_URL}`;

export const settingsHandlers = [
    // Get all shipment summaries
    http.get(`${BASE_URL}/old/account-settings/dealer-portal`, async () => {
        await delay(500);
        return HttpResponse.json({
            companyLogoDocumentId: 1,
            companyLogoDocumentUrl:
                'https://smartpadpro-uat-au.s3-ap-southeast-2.amazonaws.com/dealers/files/documents/466/YToyy73inCdZXUkNKAV9BdGpt3a2rP9LmO4DOvvo.png',
            companyDeliveryAddress: '123 Fake st',
            bindingText: 'binding text',
            depositPercentage: 10,
            taxRatePercentage: 10,
            accountAdjustmentType: 'PERCENT_MARK_UP',
            accountAdjustmentValue: 10,
        });
    }),

    http.get(`${BASE_URL}/bundles`, async () => {
        await delay(500);
        return HttpResponse.json([
            {
                id: '1',
                name: 'Bundle name',
                description:
                    'This is the description of the bundle it might be quite long or break onto multiple lines we dont really mind too much as the space around it can just grow to whatever is needed',
                exclusivityKey: 'Some Exclusivity Group', // this will be displayed in the UI
                sortOrder: 10,
                isSubscribed: false,
                templateFields: [],
            },
            {
                id: '2',
                name: 'Another bundle',
                description:
                    'This is the description of the bundle it might be quite long or break onto multiple lines we dont really mind too much as the space around it can just grow to whatever is needed',
                exclusivityKey: 'Some Exclusivity Group', // this will be displayed in the UI
                sortOrder: 10,
                isSubscribed: true,
                templateFields: [],
            },
            {
                id: '3',
                name: 'Simple bundle no description',
                exclusivityKey: 'Some Exclusivity Group', // this will be displayed in the UI
                sortOrder: 10,
                isSubscribed: false,
                templateFields: [],
            },
            {
                id: '4',
                name: 'Simple bundle no description',
                description:
                    'This is the description of the bundle it might be quite long or break onto multiple lines we dont really mind too much as the space around it can just grow to whatever is needed',
                exclusivityKey: 'An exclusivity group with only one bundle', // this will be displayed in the UI
                sortOrder: 10,
                isSubscribed: false,
                templateFields: [],
            },
            {
                id: '5',
                name: 'Simple bundle no description',
                description:
                    'This is the description of the bundle it might be quite long or break onto multiple lines we dont really mind too much as the space around it can just grow to whatever is needed',
                exclusivityKey: null,
                sortOrder: 10,
                isSubscribed: true,
                templateFields: [],
            },
            {
                id: '6',
                name: 'Simple bundle no description',
                description:
                    'This is the description of the bundle it might be quite long or break onto multiple lines we dont really mind too much as the space around it can just grow to whatever is needed',
                exclusivityKey: null,
                sortOrder: 10,
                isSubscribed: true,
                templateFields: [],
            },
            {
                id: '7',
                name: 'Simple bundle no description',
                description:
                    'This is the description of the bundle it might be quite long or break onto multiple lines we dont really mind too much as the space around it can just grow to whatever is needed',
                exclusivityKey: null,
                sortOrder: 10,
                isSubscribed: true,
                templateFields: [],
            },
            {
                id: '8',
                name: 'Simple bundle no description',
                description:
                    'This is the description of the bundle it might be quite long or break onto multiple lines we dont really mind too much as the space around it can just grow to whatever is needed',
                exclusivityKey: null,
                sortOrder: 10,
                isSubscribed: true,
                templateFields: [],
            },
            {
                id: '9',
                name: 'Cost Factors',
                description:
                    'Here is a description of a more complex bundle that requires the user to enter some fields. This one is not subscribed yet so the fields are hidden',
                exclusivityKey: null,
                sortOrder: 10,
                isSubscribed: false,
                templateFields: [
                    {
                        templateKey: 'COST_FACTOR_ROLLERS',
                        label: 'Roller blinds',
                        operator: 'FACTOR', // 0.0 - 1.0
                        value: 0.3, // if subscribed, the current value
                    },
                    {
                        templateKey: 'COST_DISCOUNT_WIDGETS',
                        label: 'Widgets',
                        operator: 'CURRENCY', // dollar amount >= 0
                        value: 10, // if subscribed, the current value
                    },
                ],
            },
        ]);
    }),

    http.post(`${BASE_URL}/bundles/:id/subscribe`, async () => {
        await delay(1000);
        return new HttpResponse(null, { status: 200 });
    }),

    http.post(`${BASE_URL}/bundles/:id/unsubscribe`, async () => {
        await delay(500);
        return new HttpResponse(null, { status: 200 });
    }),
];
