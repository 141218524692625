import React, { useCallback } from 'react';

// import MySelectInput from 'components/MySelectInput/MySelectInput';
import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import { DynamicQueryPaging } from 'models/DynamicQueryParams';
import { BiSkipNext, BiSkipPrevious } from 'react-icons/bi';
import './DataTablePaging.scss';

// const pageSizeOptions = ['10', '50', '100', '200'].map(x => ({ label: x, value: x }));

export default function DataTablePaging({
    data,
    totalCount = 0,
    onChange,
}: {
    data: DynamicQueryPaging;
    totalCount?: number;
    onChange: (val: DynamicQueryPaging) => void;
}) {
    const maxPageNumber = Math.max(1, Math.ceil(totalCount / data.limit));
    const currentPageNo = Math.max(1, Math.ceil(data.skip / data.limit) + 1);

    const goToPage = useCallback(
        (pageNo: number) => {
            const skip = (pageNo - 1) * data.limit;
            onChange({
                ...data,
                skip,
            });
        },
        [data, onChange],
    );

    const goPrevPage = useCallback(() => {
        const nextPageNo = Math.max(1, currentPageNo - 1);
        goToPage(nextPageNo);
    }, [currentPageNo, goToPage]);

    const goNextPage = useCallback(() => {
        const nextPageNo = Math.min(maxPageNumber, currentPageNo + 1);
        goToPage(nextPageNo);
    }, [currentPageNo, goToPage, maxPageNumber]);

    const canGoPrev = currentPageNo > 1;
    const canGoNext = currentPageNo < maxPageNumber;

    return (
        <div className="DataTablePaging">
            <div className="DataTablePaging__Total">
                <strong>{totalCount}</strong> {totalCount === 1 ? 'record' : 'records'} found
            </div>
            <div className="DataTablePaging__Page">
                {canGoPrev && (
                    <>
                        <MyButton
                            className="DataTablePaging__Page__PageButton"
                            IconRight={BiSkipPrevious}
                            buttonType="Nude"
                            size="small"
                            onClick={() => goToPage(1)}
                        />
                        <MyButton
                            className="DataTablePaging__Page__PageButton"
                            IconRight={Icons.ChevronLeft}
                            buttonType="Nude"
                            size="small"
                            onClick={goPrevPage}
                        />
                    </>
                )}

                <div className="DataTablePaging__Page__PageNo">
                    <strong>Page {currentPageNo}</strong> of {maxPageNumber}
                </div>

                {canGoNext && (
                    <>
                        <MyButton
                            className="DataTablePaging__Page__PageButton"
                            IconRight={Icons.ChevronRight}
                            buttonType="Nude"
                            size="small"
                            onClick={goNextPage}
                        />
                        <MyButton
                            className="DataTablePaging__Page__PageButton"
                            IconRight={BiSkipNext}
                            buttonType="Nude"
                            size="small"
                            onClick={() => goToPage(maxPageNumber)}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
