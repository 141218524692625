import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

const getInitialState = () => {
    return {
        lastUsedSalesRep: localStorage.getItem('lastUsedSalesRep') ?? '',
    };
};

const slice = createSlice({
    name: 'orders',
    initialState: getInitialState(),
    reducers: {
        setLastUsedSalesRep(state, action: PayloadAction<string>) {
            localStorage.setItem('lastUsedSalesRep', action.payload);
            state.lastUsedSalesRep = action.payload;
        },
    },
    // extraReducers: builder => {},
});

export const { setLastUsedSalesRep } = slice.actions;

export default slice.reducer;

export const selectLastUsedSalesRep = (state: RootState) => state.orders.lastUsedSalesRep;
