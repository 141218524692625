import MeasureSystem from 'features/settings/enums/MeasureSystem';
import { isEmpty } from 'utils/helpers';

let measureSystem = MeasureSystem.Metric;
export function setMeasureSystem(sys: MeasureSystem) {
    measureSystem = sys;
}

const fractionLookup: Record<number, string> = {
    0.0625: '1/16',
    0.125: '1/8',
    0.1875: '3/16',
    0.25: '1/4',
    0.3125: '5/16',
    0.375: '3/8',
    0.4375: '7/16',
    0.5: '1/2',
    0.5625: '9/16',
    0.625: '5/8',
    0.6875: '11/16',
    0.75: '3/4',
    0.8125: '13/16',
    0.875: '7/8',
    0.9375: '15/16',
};

/** Takes in a decimal/float number and returns a string formatted for the current measure system
 * If the measure system is imperial, it will return inches with a fraction
 */
export function formatMeasurement(floatValue?: number) {
    if (measureSystem === MeasureSystem.Imperial) {
        if (floatValue === 0) {
            return '0';
        }
        if (floatValue) {
            const inches = Math.floor(floatValue);
            const decimal = floatValue % 1;
            const fraction = fractionLookup[decimal];

            return fraction ? `${inches} ${fraction}` : `${inches}`;
        }
    }

    // Metric
    return `${floatValue ?? ''}`;
}

export function fractionToDecimal(fraction: string) {
    if (isEmpty(fraction)) {
        return 0;
    }
    const [numerator, denominator] = fraction.split('/').map(Number);
    return numerator / denominator;
}
