import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditAddress from 'components/PropertyEditAddress/PropertyEditAddress';
import PropertyEditHtml from 'components/PropertyEditHtml/PropertyEditHtml';
import PropertyEditMultiEmail from 'components/PropertyEditMultiEmail/PropertyEditMultiEmail';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import DocumentUploadModal from 'features/documents/components/DocumentUploadModal/DocumentUploadModal';
import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import { DealerAccountAdjustmentTypeDisplay } from 'features/settings/enums/DealerAccountAdjustmentType';
import { DealerSettings } from 'features/settings/models/DealerSettings';
import settingsApi from 'features/settings/settings.api';
import {
    selectCurrentCompany,
    selectCurrentCountry,
    selectDealerModules,
} from 'features/settings/settings.slice';
import React, { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import EditSection from '../EditSection/EditSection';
import './DealerSettingsEditable.scss';

export default function DealerSettingsEditable({
    model,
    isLoading,
    isError,
}: {
    model?: DealerSettings;
    isLoading: boolean;
    isError: boolean;
}) {
    const country = useAppSelector(selectCurrentCountry);
    const dealerModules = useAppSelector(selectDealerModules);
    const company = useAppSelector(selectCurrentCompany);
    const [showImageUploadModal, setShowImageUploadModal] = useState(false);

    const [updateDealerSettingsMutation, updateDealerSettingsMutationState] =
        settingsApi.useDealerSettingsUpdateMutation();

    const [updateMainCompanyMutation, updateMainCompanyMutationState] =
        settingsApi.useMainCompanyUpdateMutation();

    return (
        <div className="DealerSettingsEditable">
            {isLoading ? (
                <div className="DealerSettingsEditable__Loading">
                    <MyLinearProgress />
                </div>
            ) : isError ? (
                <ErrorContent className="DealerSettingsEditable__Error" />
            ) : (
                <>
                    {model && (
                        <>
                            <EditSection
                                title="Company Settings"
                                model={company}
                                onSave={updateMainCompanyMutation}
                                isSaving={updateMainCompanyMutationState.isLoading}
                            >
                                {({ editModel, updateField, isEditing, isSaving }) => (
                                    <PropertyContainer>
                                        <PropertyEditText
                                            label="Company name"
                                            value={editModel.name}
                                            onChange={name => updateField?.({ name })}
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                        />

                                        <PropertyEditMultiEmail
                                            label="Email"
                                            hint="Primary contact email only, not used for automatic notifications"
                                            value={editModel.email ?? ''}
                                            onChange={email => updateField?.({ email })}
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                        />

                                        <PropertyEditText
                                            label="Phone"
                                            value={editModel.phone}
                                            onChange={phone => updateField?.({ phone })}
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                        />

                                        <PropertyEditAddress
                                            label="Address"
                                            value={editModel.address ?? ''}
                                            onChange={address => updateField?.({ address })}
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                        />
                                    </PropertyContainer>
                                )}
                            </EditSection>

                            <EditSection
                                title="Notification Settings"
                                model={company}
                                onSave={updateMainCompanyMutation}
                                isSaving={updateMainCompanyMutationState.isLoading}
                            >
                                {({ editModel, updateField, isEditing, isSaving }) => (
                                    <PropertyContainer>
                                        <PropertyEditMultiEmail
                                            label="Account notifications"
                                            hint="Invoices, statements and other account-related emails are sent here"
                                            value={editModel.account_email ?? ''}
                                            onChange={account_email =>
                                                updateField?.({ account_email })
                                            }
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                        />

                                        <PropertyEditMultiEmail
                                            label="Order notifications"
                                            hint="Order confirmations, status/ETA updates and shipping notifications are sent here"
                                            value={editModel.order_confirmation_email ?? ''}
                                            onChange={order_confirmation_email =>
                                                updateField?.({ order_confirmation_email })
                                            }
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                        />
                                    </PropertyContainer>
                                )}
                            </EditSection>

                            <EditSection
                                title="Order Settings"
                                model={model}
                                onSave={updateDealerSettingsMutation}
                                isSaving={updateDealerSettingsMutationState.isLoading}
                            >
                                {({ editModel, updateField, isEditing, isSaving }) => (
                                    <PropertyContainer>
                                        <PropertyEditAddress
                                            label="Default shipping address"
                                            value={editModel.companyDeliveryAddress ?? ''}
                                            onChange={companyDeliveryAddress =>
                                                updateField?.({ companyDeliveryAddress })
                                            }
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                            validateWithTaxJar
                                        />
                                        <PropertyEditText
                                            label="Default shipping instructions"
                                            value={editModel.defaultShippingInstructions ?? ''}
                                            onChange={defaultShippingInstructions =>
                                                updateField?.({ defaultShippingInstructions })
                                            }
                                            disabled={isSaving}
                                            readonly={!isEditing}
                                        />
                                    </PropertyContainer>
                                )}
                            </EditSection>

                            {dealerModules?.sales && (
                                <>
                                    <EditSection
                                        title="Sales Settings"
                                        model={model}
                                        onSave={updateDealerSettingsMutation}
                                        isSaving={updateDealerSettingsMutationState.isLoading}
                                    >
                                        {({ editModel, updateField, isEditing, isSaving }) => (
                                            <>
                                                <PropertyContainer layout="row">
                                                    <PropertyEditSelect
                                                        label="Price adjustment type"
                                                        value={editModel.accountAdjustmentType}
                                                        options={
                                                            DealerAccountAdjustmentTypeDisplay.options
                                                        }
                                                        onChange={accountAdjustmentType =>
                                                            updateField?.({ accountAdjustmentType })
                                                        }
                                                        disabled={isSaving}
                                                        readonly={!isEditing}
                                                    />

                                                    <PropertyEditNumber
                                                        label="Price adjustment amount"
                                                        value={editModel.accountAdjustmentValue}
                                                        onChange={accountAdjustmentValue =>
                                                            updateField?.({
                                                                accountAdjustmentValue,
                                                            })
                                                        }
                                                        units="percent"
                                                        disabled={isSaving}
                                                        readonly={!isEditing}
                                                    />
                                                </PropertyContainer>
                                                <PropertyContainer>
                                                    <PropertyEditNumber
                                                        label="Deposit percentage"
                                                        value={
                                                            editModel.depositPercentage ?? undefined
                                                        }
                                                        onChange={depositPercentage =>
                                                            updateField?.({
                                                                depositPercentage:
                                                                    depositPercentage ?? null,
                                                            })
                                                        }
                                                        units="percent"
                                                        disabled={isSaving}
                                                        readonly={!isEditing}
                                                    />

                                                    {/* Only show tax percentage input if labor_tax_control is on for the current country */}
                                                    {country?.labor_tax_control === 1 && (
                                                        <PropertyEditNumber
                                                            label="Tax rate percentage"
                                                            value={
                                                                editModel.taxRatePercentage ??
                                                                undefined
                                                            }
                                                            onChange={taxRatePercentage =>
                                                                updateField?.({
                                                                    taxRatePercentage:
                                                                        taxRatePercentage ?? null,
                                                                })
                                                            }
                                                            units="percent"
                                                            disabled={isSaving}
                                                            readonly={!isEditing}
                                                        />
                                                    )}
                                                </PropertyContainer>
                                            </>
                                        )}
                                    </EditSection>

                                    <EditSection
                                        title="Quote PDF Settings"
                                        model={model}
                                        onSave={updateDealerSettingsMutation}
                                        isSaving={updateDealerSettingsMutationState.isLoading}
                                    >
                                        {({ editModel, updateField, isEditing, isSaving }) => (
                                            <PropertyContainer>
                                                <PropertyEditText
                                                    label="Company details"
                                                    value={editModel.companyDetails ?? ''}
                                                    onChange={companyDetails =>
                                                        updateField?.({ companyDetails })
                                                    }
                                                    disabled={isSaving}
                                                    readonly={!isEditing}
                                                    multiline={true}
                                                />

                                                <PropertyDisplay
                                                    label="Company logo"
                                                    value={
                                                        <div className="DealerSettingsEditable__CompanyLogo">
                                                            <MyButton
                                                                className={coalesceClassNames(
                                                                    'DealerSettingsEditable__CompanyLogo__ImageButton',
                                                                    isEditing && 'editing',
                                                                )}
                                                                buttonType="None"
                                                                disabled={!isEditing}
                                                                onClick={() =>
                                                                    setShowImageUploadModal(true)
                                                                }
                                                            >
                                                                {editModel.companyLogoDocumentUrl ? (
                                                                    <img
                                                                        src={
                                                                            editModel.companyLogoDocumentUrl
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <div className="DealerSettingsEditable__CompanyLogo__NoImage">
                                                                        Click to upload
                                                                    </div>
                                                                )}
                                                            </MyButton>
                                                            {isEditing &&
                                                                editModel.companyLogoDocumentId && (
                                                                    <MyButton
                                                                        className="DealerSettingsEditable__CompanyLogo__ClearButton"
                                                                        IconRight={Icons.Close}
                                                                        title="Clear image"
                                                                        buttonType="None"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            updateField?.({
                                                                                companyLogoDocumentId:
                                                                                    null,
                                                                                companyLogoDocumentUrl:
                                                                                    null,
                                                                            })
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                    }
                                                />

                                                {showImageUploadModal && (
                                                    <DocumentUploadModal
                                                        directUpload={true}
                                                        afterUpload={async (
                                                            doc: DocumentUploadResultDocument,
                                                        ) =>
                                                            updateField?.({
                                                                companyLogoDocumentId: doc.id,
                                                                companyLogoDocumentUrl: doc.url,
                                                            })
                                                        }
                                                        mobileTitle="Settings"
                                                        imagesOnly={true}
                                                        oneOnly={true}
                                                        close={() => setShowImageUploadModal(false)}
                                                    />
                                                )}

                                                <PropertyEditHtml
                                                    label="Binding text"
                                                    className="DealerSettingsEditable__BindingTextHtml"
                                                    readonly={!isEditing}
                                                    value={editModel.bindingText}
                                                    onChange={bindingText =>
                                                        updateField?.({ bindingText })
                                                    }
                                                />
                                            </PropertyContainer>
                                        )}
                                    </EditSection>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
