import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import purchasesApi from 'features/purchases/purchases.api';
import React from 'react';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import './PurchaseOrderSupplierOrders.scss';

export default function PurchaseOrderSupplierOrders({
    purchaseOrderId,
}: {
    purchaseOrderId: number;
}) {
    const { detailPath } = useOrderPresentation();

    const query = purchasesApi.useManufacturerOrdersQuery(purchaseOrderId);
    const supplierOrders = query.data;

    return (
        <>
            {supplierOrders?.map(order => (
                <div
                    key={order.id}
                    className="PurchaseOrderSupplierOrder"
                >
                    <SectionHeader
                        title="Supplier order"
                        titleContext={order.reference}
                        autoMargin
                    >
                        <ManufacturerOrderStatusBadge
                            customerStatus={order.context.orderStatus.name}
                            coreStatus={order.context.orderStatus.coreStatus}
                        />
                    </SectionHeader>
                    <div className="PurchaseOrderSupplierOrder__Content">
                        <PropertyContainer layout="table">
                            <PropertyDisplay
                                label="ETA"
                                value={order.eta ? formatDateRelative(order.eta) : null}
                            />
                            <PropertyDisplay
                                label="Shipping method"
                                value={order.context.shippingMethod?.name}
                            />
                            <PropertyDisplay
                                label="Last updated"
                                value={formatDateTimeRelative(order.updatedAt ?? order.createdAt)}
                            />
                        </PropertyContainer>

                        <MyButton
                            label="Details"
                            buttonType="Hollow"
                            size="small"
                            href={`${detailPath}/${purchaseOrderId}/supplier-order/${order.id}?`}
                            LinkComponent={MyButtonLink}
                        />
                    </div>
                </div>
            ))}
        </>
    );
}
