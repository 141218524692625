import Portal from '@mui/material/Portal';
import MyButton from 'components/MyButton/MyButton';
import authApi from 'features/auth/auth.api';
import { selectIsAuthenticated, selectNewVersionAvailable } from 'features/auth/auth.slice';
import Icons from 'Icons';
import React, { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import './AppUpdater.scss';

/** Timeout for version checks - default is 5 mins */
const pollingInterval = parseInt(process.env.REACT_APP_CHECK_UPDATE_TIMEOUT ?? '', 10) || 300000;

export default function AppUpdater({ children }: { children: React.ReactNode }) {
    const isUpdateAvailable = useAppSelector(selectNewVersionAvailable);
    const isAuthed = useAppSelector(selectIsAuthenticated);

    //  Poll for new versions
    authApi.useCheckVersionQuery(undefined, {
        pollingInterval,
        skip: !process.env.REACT_APP_GIT_HASH || isUpdateAvailable,
    });

    useEffect(() => {
        // If an update is required but the user is not authenticated, just reload the page immediately
        if (!isAuthed && isUpdateAvailable) {
            window.location.reload();
        }
    }, [isAuthed, isUpdateAvailable]);

    return (
        <>
            {children}
            {isUpdateAvailable && isAuthed && <UpdateDialog />}
        </>
    );
}

function UpdateDialog() {
    const [isDialogMinimized, setDialogMinimized] = React.useState(false);
    const handleClickUpdate = (e: React.UIEvent) => {
        e.preventDefault();
        window.location.reload();
    };

    const toggleMinimize = (e: React.UIEvent) => {
        e.preventDefault();
        setDialogMinimized(!isDialogMinimized);
    };

    return (
        <Portal>
            <div
                className={coalesceClassNames(
                    'AppUpdater__UpdateDialog',
                    isDialogMinimized ? 'layout-minimized' : 'layout-default',
                )}
            >
                {isDialogMinimized ? (
                    <div className="AppUpdater__UpdateDialog__ContentMinimized">
                        <Icons.UpdateAvailable className="icon" />
                        <span>
                            Please{' '}
                            <a
                                className="Link"
                                href="#"
                                onClick={handleClickUpdate}
                            >
                                refresh page
                            </a>{' '}
                            to update
                        </span>
                    </div>
                ) : (
                    <div className="AppUpdater__UpdateDialog__ContentDefault">
                        <div className="AppUpdater__UpdateDialog__ContentDefault__Title">
                            Update Available!
                        </div>
                        <div className="AppUpdater__UpdateDialog__ContentDefault__Message">
                            A new version of this app is now available, please refresh the page to
                            update.
                        </div>
                        <div className="AppUpdater__UpdateDialog__ContentDefault__Actions">
                            <a
                                className="Link"
                                href="#"
                                onClick={toggleMinimize}
                            >
                                Later
                            </a>
                            <MyButton
                                label="Update now"
                                size="small"
                                onClick={handleClickUpdate}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Portal>
    );
}
