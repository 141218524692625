import React, { createContext, useContext, useMemo } from 'react';

type OrderPresentationData = {
    isQuotes: boolean;
    listPath: string;
    detailPath: string;
    topLevelPageTitle: string;
    entityTitle: string;
};

export const OrderPresentationContext = createContext<OrderPresentationData | undefined>(undefined);
export const useOrderPresentation = () => {
    const ctx = useContext(OrderPresentationContext);
    if (!ctx) {
        throw new Error(
            'OrderPresentationContext not found in the tree. Make sure to wrap your component with OrderPresentation.',
        );
    }
    return ctx;
};

/** This provider allows us to make components common to both Sales and Purchases without passing in loads of context parameters */
export default function OrderPresentation({
    isQuotes,
    listPath,
    detailPath,
    children,
}: {
    isQuotes: boolean;
    /** Route path for the order list screen (where to go when closing order detail modal) */
    listPath: string;
    /** Route path for the order detail screen (will append /:id) after this */
    detailPath: string;
    children?: React.ReactFragment;
}) {
    const ctx: OrderPresentationData = useMemo(
        () => ({
            isQuotes,
            listPath,
            detailPath,
            topLevelPageTitle: isQuotes ? 'Quotes' : 'Orders',
            entityTitle: isQuotes ? 'Quote' : 'Order',
        }),
        [detailPath, isQuotes, listPath],
    );
    return (
        <OrderPresentationContext.Provider value={ctx}>
            {children}
        </OrderPresentationContext.Provider>
    );
}
