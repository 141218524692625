import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import documentsApi from 'features/documents/documents.api';
import { getDocumentFriendlyType } from 'features/documents/enums/DocumentFriendlyType';
import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import coalesceClassNames from 'utils/coalesceClassNames';
import DocumentFileIcon from '../DocumentFileIcon/DocumentFileIcon';
import './DocumentUploadModal.scss';

export type DocumentUploadFileArgs = {
    file: File;
    brandId: number | null;
};

const imageTypes = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
};

const docTypes = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'document/pdf': ['.pdf'],
    'document/word': ['.doc', '.docx'],
    'document/excel': ['.xls', '.xlsx'],
    'document/csv': ['.csv'],
    'document/text': ['.txt', '.msg'],
};

export default function DocumentUploadModal({
    directUpload,
    afterUpload,
    mobileTitle,
    imagesOnly,
    oneOnly,
    close,
}: {
    directUpload: boolean;
    afterUpload?: (doc: DocumentUploadResultDocument) => Promise<any | void>;
    mobileTitle?: React.ReactFragment;
    imagesOnly?: boolean;
    oneOnly?: boolean;
    close?: () => void;
}) {
    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadDocumentMutation] = documentsApi.useDocumentUploadMutation();
    const dialogManager = useDialogManager();

    const dz = useDropzone({
        accept: imagesOnly
            ? docTypes
            : {
                  ...imageTypes,
                  ...docTypes,
              },
        maxFiles: oneOnly ? 1 : undefined,
    });

    const handleRemoveFile = useCallback(
        (file: File) => {
            setFiles(files.filter(f => f !== file));
        },
        [files],
    );

    /** Upload an individual file and call afterUpload if defined  */
    const uploadFile = useCallback(
        async (file: File) => {
            const result = await uploadDocumentMutation({
                file,
                // brandId,
                directUpload,
            }).unwrap();

            if (afterUpload) {
                await afterUpload(result.data.document);
            }
        },
        [afterUpload, directUpload, uploadDocumentMutation],
    );

    /** Begin upload of all selected files */
    const handleUpload = useCallback(async () => {
        setIsUploading(true);
        try {
            const promises = files.map(f => uploadFile(f));
            await Promise.all(promises);

            // close after all files uploaded
            close?.();
        } catch (e) {
            setIsUploading(false);
            throw e;
        }
    }, [close, files, uploadFile]);

    const handleClose = useCallback(() => {
        if (isUploading) {
            dialogManager.alert({
                title: 'Upload is still in progress',
                message: 'Please wait for all uploads to finish before closing',
            });
        } else {
            close?.();
        }
    }, [close, dialogManager, isUploading]);

    useEffect(
        () => {
            if (dz.acceptedFiles.length > 0) {
                if (oneOnly) {
                    const file = dz.acceptedFiles[dz.acceptedFiles.length - 1];
                    dz.acceptedFiles = [file];
                    setFiles([file]);
                } else {
                    setFiles([...files, ...dz.acceptedFiles.filter(f => !files.includes(f))]);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [oneOnly, dz.acceptedFiles],
    );

    return (
        <MyModal
            className="DocumentUploadModal"
            mobileTitle={mobileTitle}
            close={handleClose}
            header={<PageHeader title={imagesOnly ? 'Upload image' : 'Upload documents'} />}
        >
            <div {...dz.getRootProps({ className: 'DocumentUploadModal__Dropzone' })}>
                <p>
                    {oneOnly ? (
                        <b>Drag a file here, or click to open file picker</b>
                    ) : (
                        <b>Drag some files here, or click to open file picker</b>
                    )}
                </p>
                <p>
                    Accepted file extensions are{' '}
                    {imagesOnly ? (
                        <em>jpg, jpeg, png</em>
                    ) : (
                        <em>jpg, jpeg, png, pdf, doc, docx, csv, txt, xls, xlsx, msg</em>
                    )}
                </p>
                <p>Max file size is 25MB.</p>
                <input {...dz.getInputProps()} />
            </div>

            {files.length > 0 && (
                <ul className="DocumentUploadModal__FileList">
                    {files.map((file, index) => (
                        <FileItem
                            key={index}
                            file={file}
                            disabled={isUploading}
                            onRemove={handleRemoveFile}
                        />
                    ))}
                </ul>
            )}

            <div className="DocumentUploadModal__Footer">
                <MyButton
                    label="Cancel"
                    buttonType="Hollow"
                    onClick={handleClose}
                    disabled={isUploading}
                />
                <MyButton
                    label="Upload now"
                    buttonType="Primary"
                    IconLeft={Icons.Upload}
                    onClick={handleUpload}
                    isLoading={isUploading}
                    disabled={!files.length}
                />
            </div>
        </MyModal>
    );
}

function FileItem({
    file,
    disabled,
    onRemove,
}: {
    file: File;
    disabled: boolean;
    onRemove?: (file: File) => void;
}) {
    const sizeKb = Math.round(file.size / 1024);
    const type = getDocumentFriendlyType(file.name);
    return (
        <li
            className={coalesceClassNames(
                'DocumentUploadModal__FileItem',
                disabled && 'DocumentUploadModal__FileItem--disabled',
            )}
        >
            <DocumentFileIcon type={type} />
            <div className="DocumentUploadModal__FileItem__Name">{file.name}</div>
            <div>{sizeKb === 0 ? <>{file.size} bytes</> : <>{sizeKb} kb</>}</div>
            <MyButton
                className="DocumentUploadModal__FileItem__RemoveButton"
                IconLeft={Icons.Close}
                onClick={() => onRemove?.(file)}
                title="Remove"
                buttonType="None"
                size="small"
                disabled={disabled}
            />
        </li>
    );
}
