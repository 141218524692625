import { delay, http, HttpResponse } from 'msw';

let healthCheckCount = 0;

// this mock is used to simulate the health check endpoint
// it will simulate a new version of the app after 5 calls for testing purposes
export const authHandlers = [
    http.get(`/health.json`, async () => {
        healthCheckCount++;
        const result = healthCheckCount >= 5 ? 'invalid' : process.env.REACT_APP_GIT_HASH;
        await delay(500);

        return HttpResponse.json({
            GIT_COMMIT: result,
        });
    }),
];
