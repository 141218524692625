import Rollbar from 'rollbar';

declare global {
    interface Window {
        BACKEND_URL: string;
        IFRAME_BASE_URL: string;
        ROLLBAR_ENABLED: boolean;
        ROLLBAR_ACCESS_TOKEN: string;
        ROLLBAR_ENV: string;
        ROLLBAR_INSTANCE: Rollbar | undefined;
        POSTHOG_ENABLED: boolean;
        POSTHOG_API_KEY: string;
        POSTHOG_HOST: string;

        // Loaded via api call to /old/auth/env-js
        HOSTED_DOMAIN: string | undefined;
        HOSTED_DOMAIN_NAME: string | undefined;
        HOSTED_DOMAIN_BACKGROUND_COLOR: string | undefined;
        HOSTED_DOMAIN_LOGO: string | undefined;
        HOSTED_DOMAIN_NAV_LOGO: string | undefined;
        HOSTED_DOMAIN_MOBILE_LOGO: string | undefined;

        // used for luxon date time conversion
        // set by auth slice restoreSession
        COMPANY_TIMEZONE: string;
    }
}
/**
 * Environment variables
 */
export default {
    NODE_ENV: process.env.NODE_ENV,
    API_BASE_URL: window.BACKEND_URL as string,
    MSW_ENABLED: process.env.REACT_APP_MSW_ENABLED === 'true',

    get isProd() {
        return this.NODE_ENV === 'production';
    },
    get isDev() {
        return this.NODE_ENV === 'development';
    },
    get isTest() {
        return this.NODE_ENV === 'test';
    },
};
