import MyButton from 'components/MyButton/MyButton';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import Dialog from './Dialog';

export type AlertProps = {
    acceptLabel?: React.ReactNode;
    className?: string;
    title?: React.ReactNode;
    message?: React.ReactNode;
    close?: () => void;
};

export default function Alert({
    acceptLabel = 'OK',
    className,
    close = () => {},
    title,
    message,
}: AlertProps) {
    return (
        <Dialog
            className={coalesceClassNames('DialogManager__dialog--alert', className)}
            title={title}
            message={message}
            close={close}
            buttons={
                <MyButton
                    buttonType="Primary"
                    onClick={close}
                >
                    {acceptLabel}
                </MyButton>
            }
        />
    );
}
