import { faker } from '@faker-js/faker';
import OrderLineError from 'features/orders/enums/OrderLineError';
import { generateFakeProductConfiguration } from 'features/orders/faker/OrdersFaker';
import { generateTuid } from 'utils/helpers';
import QuoteStatus from '../enums/QuoteStatus';
import { QuoteDetail } from '../models/QuoteDetail';
import { QuoteOrderLine } from '../models/QuoteOrderLine';

export const mockedQuotes: QuoteDetail[] = [];

const generateFakeOrderLine = (): QuoteOrderLine => {
    const costPrice = faker.number.int({ min: 10, max: 100 });
    const configuration = generateFakeProductConfiguration();

    return {
        id: faker.number.int(),
        quantity: faker.number.int({ min: 1, max: 10 }),
        costPrice,
        sellPrice: costPrice * 1.1,
        windowGroupName: faker.lorem.sentence(),
        orderWindowId: faker.number.int(),
        notes: faker.lorem.sentence(),

        context: {
            configuration,
            errors: configuration.optionGroups.length ? [] : [OrderLineError.OptionsNotSelected],
            product: {
                id: faker.number.int(),
                name: faker.commerce.productName(),
                hasWidth: true,
                hasHeight: true,
                hasQuantity: true,
                context: {
                    brandName: faker.company.name(),
                    categoryName: faker.commerce.department(),
                },
            },
        },
    };
};

export function generateFakeQuoteDetail(id?: string, dealId?: string): QuoteDetail {
    const quote: QuoteDetail = {
        id: id ?? faker.string.uuid(),
        externalId: faker.number.int(),
        createdAt: faker.date.recent().toISOString(),
        tuid: generateTuid('Q'),
        description: faker.lorem.words(10),
        status: faker.helpers.enumValue(QuoteStatus),
        totalSellPrice: faker.number.float({ min: 100, max: 10000, precision: 2 }),
        totalSellPriceOverride:
            faker.helpers.maybe(() => faker.number.float({ min: 100, max: 10000, precision: 2 }), {
                probability: 0.2,
            }) ?? null,
        isArchived: faker.datatype.boolean(),
        isReadOnly: faker.datatype.boolean(),
        context: {
            totalQuantity: faker.number.int({ min: 0, max: 200 }),
            orderLineCount: faker.number.int({ min: 0, max: 200 }),
            orderLines: faker.helpers.multiple(generateFakeOrderLine, {
                count: { min: 0, max: 50 },
            }),
            deal: {
                id: dealId ?? faker.string.uuid(),
                tuid: generateTuid('D'),
                isArchived: faker.datatype.boolean(),
                workflowId: faker.string.uuid(),
                customerName: faker.company.name(),
                description: faker.lorem.words(10),
                statusId: faker.string.uuid(),
                sortOrder: faker.number.bigInt().toString(),
            },
            workflow: {
                id: '1',
                name: 'Sales',
            },
            pendingUpdates: null,
        },
        isQuote: true as const,
        isPurchase: false as const,
    };
    mockedQuotes.push(quote);
    return quote;
}
