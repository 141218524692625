// taken from https://www.npmjs.com/package/react-smooth-dnd 0.11.1
// brought in manually because of weird npm dependency issues
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { constants } from 'smooth-dnd';

const { wrapperClass } = constants;

export interface DraggableProps {
    render?: () => React.ReactElement;
    className?: string;
    disableTouch?: boolean;
}

class Draggable extends Component<DraggableProps> {
    public static propsTypes = {
        render: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        if (this.props.render) {
            return React.cloneElement(this.props.render(), { className: wrapperClass });
        }

        const clsName = `${this.props.className ? `${this.props.className} ` : ''}`;
        return (
            <div
                // {...this.props}
                className={`${clsName}${wrapperClass}`}
                onTouchStart={e => {
                    if (this.props.disableTouch) {
                        e.stopPropagation();
                    }
                }}
            >
                {/* eslint-disable-next-line react/prop-types */}
                {this.props.children}
            </div>
        );
    }
}

export default Draggable;
