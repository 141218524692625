import { faker } from '@faker-js/faker';
import { ProductConfiguration } from '../models/ProductConfiguration';
import { ProductOptionBase, ProductOptionFabric } from '../models/ProductOption';
import { ProductOptionGroup } from '../models/ProductOptionGroup';

// generate more options
// most options should be base single option
// then sometimes multiple base options
// occasionally fabric option
export const generateFakeOptionGroup = (): ProductOptionGroup => {
    const val = faker.number.int({ min: 0, max: 9 });
    const group: ProductOptionGroup = {
        name: faker.lorem.word(),
        options: [],
    };
    // 10% chance of a showing quantity
    const showQuantity = faker.number.int({ min: 0, max: 9 }) === 0;
    const showPrice = faker.number.int({ min: 0, max: 9 }) === 0;

    if (val === 0) {
        // generate a single fabric option
        const fabricOption: ProductOptionFabric = {
            type: 'FABRIC',
            value: faker.lorem.word(),
            quantity: showQuantity ? faker.number.int({ min: 1, max: 10 }) : null,
            price: showPrice ? faker.number.float({ min: 1, max: 20, precision: 0.01 }) : null,
            itemFabricWidth: faker.number.int({ min: 10, max: 100 }),
            itemFabricRepeat: `${faker.number.int({ min: 10, max: 100 })}mm`,
            itemName: faker.commerce.productName(),
            brandName: faker.company.name(),
            color: faker.lorem.word(),
        };
        group.options.push(fabricOption);
    } else {
        // 80% chance of a single base option
        // else generate between 2 and 5 base options
        const isSingleOption = faker.number.int({ min: 0, max: 4 }) !== 0;

        faker.helpers.multiple(
            () => {
                const baseOption: ProductOptionBase = {
                    type: 'BASE',
                    value: faker.lorem.word(),
                    quantity: showQuantity ? faker.number.int({ min: 1, max: 10 }) : null,
                    price: showPrice
                        ? faker.number.float({ min: 1, max: 20, precision: 0.01 })
                        : null,
                };
                return group.options.push(baseOption);
            },
            { count: isSingleOption ? 1 : { min: 2, max: 5 } },
        );
    }

    return group;
};

export const generateFakeProductConfiguration = (): ProductConfiguration => {
    // 1 in 5 chance of no option groups
    const optionGroups =
        faker.number.int({ min: 0, max: 4 }) === 0
            ? []
            : faker.helpers.multiple(generateFakeOptionGroup, {
                  count: faker.number.int({ min: 10, max: 30 }),
              });

    return {
        width: faker.number.int({ min: 10, max: 100 }),
        height: faker.number.int({ min: 10, max: 100 }),
        optionGroups,
    };
};
