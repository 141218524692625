import { useMemo } from 'react';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';
import { ManufacturerOrder } from '../models/ManufacturerOrder';
import { PurchaseOrderDetail } from '../models/PurchaseOrderDetail';
import purchasesApi from '../purchases.api';

export default function useGetProductManufacturerOrders({
    order,
    productId,
}: {
    order: PurchaseOrderDetail;
    productId: number;
}) {
    const query = purchasesApi.useManufacturerOrdersQuery(order.id, {
        skip: order.status !== PurchaseOrderStatus.Submitted,
    });

    const orderLine = useMemo(
        () => order.context.orderLines.find(ol => ol.id === productId),
        [order.context.orderLines, productId],
    );

    const mfOrders = useMemo((): ManufacturerOrder[] => {
        if (orderLine) {
            return (
                query.data?.filter(o =>
                    o.context.orderLines.some(ol => ol.externalOrderLineId === orderLine.id),
                ) ?? []
            );
        }
        return [];
    }, [orderLine, query.data]);

    return mfOrders;
}
