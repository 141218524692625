import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import WorkflowPhase, { WorkflowPhaseDisplay } from 'features/deals/enums/WorkflowPhase';
import { WorkflowStatus } from 'features/deals/models/WorkflowStatus';
import React from 'react';
import { IconType } from 'react-icons';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkflowStatusIcon.scss';

export default function WorkflowStatusIcon({
    status,
    className,
}: {
    status?: WorkflowStatus;
    className?: string;
}) {
    let Icon: IconType;

    switch (status?.phase) {
        case WorkflowPhase.New:
            Icon = Icons.WorkflowStatusNew;
            break;
        case WorkflowPhase.Quoting:
            Icon = Icons.WorkflowStatusQuoting;
            break;
        case WorkflowPhase.InProgress:
            Icon = Icons.WorkflowStatusInProgress;
            break;
        case WorkflowPhase.Closed:
            Icon = Icons.WorkflowStatusClosed;
            break;
        case WorkflowPhase.Cancelled:
            Icon = Icons.WorkflowStatusCancelled;
            break;
        default:
            return null;
    }

    return (
        <Tooltip
            title={
                <>
                    Phase: <strong>{WorkflowPhaseDisplay.display(status?.phase)}</strong>
                </>
            }
            placement="top"
            arrow
        >
            <span className={coalesceClassNames('WorkflowStatusIcon', status?.phase, className)}>
                <Icon />
            </span>
        </Tooltip>
    );
}
