import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig } from 'axios';
import Env from 'config/Env';
import createAxiosApi from 'utils/createAxiosApi';
import { SerializableAxiosError } from 'utils/errors/axiosErrors';

const axiosApi = createAxiosApi(Env.API_BASE_URL);
const axiosBaseQuery: BaseQueryFn<AxiosRequestConfig, unknown, SerializableAxiosError> = async (
    config: AxiosRequestConfig,
) => {
    try {
        const response = await axiosApi.request<unknown, unknown>(config);
        return { data: response };
    } catch (err: any) {
        // an axios error!
        if (axios.isAxiosError(err)) {
            // strip out non-serializable parts of the error to avoid redux errors
            return {
                error: {
                    message: err.message,
                    isSerializableAxiosError: true,
                    code: err.code,
                    response: {
                        data: err.response?.data,
                        status: err.response?.status,
                        statusText: err.response?.statusText,
                    },
                },
            };
        }
        // not an axios error, just throw it
        throw err;
    }
};

export enum ApiTagType {
    AccountSettings = 'AccountSettings',
    AppVersion = 'AppVersion',
    CustomerQuote = 'CustomerQuotePayment',
    CustomerQuotePayment = 'CustomerQuotePayment',
    Deal = 'Deal',
    DealerSettings = 'DealerSettings',
    DealNotes = 'DealNotes',
    Me = 'Me',
    OrderDocument = 'OrderDocument',
    OrderProductOptions = 'OrderProductOptions',
    PurchaseOrder = 'PurchaseOrder',
    Quote = 'Quote',
    Workflow = 'Workflow',
}

export const api = createApi({
    baseQuery: axiosBaseQuery,

    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: Object.values(ApiTagType),

    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({}),
});

// export const enhancedApi = api.enhanceEndpoints({
//     endpoints: () => ({
//         getPost: () => 'test',
//     }),
// });
