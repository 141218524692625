import z from 'zod';
import BundleOperatorType from '../enums/BundleOperator';

export const BundleTemplateFieldSchema = z.object({
    templateKey: z.string(),
    label: z.string(),
    operator: z.nativeEnum(BundleOperatorType), // Factor or currency enum
    value: z.number().nullable(), // if subscribed, the current value - otherwise the default value
});

export const BundleSchema = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    exclusivityKey: z.string().nullable(), // this will be displayed in the UI
    sortOrder: z.number(),
    isSubscribed: z.boolean(),
    templateFields: z.array(BundleTemplateFieldSchema),
});

export type BundleTemplateField = z.infer<typeof BundleTemplateFieldSchema>;
export type Bundle = z.infer<typeof BundleSchema>;
