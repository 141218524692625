import { ApiTagType, api } from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import z from 'zod';
import { getDocumentFriendlyType } from './enums/DocumentFriendlyType';
import { DocumentFile, DocumentFileSchema } from './models/DocumentFile';
import { DocumentUploadResult, DocumentUploadResultSchema } from './models/DocumentUploadResult';
import { ManufacturerBasic, ManufacturerBasicSchema } from './models/ManufacturerBasic';
import {
    ProductDocumentCategory,
    ProductDocumentCategorySchema,
} from './models/ProductDocumentCategory';

export const documentsApi = api.injectEndpoints({
    endpoints: build => ({
        /** Upload a document */
        documentUpload: build.mutation<
            DocumentUploadResult,
            {
                file: File;
                brandId?: number | null;
                directUpload: boolean;
            }
        >({
            async queryFn({ file, brandId, directUpload }, _queryApi, _extraOptions, fetchWithBQ) {
                const uuid = uuidv4();
                const friendlyName = file.name.split('.')[0];
                const friendlyType = getDocumentFriendlyType(file.name);

                const formData = new FormData();
                formData.append('document', file, file.name);
                formData.append('uuid', uuid);
                formData.append('friendly_type', friendlyType ?? '');
                formData.append('friendly_name', friendlyName);
                formData.append('document_category_id', `${brandId || ''}`);
                formData.append('directUpload', directUpload ? '1' : '0');

                const response = await fetchWithBQ({
                    url: `/old/documents`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.error) {
                    return {
                        error: response.error,
                    };
                }
                return {
                    data: DocumentUploadResultSchema.parse(response.data),
                };
            },
        }),

        /** Update a document */
        documentUpdate: build.mutation<void, DocumentFile>({
            query: doc => ({
                url: `/old/documents/${doc.id}`,
                method: 'POST',
                data: {
                    ...doc,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: [ApiTagType.OrderDocument],
        }),

        /** Archive a document */
        // documentArchive: build.mutation<void, number>({
        //     query: documentId => ({
        //         url: `/old/documents/${documentId}`,
        //         method: 'POST',
        //         data: {
        //             _method: 'DELETE',
        //         },
        //     }),
        //     invalidatesTags: [ApiTagType.DocumentList],
        // }),

        /** Get the list of product document categories */
        productDocumentCategoryList: build.query<ProductDocumentCategory[], void>({
            query: () => ({
                url: `/old/product-document-categories/select`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(ProductDocumentCategorySchema),
                });
                return schema.parse(result).data;
            },
        }),

        manufacturerDocumentList: build.query<DocumentFile[], void>({
            query: () => ({
                url: `/old/manufacturer-documents`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(DocumentFileSchema),
                });
                return schema.parse(result).data;
            },
        }),

        manufacturerList: build.query<ManufacturerBasic[], void>({
            query: () => ({
                url: `/old/manufacturers`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(ManufacturerBasicSchema),
                });
                return schema.parse(result).data;
            },
        }),
    }),
});

export default documentsApi;
