export type SerializableAxiosError = {
    message: string;
    isSerializableAxiosError: boolean;
    code?: string;
    response: {
        data?: any;
        status?: number;
        statusText?: string;
    };
};

export function isSerializableAxiosError(error: any): error is SerializableAxiosError {
    return error.isSerializableAxiosError;
}

export class SerializableAxiosErrorAsError extends Error implements SerializableAxiosError {
    constructor(err: SerializableAxiosError) {
        super(err.message);
        this.isSerializableAxiosError = true;
        this.code = err.code;
        this.response = err.response;
    }

    isSerializableAxiosError: boolean;
    code?: string;
    response: {
        data?: any;
        status?: number;
        statusText?: string;
    };
}
