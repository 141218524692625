import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { FormFieldInfo, revealAllErrors, selectFormState } from 'store/forms.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showDialogAction } from 'store/showDialogAction';
import { generateShortUuid } from 'utils/helpers';

type ValidationContext = {
    formId: string;
    isValid: boolean;
    isDirty: boolean;
    fields: FormFieldInfo[];
    revealImmediately: boolean;

    handleSubmit: (e?: FormEvent) => Promise<any>;
};

export const FormValidationContext = React.createContext<ValidationContext | null>(null);
export const useValidationContext = () => useContext(FormValidationContext);

type ChildFunction = (ctx: ValidationContext) => JSX.Element;

export default function FormValidation({
    submit,
    onDirtyChanged,
    revealImmediately = false,
    toastErrors = true,
    children,
}: {
    submit?: (() => Promise<any>) | (() => void);
    onDirtyChanged?: (isDirty: boolean) => void;
    revealImmediately?: boolean;
    toastErrors?: boolean;
    children?: React.ReactFragment | ChildFunction;
}) {
    const formId = useRef(generateShortUuid());
    const formState = useAppSelector(selectFormState(formId.current));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (formState) {
            onDirtyChanged?.(formState?.isDirty);
        }
    }, [formState, formState?.isDirty, onDirtyChanged]);

    const handleSubmit = useCallback(
        async (e?: FormEvent) => {
            e?.preventDefault?.();
            if (formState?.isValid) {
                await submit?.();
            } else {
                // eslint-disable-next-line
                console.log('FormValidation', 'form is invalid');

                // find the first invalid field and get its error message
                const firstFieldWithError = formState?.fields.find(f => f.error);

                // reveal the error in the form
                if (firstFieldWithError) {
                    dispatch(
                        revealAllErrors({ formId: formId.current, key: firstFieldWithError.key }),
                    );
                }

                if (toastErrors) {
                    // display the error in a toast popup
                    const title = firstFieldWithError?.error;
                    const message = 'Review all fields and try again';

                    dispatch(
                        showDialogAction({
                            type: 'toast',
                            props: {
                                title,
                                message,
                                icon: 'wave',
                            },
                        }),
                    );
                }
            }
        },
        [dispatch, formState?.fields, formState?.isValid, submit, toastErrors],
    );

    const ctx: ValidationContext = useMemo(
        () => ({
            formId: formId.current,
            isValid: formState?.isValid || false,
            isDirty: formState?.isDirty || false,
            fields: formState?.fields || [],
            revealImmediately,

            /** Handle the user pressing the submit button
             * This is provided for external use of the validationContext
             * Where FormValidation is implemented
             */
            handleSubmit,
        }),
        [
            formState?.fields,
            formState?.isDirty,
            formState?.isValid,
            handleSubmit,
            revealImmediately,
        ],
    );

    return (
        <FormValidationContext.Provider value={ctx}>
            {children && typeof children === 'function' ? children(ctx) : children}
        </FormValidationContext.Provider>
    );
}
