import { SerializedError } from '@reduxjs/toolkit';

export class SerializedErrorAsError extends Error {
    constructor(serialisedError: ValidSerializedError) {
        super(serialisedError.message);
        this.name = serialisedError.name;
        this.stack = serialisedError.stack;
        this.code = serialisedError.code;
    }

    stack?: string;
    code?: string;
}

// a version of SerialisedError where `name` and `message` are required
type ValidSerializedError = SerializedError & {
    name: NonNullable<SerializedError['name']>;
    message: NonNullable<SerializedError['message']>;
};
export function isValidSerializedError(object: any): object is ValidSerializedError {
    return 'name' in object && 'message' in object;
}
