import { faker } from '@faker-js/faker';
import Env from 'config/Env';
import { delay, http, HttpResponse } from 'msw';
import { DashboardData } from './models/DashboardData';

const BASE_URL = `${Env.API_BASE_URL}`;

export const dashboardHandlers = [
    // Get work items by quote id
    http.get(`${BASE_URL}/dashboard`, async () => {
        const result: DashboardData = {
            quotes: {
                count: faker.number.int(100),
                value: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
            },
            sales: {
                count: faker.number.int(100),
                value: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
            },
            draft_orders: {
                count: faker.number.int(10),
                value: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
            },
            purchases: {
                count: faker.number.int(100),
                value: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
            },
            sales_by_rep: [
                {
                    name: faker.person.fullName(),
                    quotes: faker.number.int(100),
                    orders: faker.number.int(100),
                },
                {
                    name: faker.person.fullName(),
                    quotes: faker.number.int(100),
                    orders: faker.number.int(100),
                },
                {
                    name: faker.person.fullName(),
                    quotes: faker.number.int(100),
                    orders: faker.number.int(100),
                },
                {
                    name: faker.person.fullName(),
                    quotes: faker.number.int(100),
                    orders: faker.number.int(100),
                },
            ],
            top_selling_category: [
                {
                    id: faker.number.int(),
                    name: 'Roller Blinds',
                    value: faker.number.float({ min: 10, max: 1000, precision: 0.01 }),
                },
                {
                    id: faker.number.int(),
                    name: 'Shutters',
                    value: faker.number.float({ min: 10, max: 1000, precision: 0.01 }),
                },
            ],
            most_ordered_category: [
                {
                    id: faker.number.int(),
                    name: 'Shutters',
                    count: faker.number.int(100),
                },
                {
                    id: faker.number.int(),
                    name: 'Roller Blinds',
                    count: faker.number.int(100),
                },
                {
                    id: faker.number.int(),
                    name: 'Honeycomb',
                    count: faker.number.int(100),
                },
            ],
        };
        await delay(500);
        return HttpResponse.json(result);
    }),
];
