import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MyTabs.scss';

export type MyTabsTab<T extends string> = {
    name: T;
    label: React.ReactNode;
    content?: React.ReactNode;
};

export function TabBuilder<T extends string>(tabs: (false | MyTabsTab<T>)[]) {
    return tabs.filter(Boolean) as MyTabsTab<T>[];
}

export default function MyTabs<T extends string>({
    className,
    tabs,
    activeTab = '',
    setActiveTab,
    disabled = false,
    renderInactiveTabs = false,
}: {
    className?: string;
    tabs: MyTabsTab<T>[];
    activeTab?: string;
    setActiveTab?: (tab: T) => void;
    disabled?: boolean;
    renderInactiveTabs?: boolean;
}) {
    const goToTab = (tab: T) => {
        setActiveTab?.(tab);
    };

    const currentTab = tabs.find(t => t && t.name === activeTab);

    return (
        <div className={coalesceClassNames('MyTabs', className)}>
            <div className="MyTabs__Tabs">
                {tabs.map(
                    tab =>
                        tab && (
                            <button
                                key={tab.name}
                                className={coalesceClassNames(
                                    'MyTabs__Tabs__TabButton',
                                    `MyTabs__Tabs__TabButton--${tab.name}`,
                                    activeTab === tab.name ? 'active' : 'inactive',
                                )}
                                onClick={() => goToTab(tab.name)}
                                disabled={disabled}
                            >
                                {tab.label}
                            </button>
                        ),
                )}
            </div>

            <div className="MyTabs__TabPanels">
                {tabs.map(tab =>
                    tab && (tab === currentTab || renderInactiveTabs) ? (
                        <div
                            key={tab.name}
                            className={coalesceClassNames(
                                'MyTabs__TabPanel',
                                `MyTabs__TabPanel--${tab.name}`,
                                activeTab !== tab.name && 'hidden',
                            )}
                        >
                            {tab.content}
                        </div>
                    ) : null,
                )}
            </div>
        </div>
    );
}
