import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum QuoteStatus {
    Draft = 'DRAFT',
    Accepted = 'ACCEPTED',
}

export default QuoteStatus;

const labels: EnumDisplayLabels<typeof QuoteStatus> = {
    Draft: 'Draft',
    Accepted: 'Accepted',
};

export const QuoteStatusDisplay = createDisplayEnum(QuoteStatus, labels);
