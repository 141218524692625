import { FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import React, { ReactNode } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';

import FieldValidator from 'components/FieldValidator/FieldValidator';
import { FlagValue } from 'features/deals/models/FlagValue';
import { WorkflowFlag } from 'features/deals/models/WorkflowFlag';
import DealFlagPill from '../DealFlagPill/DealFlagPill';
import './DealFlagSelect.scss';

export type DealFlagSelectProps = {
    flag: WorkflowFlag;
    allowBlank?: boolean;
    blankValue?: string;
    value?: string;
    handleInput?: (flagValue?: FlagValue) => void;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
} & SelectProps;

export default function DealFlagSelect({
    /** the selected option id */
    value,
    /** WorkflowFlag which we use to construct options */
    flag,
    handleInput,
    validationKey,
    validationRequired,
    validationCustom,
    ...props
}: DealFlagSelectProps) {
    const onChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
        const id = e.target.value as string;
        const flagValue = flag.values.find(v => v.id === id);
        if (handleInput) {
            handleInput(flagValue);
        } else if (props.onChange) {
            props.onChange(e, child);
        }
    };

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
            value={value}
        >
            {({ revealError, error }) => (
                <FormControl
                    className={coalesceClassNames(
                        'DealFlagSelect',
                        props.className,
                        error && 'DealFlagSelect--error',
                        props.label ? 'DealFlagSelect--with-label' : 'DealFlagSelect--no-label',
                    )}
                    size="small"
                >
                    <Select
                        {...props}
                        value={value}
                        className={coalesceClassNames(
                            'DealFlagSelect__Select',
                            props.className && `${props.className}__Select`,
                        )}
                        size="small"
                        onChange={onChange}
                        onBlur={revealError}
                        onClose={revealError}
                        MenuProps={{
                            className: 'DealFlagSelect__Menu',
                        }}
                    >
                        {flag.values.map((val, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val.id}
                                >
                                    <DealFlagPill
                                        value={val}
                                        size="large"
                                    />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
        </FieldValidator>
    );
}
