import { Tooltip } from '@mui/material';
import Kanban, { KanbanColumnDefinition } from 'components/Kanban/Kanban';
import dealsApi from 'features/deals/deals.api';
import WorkflowPhase from 'features/deals/enums/WorkflowPhase';
import { Deal } from 'features/deals/models/Deal';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import DealFlagPill from '../DealFlagPill/DealFlagPill';
import WorkflowStatusIcon from '../WorkflowStatusIcon/WorkflowStatusIcon';
import './DealsKanban.scss';

type DealsKanbanColumnData = { phase: WorkflowPhase };

export default function DealsKanban({ workflow }: { workflow: WorkflowDetail }) {
    const columns = workflow.context.statuses.map(
        (status): KanbanColumnDefinition<Deal, DealsKanbanColumnData> => ({
            id: status.id,
            data: { phase: status.phase },
            title: (
                <div className="DealsKanban__ColumnTitle">
                    <WorkflowStatusIcon status={status} /> {status.name}
                </div>
            ),
            group: status.phase,
            cardSelector: deal => deal.statusId === status.id,
        }),
    );

    const query = dealsApi.useWorkflowDealListQuery(workflow.id);
    const [moveMutation] = dealsApi.useDealMoveMutation();

    const dialogManager = useDialogManager();

    const handleCardDrop = useCallback(
        async (
            item: Deal,
            destColumn: KanbanColumnDefinition<Deal, DealsKanbanColumnData>,
            after: Deal | undefined,
        ) => {
            const result = await moveMutation({
                dealId: item.id,
                currentStatusId: item.statusId,
                targetStatusId: destColumn.id,
                belowId: after?.id ?? null,
                belowSortOrder: after?.sortOrder ?? null,
                workflowId: workflow.id,
            }).unwrap();
            if (result === 'conflict') {
                dialogManager.toast({
                    title: "Oops! That didn't work",
                    icon: 'wave',
                    message: 'We had trouble making that change, please try again',
                });
            }
        },
        [dialogManager, moveMutation, workflow.id],
    );

    return (
        <Kanban
            columns={columns}
            data={query.data}
            renderCard={(item, column) => (
                <Card
                    deal={item}
                    phase={column.data.phase}
                />
            )}
            onCardDrop={handleCardDrop}
            cardLinkTo={deal => deal.id}
        />
    );
}

function Card({ deal, phase }: { deal: Deal; phase: WorkflowPhase }) {
    const displayFlags = deal.context.flagValues.filter(flag => flag.showOnCard);

    return (
        <div className="DealsKanban__Card">
            <div className="DealsKanban__Card__Title">
                <div className="DealsKanban__Card__Tuid">{deal.tuid}</div>
                <div className="DealsKanban__Card__Counts">
                    <Tooltip
                        title="Quotes"
                        placement="top"
                        arrow={true}
                    >
                        <span
                            className={coalesceClassNames(
                                'DealsKanban__Card__Counts__Number',
                                getQuoteCountDisplayType(deal.context.quoteCount, phase),
                            )}
                        >
                            {deal.context.quoteCount}
                        </span>
                    </Tooltip>
                    <Tooltip
                        title="Orders"
                        placement="top"
                        arrow={true}
                    >
                        <span
                            className={coalesceClassNames(
                                'DealsKanban__Card__Counts__Number',
                                getOrderCountDisplayType(deal.context.orderCount, phase),
                            )}
                        >
                            {deal.context.orderCount}
                        </span>
                    </Tooltip>
                </div>
            </div>
            <div className="DealsKanban__Card__Customer">{deal.customerName || <>&nbsp;</>}</div>
            {deal.description && (
                <div className="DealsKanban__Card__Description">{deal.description}</div>
            )}
            <div className="DealsKanban__Card__Flags">
                {displayFlags.map(flag => (
                    <DealFlagPill
                        key={flag.workflowFlagId}
                        value={{
                            id: flag.workflowFlagValueId,
                            label: flag.label,
                            appearance: flag.appearance,
                        }}
                        size="small"
                    />
                ))}
            </div>
            {/* <div className="DealsKanban__Card__SalesRep">
                <Icons.Person />
                {deal.salesRep}
            </div> */}
        </div>
    );
}

function getQuoteCountDisplayType(
    quoteCount: number,
    phase?: WorkflowPhase,
): 'warning' | 'active' | 'default' {
    if (quoteCount === 0) {
        if (phase === WorkflowPhase.Quoting || phase === WorkflowPhase.InProgress) {
            return 'warning';
        }
    }
    if (quoteCount > 0) {
        if (phase === WorkflowPhase.New) {
            return 'warning';
        }
        if (phase === WorkflowPhase.Quoting) {
            return 'active';
        }
    }
    return 'default';
}
function getOrderCountDisplayType(
    orderCount: number,
    phase?: WorkflowPhase,
): 'warning' | 'active' | 'default' {
    if (orderCount === 0) {
        if (phase === WorkflowPhase.InProgress || phase === WorkflowPhase.Closed) {
            return 'warning';
        }
    }
    if (orderCount > 0) {
        if (phase === WorkflowPhase.New || phase === WorkflowPhase.Quoting) {
            return 'warning';
        }
        if (phase === WorkflowPhase.InProgress) {
            return 'active';
        }
    }
    return 'default';
}
