// taken from https://www.npmjs.com/package/react-smooth-dnd 0.11.1
// brought in manually because of weird npm dependency issues
import React, { Component, CSSProperties } from 'react';
import PropTypes from 'prop-types';
import { smoothDnD as container, ContainerOptions, SmoothDnD, dropHandlers } from 'smooth-dnd';

container.dropHandler = dropHandlers.reactDropHandler().handler;
container.wrapChild = false;

interface DndContainerProps extends ContainerOptions {
    render?: (rootRef: React.RefObject<any>) => React.ReactElement;
    style?: CSSProperties;
}

class DndContainer extends Component<DndContainerProps> {
    public static propTypes = {
        behaviour: PropTypes.oneOf(['move', 'copy', 'drop-zone', 'contain']),
        groupName: PropTypes.string,
        orientation: PropTypes.oneOf(['horizontal', 'vertical']),
        style: PropTypes.object,
        dragHandleSelector: PropTypes.string,
        nonDragAreaSelector: PropTypes.string,
        dragBeginDelay: PropTypes.number,
        animationDuration: PropTypes.number,
        autoScrollEnabled: PropTypes.bool,
        lockAxis: PropTypes.string,
        dragClass: PropTypes.string,
        dropClass: PropTypes.string,
        onDragStart: PropTypes.func,
        onDragEnd: PropTypes.func,
        onDrop: PropTypes.func,
        getChildPayload: PropTypes.func,
        shouldAnimateDrop: PropTypes.func,
        shouldAcceptDrop: PropTypes.func,
        onDragEnter: PropTypes.func,
        onDragLeave: PropTypes.func,
        render: PropTypes.func,
        getGhostParent: PropTypes.func,
        removeOnDropOut: PropTypes.bool,
        dropPlaceholder: PropTypes.oneOfType([
            PropTypes.shape({
                className: PropTypes.string,
                animationDuration: PropTypes.number,
                showOnTop: PropTypes.bool,
            }),
            PropTypes.bool,
        ]),
    };

    public static defaultProps = {
        behaviour: 'move',
        orientation: 'vertical',
    };

    prevContainer: null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    container: SmoothDnD = null!;
    containerRef: React.RefObject<any> = React.createRef();
    constructor(props: DndContainerProps) {
        super(props);
        this.getContainerOptions = this.getContainerOptions.bind(this);
        this.getContainer = this.getContainer.bind(this);
        this.isObjectTypePropsChanged = this.isObjectTypePropsChanged.bind(this);
        this.prevContainer = null;
    }

    componentDidMount() {
        this.prevContainer = this.getContainer();
        this.container = container(this.getContainer(), this.getContainerOptions());
    }

    componentWillUnmount() {
        this.container.dispose();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.container = null!;
    }

    componentDidUpdate(prevProps: DndContainerProps) {
        if (this.getContainer()) {
            if (this.prevContainer && this.prevContainer !== this.getContainer()) {
                this.container.dispose();
                this.container = container(this.getContainer(), this.getContainerOptions());
                this.prevContainer = this.getContainer();
                return;
            }

            if (this.isObjectTypePropsChanged(prevProps)) {
                this.container.setOptions(this.getContainerOptions());
            }
        }
    }

    isObjectTypePropsChanged(prevProps: DndContainerProps) {
        const { render, children, style, ...containerOptions } = this.props;

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const _key in containerOptions) {
            const key = _key as keyof ContainerOptions;
            // eslint-disable-next-line no-prototype-builtins
            if (containerOptions.hasOwnProperty(key)) {
                const prop = containerOptions[key];

                if (typeof prop !== 'function' && prop !== prevProps[key]) {
                    return true;
                }
            }
        }

        return false;
    }

    render() {
        if (this.props.render) {
            return this.props.render(this.containerRef);
        }
        return (
            <div
                style={this.props.style}
                ref={this.containerRef}
            >
                {this.props.children}
            </div>
        );
    }

    getContainer() {
        return this.containerRef.current;
    }

    getContainerOptions(): ContainerOptions {
        return Object.keys(this.props).reduce((hash, key: string) => {
            const optionName = key as keyof ContainerOptions;
            const prop = this.props[optionName];

            if (typeof prop === 'function') {
                hash[optionName] = (...params: any[]) => {
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    return (this.props[optionName] as Function)(...params);
                };
            } else {
                hash[optionName] = prop;
            }

            return hash;
        }, {} as { [key: string]: any }) as ContainerOptions;
    }
}

export default DndContainer;
