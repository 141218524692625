import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum MeasureSystem {
    Metric = 'METRIC',
    Imperial = 'IMPERIAL',
}

export default MeasureSystem;

const labels: EnumDisplayLabels<typeof MeasureSystem> = {
    Metric: 'Metric',
    Imperial: 'Imperial',
};

export const MeasureSystemDisplay = createDisplayEnum(MeasureSystem, labels);
