import { z } from 'zod';
import { WorkflowSchema } from './Workflow';
import { WorkflowFlagSchema } from './WorkflowFlag';
import { WorkflowStatusSchema } from './WorkflowStatus';

export const WorkflowDetailSchema = WorkflowSchema.extend({
    context: z.object({
        statuses: z.array(WorkflowStatusSchema),
        flags: z.array(WorkflowFlagSchema),
    }),
});

export type WorkflowDetail = z.infer<typeof WorkflowDetailSchema>;
