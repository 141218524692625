import { addListener, configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import Env from 'config/Env';
import authReducer from 'features/auth/auth.slice';
import customerQuotesReducer from 'features/customerQuotes/customerQuotes.slice';
import dashboardReducer from 'features/dashboard/dashboard.slice';
import ordersReducer from 'features/orders/orders.slice';
import purchasesReducer from 'features/purchases/purchases.slice';
import quotesReducer from 'features/quotes/quotes.slice';
import settingsReducer from 'features/settings/settings.slice';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import formsReducer from './forms.slice';
// import logger from 'redux-logger';
import { api } from 'services/api';
import apiErrorMiddleware from './apiErrorMiddleware';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    reduxTravelling: Env.isDev,
    savePreviousLocations: 1,
});

const makeStore = () => {
    const store = configureStore({
        reducer: {
            [api.reducerPath]: api.reducer,
            auth: authReducer,
            customerQuotes: customerQuotesReducer,
            dashboard: dashboardReducer,
            forms: formsReducer,
            orders: ordersReducer,
            purchases: purchasesReducer,
            quotes: quotesReducer,
            settings: settingsReducer,
            router: routerReducer,
        },
        devTools: Env.isDev,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore warnings on these action types
                    ignoredActions: [addListener.type],
                },
            })
                .concat(createListenerMiddleware().middleware)
                .concat(routerMiddleware)
                // .concat(logger)
                .concat(api.middleware)
                .concat(apiErrorMiddleware),
    });

    return store;
};

export const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const history = createReduxHistory(store);
