import Icons from 'Icons';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { generateShortUuid, isEmpty } from 'utils/helpers';
import './PropertyEditMoney.scss';

export type PropertyEditMoneyProps = {
    className?: string;
    value?: number;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val?: number) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
    min?: number;
    max?: number;
    allowBlank?: boolean;
};

export default function PropertyEditMoney({
    className,
    value,
    label,
    hint,
    inlineHint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
    min,
    max,
    allowBlank,
}: PropertyEditMoneyProps) {
    const [strVal, setStrVal] = useState<string>(formatValue(value));

    useEffect(() => {
        setStrVal(isEmpty(value) ? '' : `${value}`);
    }, [value]);

    const handleInput = useCallback(
        (val: string) => {
            setStrVal(val);

            if (isEmpty(val)) {
                if (allowBlank) {
                    onChange?.(undefined);
                }
            }
            const num = parseFloat(val);
            if (!Number.isNaN(num)) {
                onChange?.(num);
            }
        },
        [allowBlank, onChange],
    );

    // Reset the input string value on blur
    const handleBlur = useCallback(() => {
        setStrVal(formatValue(value));
    }, [value]);

    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                className={className}
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={value}
            />
        );
    }
    return (
        <PropertyEdit
            className={coalesceClassNames('PropertyEditMoney', className)}
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
        >
            <FieldValidator
                value={value}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            >
                <div className="PropertyEditMoney__InputWrapper">
                    <MyTextInput
                        className="PropertyEditMoney__Input"
                        id={inputId}
                        value={strVal}
                        type="number"
                        min={min}
                        max={max}
                        disabled={disabled}
                        handleInput={handleInput}
                        onBlur={handleBlur}
                        LeftIcon={Icons.Dollar}
                    />
                </div>
            </FieldValidator>
        </PropertyEdit>
    );
}

function formatValue(val?: number) {
    const str = val?.toFixed(2) ?? '';
    return str.replace('.00', '');
}
