import { Popover } from '@mui/material';
import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import MeasureSystem from 'features/settings/enums/MeasureSystem';
import React, { KeyboardEvent, useCallback, useEffect, useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isNone } from 'utils/helpers';
import { formatMeasurement, fractionToDecimal } from 'utils/measurementHelper';
import './Numpad.scss';

export default function Numpad({
    className,
    open,
    anchorEl,
    close,
    onSave,
    value,
    measureSystem = MeasureSystem.Metric,
    allowEighths = false,
    allowSixteenths = false,
}: {
    className?: string;
    open: boolean;
    anchorEl: HTMLElement | null;
    close?: () => void;
    onSave?: (val: number | undefined) => void;
    value?: number;
    measureSystem?: MeasureSystem;
    allowEighths?: boolean;
    allowSixteenths?: boolean;
}) {
    const [strValue, setStrValue] = React.useState('');
    const placeholder = useMemo(() => formatMeasurement(value), [value]);
    const [showPlaceholder, setShowPlaceholder] = React.useState(true);

    const allowedFractions = useMemo(() => {
        let result = ['1/2', '1/4', '3/4'];
        if (allowEighths) {
            result = [...result, '1/8', '3/8', '5/8', '7/8'];
        }
        if (allowSixteenths) {
            result = [...result, '1/16', '3/16', '5/16', '7/16', '9/16', '11/16', '13/16', '15/16'];
        }
        return result;
    }, [allowEighths, allowSixteenths]);

    const calculateDecimalValue = useCallback(
        strVal => {
            if (strVal.trim() === '') {
                return undefined;
            }

            let numValue: number;
            if (measureSystem === MeasureSystem.Imperial) {
                // parse fractions
                const [first, second] = strVal.trim().split(' ');
                const firstIsFraction = first.includes('/');
                const wholeNo = firstIsFraction ? 0 : parseInt(first || '0', 10);
                const fraction = firstIsFraction ? first : second;
                numValue = wholeNo + fractionToDecimal(fraction);

                // chek that fraction is an allowed value
                if (fraction) {
                    if (!allowedFractions.includes(fraction)) {
                        return undefined;
                    }
                    if (fraction.endsWith('/8') && !allowEighths) {
                        return undefined;
                    }
                    if (fraction.endsWith('/16') && !allowSixteenths) {
                        return undefined;
                    }
                }
            } else {
                // metric - just parseInt
                numValue = parseInt(strVal, 10);
            }
            if (Number.isNaN(numValue)) {
                return undefined;
            }
            return numValue;
        },
        [allowEighths, allowSixteenths, allowedFractions, measureSystem],
    );

    const decimalValue = useMemo(
        () => calculateDecimalValue(strValue),
        [calculateDecimalValue, strValue],
    );

    const isValid = useMemo(
        () => showPlaceholder || !strValue || !isNone(decimalValue),
        [decimalValue, showPlaceholder, strValue],
    );

    const applyAndClose = useCallback(() => {
        if (showPlaceholder) {
            // close without saving
            close?.();
        } else if (isValid) {
            onSave?.(decimalValue);
            close?.();
        }
    }, [close, decimalValue, isValid, onSave, showPlaceholder]);

    const typeDigit = useCallback(
        (ch: string, isKeyboard?: boolean) => {
            if (ch === ' ') {
                if (
                    measureSystem === MeasureSystem.Imperial &&
                    strValue.length &&
                    !strValue.includes(' ')
                ) {
                    setStrValue(strValue + ch);
                }
                return;
            }

            if (ch === '/') {
                if (
                    measureSystem === MeasureSystem.Imperial &&
                    strValue.length &&
                    strValue.includes(' ') &&
                    !strValue.includes('/')
                ) {
                    setStrValue(strValue + ch);
                }
                return;
            }

            // else its a digit 0-9
            if (!isKeyboard && strValue.includes(' ')) {
                // includes a fraction, next digit resets the value
                setStrValue(ch);
            } else {
                setStrValue(strValue + ch);
            }
            setShowPlaceholder(false);
        },
        [measureSystem, strValue],
    );

    const typeFraction = useCallback(
        (fr: string) => {
            const wholeNo = strValue.split(' ')[0];
            if (fr) {
                const newVal = `${wholeNo} ${fr}`;
                setStrValue(newVal);
                const decimal = calculateDecimalValue(newVal);
                onSave?.(decimal);
                close?.();
            } else {
                setStrValue(wholeNo);
            }
            setShowPlaceholder(false);
        },
        [calculateDecimalValue, close, onSave, strValue],
    );

    const clearInput = useCallback(() => {
        setStrValue('');
        setShowPlaceholder(false);
    }, []);

    const backspace = useCallback(() => {
        if (showPlaceholder) {
            clearInput();
        } else {
            setStrValue(strValue.slice(0, -1));
        }
    }, [clearInput, showPlaceholder, strValue]);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if ((e.key >= '0' && e.key <= '9') || e.key === ' ' || e.key === '/') {
                e.preventDefault();
                e.stopPropagation(); // prevent storybook from interrupting
                typeDigit(e.key, true);
            } else if (e.key === 'Backspace') {
                backspace();
            } else if (e.key === 'Enter') {
                e.preventDefault();
                applyAndClose();
            } else if (e.key === 'Escape') {
                e.preventDefault();
                close?.();
            } else if (e.key === 'Tab') {
                if (showPlaceholder) {
                    close?.();
                } else {
                    applyAndClose();
                }
            }
        },
        [applyAndClose, backspace, close, showPlaceholder, typeDigit],
    );

    // reset strValue when opening the numpad
    useEffect(() => {
        if (open) {
            setStrValue(``);
            setShowPlaceholder(true);
        }
    }, [open]);

    const onClose = useCallback(() => {
        if (isValid) {
            applyAndClose();
        } else {
            close?.();
        }
    }, [applyAndClose, close, isValid]);

    return (
        <Popover
            className={coalesceClassNames('Numpad', className)}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            disableEscapeKeyDown={true}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onKeyDown={handleKeyDown}
        >
            <div className="Numpad__Container">
                <div className="Numpad__Display">
                    {showPlaceholder ? (
                        <span className="placeholder">{placeholder}</span>
                    ) : (
                        strValue
                    )}
                </div>

                <div className="Numpad__Main">
                    <div className="Numpad__Main__ButtonGrid">
                        <div className="Numpad__Main__ButtonGrid__Row">
                            <MyButton
                                className="Numpad__DigitButton"
                                label="7"
                                buttonType="Secondary"
                                onClick={() => typeDigit('7')}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="8"
                                buttonType="Secondary"
                                onClick={() => typeDigit('8')}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="9"
                                buttonType="Secondary"
                                onClick={() => typeDigit('9')}
                            />
                        </div>
                        <div className="Numpad__Main__ButtonGrid__Row">
                            <MyButton
                                className="Numpad__DigitButton"
                                label="4"
                                buttonType="Secondary"
                                onClick={() => typeDigit('4')}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="5"
                                buttonType="Secondary"
                                onClick={() => typeDigit('5')}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="6"
                                buttonType="Secondary"
                                onClick={() => typeDigit('6')}
                            />
                        </div>
                        <div className="Numpad__Main__ButtonGrid__Row">
                            <MyButton
                                className="Numpad__DigitButton"
                                label="1"
                                buttonType="Secondary"
                                onClick={() => typeDigit('1')}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="2"
                                buttonType="Secondary"
                                onClick={() => typeDigit('2')}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="3"
                                buttonType="Secondary"
                                onClick={() => typeDigit('3')}
                            />
                        </div>
                        <div className="Numpad__Main__ButtonGrid__Row">
                            <MyButton
                                className="Numpad__DigitButton"
                                IconRight={Icons.Backspace}
                                buttonType="Primary"
                                onClick={backspace}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="0"
                                buttonType="Secondary"
                                onClick={() => typeDigit('0')}
                            />
                            <MyButton
                                className="Numpad__DigitButton"
                                label="CLR"
                                buttonType="Danger"
                                onClick={clearInput}
                            />
                        </div>
                    </div>

                    {/* Fractions for Imperial input */}
                    {measureSystem === MeasureSystem.Imperial && (
                        <div className="Numpad__Main__ButtonGrid">
                            <div className="Numpad__Main__ButtonGrid__Row">
                                <MyButton
                                    className="Numpad__FractionButton"
                                    label={<>&ndash;</>}
                                    buttonType="None"
                                    onClick={() => typeFraction('')}
                                />
                                {allowEighths && (
                                    <MyButton
                                        className="Numpad__FractionButton"
                                        label={<FractionLabel value="1/8" />}
                                        buttonType="None"
                                        onClick={() => typeFraction('1/8')}
                                    />
                                )}
                                {allowSixteenths && (
                                    <>
                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="1/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('1/16')}
                                        />

                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="3/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('3/16')}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="Numpad__Main__ButtonGrid__Row">
                                <MyButton
                                    className="Numpad__FractionButton"
                                    label={<FractionLabel value="1/4" />}
                                    buttonType="None"
                                    onClick={() => typeFraction('1/4')}
                                />
                                {allowEighths && (
                                    <MyButton
                                        className="Numpad__FractionButton"
                                        label={<FractionLabel value="3/8" />}
                                        buttonType="None"
                                        onClick={() => typeFraction('3/8')}
                                    />
                                )}
                                {allowSixteenths && (
                                    <>
                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="5/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('5/16')}
                                        />
                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="7/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('7/16')}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="Numpad__Main__ButtonGrid__Row">
                                <MyButton
                                    className="Numpad__FractionButton"
                                    label={<FractionLabel value="1/2" />}
                                    buttonType="None"
                                    onClick={() => typeFraction('1/2')}
                                />
                                {allowEighths && (
                                    <MyButton
                                        className="Numpad__FractionButton"
                                        label={<FractionLabel value="5/8" />}
                                        buttonType="None"
                                        onClick={() => typeFraction('5/8')}
                                    />
                                )}
                                {allowSixteenths && (
                                    <>
                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="9/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('9/16')}
                                        />
                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="11/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('11/16')}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="Numpad__Main__ButtonGrid__Row">
                                <MyButton
                                    className="Numpad__FractionButton"
                                    label={<FractionLabel value="3/4" />}
                                    buttonType="None"
                                    onClick={() => typeFraction('3/4')}
                                />
                                {allowEighths && (
                                    <MyButton
                                        className="Numpad__FractionButton"
                                        label={<FractionLabel value="7/8" />}
                                        buttonType="None"
                                        onClick={() => typeFraction('7/8')}
                                    />
                                )}
                                {allowSixteenths && (
                                    <>
                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="13/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('13/16')}
                                        />
                                        <MyButton
                                            className="Numpad__FractionButton"
                                            label={<FractionLabel value="15/16" />}
                                            buttonType="None"
                                            onClick={() => typeFraction('15/16')}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="Numpad__Footer">
                    <MyButton
                        className="Numpad__DigitButton"
                        label="Cancel"
                        buttonType="Hollow"
                        onClick={close}
                    />
                    <MyButton
                        className="Numpad__DigitButton"
                        label="Enter"
                        buttonType="Accent"
                        onClick={applyAndClose}
                        disabled={!isValid}
                    />
                </div>
            </div>
        </Popover>
    );
}

function FractionLabel({ value }: { value: string }) {
    const [numerator, denominator] = value.split('/');
    return (
        <div className="Numpad__FractionLabel">
            <span className="numerator">{numerator}</span>
            <span className="divider">/</span>
            <span className="denominator">{denominator}</span>
        </div>
    );
}
