import LinkOrDiv from 'components/LinkOrDiv/LinkOrDiv';
import React from 'react';
import { isEmpty } from 'utils/helpers';
import './InfoGridTable.scss';

function InfoGridTable({ children }: { children: React.ReactNode }) {
    return <div className="InfoGridTable">{children}</div>;
}

/** Row for displaying option or label variables */
function InfoGridRow({
    label,
    value,
    onClick,
}: {
    label: React.ReactNode;
    value: React.ReactNode;
    onClick?: () => void;
}) {
    return (
        <LinkOrDiv
            className="InfoGridTable__InfoGridRow"
            onClick={onClick}
        >
            <div className="InfoGridTable__InfoGridRow__Label">{label}</div>
            <div className="InfoGridTable__InfoGridRow__Value">
                {isEmpty(value) ? <span className="empty">&ndash;</span> : value}
            </div>
        </LinkOrDiv>
    );
}

export { InfoGridRow, InfoGridTable };
