import { Tooltip } from '@mui/material';
import ManufacturerOrderCoreStatus from 'features/purchases/enums/ManufacturerOrderCoreStatus';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import React from 'react';
import { ManufacturerOrderCoreStatusColorBlock } from '../ManufacturerOrderCoreStatusColorBlock/ManufacturerOrderCoreStatusColorBlock';
import PurchaseOrderStatusBadge from '../PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import './CoreStatusProgress.scss';

/** Progress bar showing a summary of manufacturer orders and their core statuses
 * This only appears on purchase orders with status SUBMITTED
 */
export default function CoreStatusProgress({
    data,
}: {
    data: {
        CANCELLED: number;
        COMPLETED: number;
        IN_PROGRESS: number;
        ON_HOLD: number;
        UNSTARTED: number;
    };
}) {
    const total =
        data.CANCELLED + data.COMPLETED + data.IN_PROGRESS + data.ON_HOLD + data.UNSTARTED;
    const percentCancelled = (data.CANCELLED / total) * 100;
    const percentCompleted = (data.COMPLETED / total) * 100;
    const percentInProgress = (data.IN_PROGRESS / total) * 100;
    const percentOnHold = (data.ON_HOLD / total) * 100;
    const percentUnstarted = (data.UNSTARTED / total) * 100;

    return (
        <Tooltip
            title={<TooltipContent data={data} />}
            placement="top"
            disableInteractive={true}
            arrow
        >
            <div className="CoreStatusProgress">
                <div
                    className="CoreStatusProgress__Progress CANCELLED"
                    style={{ width: `${percentCancelled}%` }}
                ></div>
                <div
                    className="CoreStatusProgress__Progress COMPLETED"
                    style={{ width: `${percentCompleted}%` }}
                ></div>
                <div
                    className="CoreStatusProgress__Progress IN_PROGRESS"
                    style={{ width: `${percentInProgress}%` }}
                ></div>
                <div
                    className="CoreStatusProgress__Progress ON_HOLD"
                    style={{ width: `${percentOnHold}%` }}
                ></div>
                <div
                    className="CoreStatusProgress__Progress UNSTARTED"
                    style={{ width: `${percentUnstarted}%` }}
                ></div>
            </div>
        </Tooltip>
    );
}

function TooltipContent({
    data,
}: {
    data: {
        CANCELLED: number;
        COMPLETED: number;
        IN_PROGRESS: number;
        ON_HOLD: number;
        UNSTARTED: number;
    };
}) {
    return (
        <div className="CoreStatusProgress__Tooltip">
            <div className="CoreStatusProgress__Tooltip__Status">
                <div className="CoreStatusProgress__Tooltip__Status__Label">Status</div>
                <div className="CoreStatusProgress__Tooltip__Status__Value">
                    <PurchaseOrderStatusBadge
                        status={PurchaseOrderStatus.Submitted}
                        size="small"
                    />
                </div>
            </div>

            <TooltipCoreStatus
                status={ManufacturerOrderCoreStatus.Cancelled}
                count={data.CANCELLED}
            />
            <TooltipCoreStatus
                status={ManufacturerOrderCoreStatus.Completed}
                count={data.COMPLETED}
            />
            <TooltipCoreStatus
                status={ManufacturerOrderCoreStatus.InProgress}
                count={data.IN_PROGRESS}
            />
            <TooltipCoreStatus
                status={ManufacturerOrderCoreStatus.OnHold}
                count={data.ON_HOLD}
            />
            <TooltipCoreStatus
                status={ManufacturerOrderCoreStatus.Unstarted}
                count={data.UNSTARTED}
            />
        </div>
    );
}

function TooltipCoreStatus({
    status,
    count,
}: {
    status: ManufacturerOrderCoreStatus;
    count: number;
}) {
    if (count === 0) {
        return null;
    }

    return (
        <div className="CoreStatusProgress__TooltipCoreStatus">
            <ManufacturerOrderCoreStatusColorBlock coreStatus={status} />
            <div className="CoreStatusProgress__TooltipCoreStatus__Value">{count}</div>
        </div>
    );
}
