import MyEditModal from 'components/MyEditModal/MyEditModal';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { QuoteEditable, QuoteEditableFactory } from 'features/quotes/models/QuoteEditable';
import quotesApi from 'features/quotes/quotes.api';
import React, { useCallback, useMemo } from 'react';
import QuoteEditFields from '../QuoteEditFields/QuoteEditFields';
import './QuoteEditModal.scss';

export default function QuoteEditModal({
    order,
    close,
}: {
    order: QuoteDetail;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = quotesApi.useQuoteUpdateMutation();

    const model = useMemo(
        () =>
            QuoteEditableFactory.create({
                id: order.id,
                description: order.description,
            }),
        [order],
    );

    const save = useCallback(
        async (editModel: QuoteEditable) => {
            await saveMutation(editModel).unwrap();
            close?.();
        },
        [close, saveMutation],
    );

    return (
        <MyEditModal
            className="QuoteEditModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="Edit details"
            titleContext={order.tuid}
            mobileTitle="Quote"
        >
            {({ editModel, updateField, isSaving }) => (
                <QuoteEditFields
                    editModel={editModel}
                    isSaving={isSaving}
                    updateField={updateField}
                />
            )}
        </MyEditModal>
    );
}
