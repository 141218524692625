import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import ManufacturerOrderCoreStatus, {
    ManufacturerOrderCoreStatusDisplay,
} from '../../enums/ManufacturerOrderCoreStatus';
import './ManufacturerOrderCoreStatusColorBlock.scss';

export function ManufacturerOrderCoreStatusColorBlock({
    coreStatus,
}: {
    coreStatus: ManufacturerOrderCoreStatus | null;
}) {
    return (
        <div
            className={coalesceClassNames(
                'ManufacturerOrderCoreStatusColorBlock',
                coreStatus ?? '',
            )}
        >
            {ManufacturerOrderCoreStatusDisplay.display(coreStatus)}
        </div>
    );
}
