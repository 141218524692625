import { MyToggleSwitchInput } from 'components/MyToggleSwitchInput/MyToggleSwitchInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import { DataTableFilterConfigBase, FilterCompProps } from '../DataTableFilterTypes';

export type ToggleFilterConfig<T> = DataTableFilterConfigBase & {
    type: 'toggle';
    getField: (item: T) => string | null | undefined;
    allowBlank?: boolean;
    trueValue?: string;
    falseValue?: string;
};

export default function ToggleFilter<T>({
    filter,
    onChange,
}: FilterCompProps<ToggleFilterConfig<T>>) {
    const trueValue = filter.config.trueValue ?? 'true';
    const falseValue = filter.config.falseValue ?? 'false';
    const handleCheckedChanged = (checked: boolean) => {
        const val = checked ? trueValue : falseValue;
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <MyToggleSwitchInput
            className={coalesceClassNames(
                'ToggleFilter',
                'DataTableFilters__Field',
                'DataTableFilters__Field--ToggleFilter',
                filter.config.className,
            )}
            label={filter.config.label}
            value={filter.value === trueValue}
            onChange={handleCheckedChanged}
        />
    );
}

/** Default applyFilter function for toggle filters */
export function applyToggleFilter<T>(config: ToggleFilterConfig<T>, value: string, item: T) {
    let val = config.getField(item);
    if (isEmpty(val)) {
        val = '';
    }
    return String(val) === value;
}
