import MySelectInput, { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import { DataTableFilterConfigBase, FilterCompProps } from '../DataTableFilterTypes';

export type SelectFilterConfig<T> = DataTableFilterConfigBase & {
    type: 'select';
    getField: (item: T) => string | null | undefined;
    options?: MySelectInputOption[];
    allowBlank?: boolean;
};

export default function SelectFilter<T>({
    filter,
    onChange,
}: FilterCompProps<SelectFilterConfig<T>>) {
    const handleInput = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--SelectFilter',
                filter.config.className,
            )}
        >
            <MySelectInput
                label={filter.config.label}
                value={filter.value}
                options={filter.config.options}
                handleInput={handleInput}
                allowBlank={filter.config.allowBlank ?? true}
            />
        </div>
    );
}

/** Default applyFilter function for select filters */
export function applySelectFilter<T>(config: SelectFilterConfig<T>, value: string, item: T) {
    let val = config.getField(item);
    if (isEmpty(val)) {
        val = '';
    }
    return String(val) === value;
}
