import MySelectInput from 'components/MySelectInput/MySelectInput';
import { DateTime } from 'luxon';

import {
    dateFilterOptionsAny,
    dateFilterOptionsFuture,
    dateFilterOptionsPast,
} from 'models/DateFilterOption';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { DataTableFilterConfigBase, FilterCompProps } from '../DataTableFilterTypes';

export type DateFilterConfig<T> = DataTableFilterConfigBase & {
    type: 'date';
    getField: (item: T) => string | null | undefined;
    range?: 'past' | 'future' | 'any';
};

export default function DateFilter<T>({ filter, onChange }: FilterCompProps<DateFilterConfig<T>>) {
    const handleInput = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableFilters__Field',
                'DataTableFilters__Field--DateFilter',
                filter.config.className,
            )}
        >
            <MySelectInput
                label={filter.config.label}
                value={filter.value}
                options={
                    filter.config.range === 'future'
                        ? dateFilterOptionsFuture
                        : filter.config.range === 'past'
                        ? dateFilterOptionsPast
                        : dateFilterOptionsAny
                }
                handleInput={handleInput}
                allowBlank={true}
            />
        </div>
    );
}

/** Default applyFilter function for date filters */
export function applyDateFilter<T>(config: DateFilterConfig<T>, value: string, item: T) {
    const minDate = dateFilterOptionsAny.find(d => d.value === value)?.minDate;
    const maxDate = dateFilterOptionsAny.find(d => d.value === value)?.maxDate;

    if (minDate && maxDate) {
        const val = config.getField(item);
        if (!val) {
            return false;
        }
        const date = DateTime.fromISO(val).valueOf();
        return date >= minDate.valueOf() && date <= maxDate.valueOf();
    }
    return true;
}
