import { useEffect } from 'react';

export type WindowMessage = {
    data?: {
        type: string;
        data?: unknown;
    };
};

/** returns true if param is null/undefined or an empty array/string */
export function useWindowOnMessage(func: (ev: WindowMessage) => void) {
    return useEffect(() => {
        const onMessage = (e: WindowMessage) => {
            func(e);
        };

        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }, [func]);
}
