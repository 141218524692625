import z from 'zod';
import ManufacturerOrderCoreStatus from '../enums/ManufacturerOrderCoreStatus';

export const ManufacturerOrderStatusSchema = z.object({
    id: z.number(),
    name: z.string(),
    coreStatus: z.nativeEnum(ManufacturerOrderCoreStatus),
});

export type ManufacturerOrderStatus = z.infer<typeof ManufacturerOrderStatusSchema>;
