import z from 'zod';
import { FlagValueSchema } from './FlagValue';

export const WorkflowFlagSchema = z.object({
    id: z.string(),
    name: z.string(),
    values: z.array(FlagValueSchema),
});

export type WorkflowFlag = z.infer<typeof WorkflowFlagSchema>;
