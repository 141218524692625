import MeasurementDisplay from 'components/MeasurementDisplay/MeasurementDisplay';
import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import { QuoteOrderLine } from 'features/quotes/models/QuoteOrderLine';
import React from 'react';
import { StrictUnion } from 'utils/typeHelpers';
import './ProductDimensionDisplay.scss';

export function ProductDimensionDisplay({
    orderLine,
}: {
    orderLine: StrictUnion<PurchaseOrderLine | QuoteOrderLine>;
}) {
    const hasWidth = orderLine.context.product.hasWidth;
    const hasHeight = orderLine.context.product.hasHeight;
    const width = orderLine.context.configuration.width;
    const height = orderLine.context.configuration.height;

    if (hasWidth && hasHeight) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay value={width} />
                    &nbsp;&times;&nbsp;
                    <MeasurementDisplay value={height} />
                </div>
            </div>
        );
    }
    if (hasWidth) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay
                        value={width}
                        showMetricUnit
                    />
                </div>
            </div>
        );
    }
    if (hasHeight) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay
                        value={height}
                        showMetricUnit
                    />
                </div>
            </div>
        );
    }
    return null;
}
