import z from 'zod';

export const DashboardDataSchema = z.object({
    quotes: z.object({
        count: z.number().nullable(),
        value: z.number().nullable(),
    }),
    sales: z.object({
        count: z.number().nullable(),
        value: z.number().nullable(),
    }),
    draft_orders: z.object({
        count: z.number().nullable(),
        value: z.number().nullable(),
    }),
    purchases: z.object({
        count: z.number().nullable(),
        value: z.number().nullable(),
    }),
    sales_by_rep: z.array(
        z.object({
            name: z.string(),
            quotes: z.number(),
            orders: z.number(),
        }),
    ),
    top_selling_category: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            value: z.number(),
        }),
    ),
    most_ordered_category: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            count: z.number(),
        }),
    ),
});

export type DashboardData = z.infer<typeof DashboardDataSchema>;
