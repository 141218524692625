import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import CustomerQuotePaymentStatus from '../enums/CustomerQuotePaymentStatus';

export const CustomerQuotePaymentSchema = z.object({
    date: dateTimeInstant(),
    paymentMethod: z.string(), // e.g "Credit Card via Payment Gateway",
    amount: z.number(),
    status: z.nativeEnum(CustomerQuotePaymentStatus),
});

export type CustomerQuotePayment = z.infer<typeof CustomerQuotePaymentSchema>;
