import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './DealFlagPill.scss';
import FlagAppearance from '../../enums/FlagAppearance';

export default function DealFlagPill({
    value,
    size = 'small',
}: {
    value: {
        appearance: FlagAppearance;
        label: string;
        id: string;
    };
    size: 'small' | 'large';
}) {
    return (
        <div
            key={value.id}
            className={coalesceClassNames('DealFlagPill', size, value.appearance)}
        >
            {value.label}
        </div>
    );
}
