import QuoteriteHeaderLogo from 'assets/quoterite-icon-with-name-black.svg';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import CustomerQuoteMain from 'features/customerQuotes/components/CustomerQuoteMain/CustomerQuoteMain';
import { fakeCustomerQuoteDetails } from 'features/customerQuotes/faker/CustomerQuoteFaker';
import { CustomerQuotePaymentSummary } from 'features/customerQuotes/models/CustomerQuotePaymentSummary';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';
import './CustomerQuotePage.scss';

export default function CustomerQuotePage() {
    usePageTitle('Quote');

    const { id } = useParams() as { id: string };
    const query = useFakeQuoteDetailQuery();
    const paymentsQuery = useFakePaymentSummarQuery(id);
    const hostedImageUrl = window.HOSTED_DOMAIN_MOBILE_LOGO || window.HOSTED_DOMAIN_NAV_LOGO;

    return (
        <div className="CustomerQuotePage">
            {/* Topbar with logo */}
            <div className="CustomerQuotePage__TopBar">
                {hostedImageUrl ? (
                    <img
                        className="CustomerQuotePage__TopBar__Logo--HostedImage"
                        src={hostedImageUrl}
                        alt="Logo"
                    />
                ) : (
                    <img
                        className="CustomerQuotePage__TopBar__Logo--DefaultImage"
                        src={QuoteriteHeaderLogo}
                        alt="Logo"
                    />
                )}
            </div>
            {query.isLoading ? (
                <div className="CustomerQuotePage__Loading">
                    <MyLinearProgress />
                </div>
            ) : query.isError || !query.data ? (
                <div className="CustomerQuotePage__Error">
                    <h1>Quote not found</h1>
                    <p>
                        Sorry, we couldn't find the quote you're looking for. It may have expired or
                        the url may be incorrect.
                    </p>
                    <p>Please double check the link you are aiming for and try again.</p>
                </div>
            ) : (
                <>
                    <CustomerQuoteMain
                        model={query.data}
                        paymentSummary={paymentsQuery.data}
                    />
                    <Outlet />
                </>
            )}
        </div>
    );
}

function useFakeQuoteDetailQuery() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    return {
        isLoading,
        isError: false,
        data: isLoading ? undefined : fakeCustomerQuoteDetails[0],
    };
}

function useFakePaymentSummarQuery(quoteId: string) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    const paymentSummary: CustomerQuotePaymentSummary = {
        id: quoteId,
        totalReceived: 0,
        balance: fakeCustomerQuoteDetails[0].order.total,
        payments: [],
    };

    return {
        isLoading,
        isError: false,
        data: isLoading ? undefined : paymentSummary,
    };
}
