import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkflowPhase {
    New = 'NEW',
    Quoting = 'QUOTING',
    InProgress = 'IN_PROGRESS',
    Closed = 'CLOSED',
    Cancelled = 'CANCELLED',
}

export default WorkflowPhase;

const labels: EnumDisplayLabels<typeof WorkflowPhase> = {
    New: 'New',
    Quoting: 'Quoting',
    InProgress: 'In Progress',
    Closed: 'Closed',
    Cancelled: 'Cancelled',
};

export const WorkflowPhaseDisplay = createDisplayEnum(WorkflowPhase, labels);
