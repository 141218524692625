import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './PropertyContainer.scss';

export default function PropertyContainer({
    className,
    layout = 'column',
    withInputSpacing = false,
    fullWidthInputs = false,
    spreadRow = false,
    indent = false,
    children,
}: {
    className?: string;
    layout?: 'column' | 'row' | 'table';
    withInputSpacing?: boolean;
    fullWidthInputs?: boolean;
    spreadRow?: boolean;
    indent?: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            className={coalesceClassNames(
                'PropertyContainer',
                `PropertyContainer--${layout}`,
                withInputSpacing && 'PropertyContainer--with-input-spacing',
                fullWidthInputs && 'PropertyContainer--full-width-inputs',
                spreadRow && 'PropertyContainer--spread-row',
                indent && 'PropertyContainer--indent',
                className,
            )}
        >
            {children}
        </div>
    );
}
