import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import PurchaseOrderStatus, {
    PurchaseOrderStatusDisplay,
} from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrder } from 'features/purchases/models/PurchaseOrder';
import purchasesApi, { PurchaseOrderListParams } from 'features/purchases/purchases.api';
import {
    selectIsMultiSupplier,
    selectManufacturer,
    selectManufacturers,
} from 'features/settings/settings.slice';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import CoreStatusProgress from '../CoreStatusProgress/CoreStatusProgress';
import PurchaseOrderStatusBadge from '../PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import './PurchaseOrdersTable.scss';

export default function PurchaseOrdersTable() {
    const [urlParams] = useSearchParams();
    const manufacturers = useAppSelector(selectManufacturers);
    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);

    const salesRepOptions = useSalesRepOptions({ includeNotSet: true });

    const criteriaFields = useMemo(
        () =>
            CriteriaBuilder<PurchaseOrderListParams>()
                .criteria({
                    field: 'search',
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                    urlParam: 'search',
                })
                .criteria(
                    isMultiSupplier
                        ? {
                              field: 'manufacturer_id',
                              label: 'Supplier',
                              type: 'select',
                              options: manufacturers?.map(m => ({
                                  label: m.name,
                                  value: m.id.toString(),
                              })),
                              defaultValue: '',
                              allowBlank: true,
                              urlParam: 'manufacturer',
                          }
                        : false,
                )
                .criteria({
                    field: 'sales_rep_override',
                    label: 'Sales rep',
                    type: 'select',
                    options: salesRepOptions,
                    defaultValue: '',
                    allowBlank: true,
                    urlParam: 'rep',
                })
                .criteria({
                    field: 'date',
                    label: 'Date',
                    type: 'date',
                    range: 'past',
                    blankValue: 'all',
                    defaultValue: '-30',
                    urlParam: 'date',
                })
                .criteria({
                    field: 'status',
                    label: 'Status',
                    type: 'select',
                    options: PurchaseOrderStatusDisplay.options,
                    defaultValue: '',
                    allowBlank: true,
                    urlParam: 'status',
                })
                .criteria({
                    field: 'archived',
                    label: 'Archived',
                    type: 'toggle',
                    defaultValue: 'false',
                    urlParam: 'archived',
                })
                .build(),
        [isMultiSupplier, manufacturers, salesRepOptions],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery(criteriaFields);

    const query = purchasesApi.usePurchaseOrderListQuery(queryParams);

    const handleSortChanged = useCallback(
        (col: DataTableColumn<PurchaseOrder>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    const breakpoints = useBreakpoints();

    const columns = useMemo(
        () =>
            ColumnBuilder<PurchaseOrder>()
                .column({
                    label: 'Order',
                    key: 'tuid',
                    render: item =>
                        breakpoints.isLargeOnly ? (
                            item.tuid
                        ) : (
                            <OrderCell
                                key={item.tuid}
                                item={item}
                            />
                        ),
                })
                .column({
                    label: 'Sidemark',
                    key: 'sidemark',
                    render: item => item.sidemark,
                })
                .column(
                    breakpoints.isLargeOnly &&
                        isMultiSupplier && {
                            label: 'Supplier',
                            key: 'manufacturerId',
                            render: item =>
                                manufacturers?.find(m => item.manufacturerId === m.id)?.name,
                        },
                )
                .column(
                    breakpoints.isMediumUp && {
                        label: 'Sales rep',
                        key: 'salesRep',
                        render: item => item.salesRep,
                    },
                )
                .column(
                    breakpoints.isLargeOnly && {
                        label: 'Date',
                        key: 'createdAt',
                        render: item => formatDateTimeRelative(item.createdAt),
                    },
                )
                .column(
                    breakpoints.isLargeOnly && {
                        label: 'Products',
                        key: 'products',
                        align: 'center',
                        render: item => (item.totalQuantity > 0 ? item.totalQuantity : null),
                    },
                )
                .column({
                    label: 'Total',
                    key: 'total',
                    render: (item: PurchaseOrder) =>
                        breakpoints.isLargeOnly ? (
                            item.totalCost > 0 && formatCurrency(item.totalCost)
                        ) : (
                            <TotalCell
                                key={item.id}
                                item={item}
                            />
                        ),
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    width: '1px',
                    render: item => <StatusCell item={item} />,
                })
                .build(),
        [breakpoints.isLargeOnly, breakpoints.isMediumUp, isMultiSupplier, manufacturers],
    );

    return (
        <div className="PurchasesTable">
            <div className="PurchasesTable__FilterBar">
                <DataTableCriteria
                    fields={criteriaFields}
                    onChange={setQueryCriteria}
                    onRefresh={breakpoints.isSmallDown ? query.refetch : undefined}
                    isRefreshing={query.isFetching}
                />
            </div>

            <DataTable
                className="PurchasesTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data?.data}
                rowLinkTo={item => `${item.id}?${urlParams}`}
                zebra="light"
                useStickyHeader={true}
                useFrontEndSorting={false}
                onSortChanged={handleSortChanged}
                columns={columns}
                showHeader={breakpoints.isMediumUp ? 'full' : 'none'}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
            />

            {(query.data?.total ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setQueryPaging}
                />
            )}
        </div>
    );
}

function OrderCell({ item }: { item: PurchaseOrder }) {
    const breakpoints = useBreakpoints();
    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);
    const manufacturerName = useAppSelector(selectManufacturer(item.manufacturerId))?.name;
    const supplierName = manufacturerName ?? 'Unknown';
    return (
        <div className="PurchasesTable__OrderCell">
            <div className="PurchasesTable__OrderCell__Id">{item.tuid}</div>
            <div className="PurchasesTable__OrderCell__Date">
                {formatDateRelative(item.createdAt, {
                    hideDayName: breakpoints.isSmallDown,
                })}
            </div>
            {isMultiSupplier && <div className="">{supplierName}</div>}
        </div>
    );
}

function TotalCell({ item }: { item: PurchaseOrder }) {
    return (
        <div className="PurchasesTable__TotalCell">
            {item.totalQuantity > 0 && (
                <>
                    <div className="count">
                        {item.totalQuantity}{' '}
                        <small>{item.totalQuantity === 1 ? 'product' : 'products'}</small>
                    </div>
                    <div className="price">
                        {item.totalCost > 0 && formatCurrency(item.totalCost)}
                    </div>
                </>
            )}
        </div>
    );
}

function StatusCell({ item }: { item: PurchaseOrder }) {
    return (
        <div className="PurchasesTable__StatusCell">
            {item.status === PurchaseOrderStatus.Submitted ? (
                <CoreStatusProgress data={item.context.coreStatus} />
            ) : (
                <PurchaseOrderStatusBadge
                    status={item.status}
                    size="small"
                />
            )}
            {item.isArchived && <ArchivedBadge size="small" />}
        </div>
    );
}
