import { faker } from '@faker-js/faker';
import Env from 'config/Env';
import { delay, http, HttpResponse } from 'msw';
import { generateFakeQuoteDetail, mockedQuotes } from './faker/QuotesFaker';

const BASE_URL = `${Env.API_BASE_URL}`;

export const quotesHandlers = [
    http.get(`${BASE_URL}/deals/:dealId/quotes`, async ({ params }) => {
        await delay(500);
        return HttpResponse.json(
            faker.helpers.multiple(
                () => generateFakeQuoteDetail(undefined, params.dealId as string),
                {
                    count: 5,
                },
            ),
        );
    }),
    http.get(`${BASE_URL}/quotes/:id`, async ({ params }) => {
        await delay(500);
        const quote = mockedQuotes.find(q => q.id === params.id);
        if (quote) {
            return HttpResponse.json(quote);
        }
        return HttpResponse.json(generateFakeQuoteDetail(params.id as string));
    }),
    http.post(`${BASE_URL}/quotes/:id/clone`, async () => {
        await delay(500);
        return HttpResponse.json({ id: faker.string.uuid() });
    }),
];
