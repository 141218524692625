import { faker } from '@faker-js/faker';
import Env from 'config/Env';
import { http, HttpResponse } from 'msw';
import { wait } from 'utils/helpers';
import {
    fakeManufacturerOrderStatuses,
    fakePurchaseOrderDetails,
    generateFakeManufacturerOrder,
} from './faker/PurchaseOrderFaker';
import { PurchaseOrder } from './models/PurchaseOrder';

const BASE_URL = `${Env.API_BASE_URL}`;

export const purchasesHandlers = [
    http.post(`${BASE_URL}/purchaseorder`, async ({ request }) => {
        const body = (await request.json()) as {
            filters: {
                search: string;
                start: string;
                end: string;
                is_archived: boolean;
                sales_rep_override: string;
            };
            pagination: {
                limit: number;
                offset: number;
            };
        };

        // map detail model to list model
        const data = fakePurchaseOrderDetails
            .slice(body.pagination.offset, body.pagination.limit)
            .map(
                (d): PurchaseOrder => ({
                    isPurchase: d.isPurchase,
                    isQuote: d.isQuote,
                    status: d.status,
                    id: d.id,
                    tuid: d.tuid,
                    createdAt: d.createdAt,
                    isArchived: d.isArchived,
                    salesRep: d.salesRep,
                    sidemark: d.sidemark ?? '',
                    customerDetails: d.customerDetails ?? '',
                    totalCost: d.totalCost,
                    totalQuantity: d.totalQuantity,
                    manufacturerId: d.manufacturerId,
                    context: {
                        coreStatus: {
                            UNSTARTED: faker.number.int({ min: 0, max: 100 }),
                            IN_PROGRESS: faker.number.int({ min: 0, max: 100 }),
                            ON_HOLD: faker.number.int({ min: 0, max: 100 }),
                            COMPLETED: faker.number.int({ min: 0, max: 100 }),
                            CANCELLED: faker.number.int({ min: 0, max: 100 }),
                        },
                    },
                }),
            );

        const result = {
            data,
            total: fakePurchaseOrderDetails.length,
        };
        await wait(500);
        return HttpResponse.json(result);
    }),

    // purchase order details
    http.get(`${BASE_URL}/purchaseorder/:id`, async ({ params }) => {
        const id = params.id as string;
        const result = fakePurchaseOrderDetails.find(p => `${p.id}` === id);
        if (!result) {
            return new HttpResponse(`Purchase order not found`, { status: 400 });
        }
        await wait(500);
        return HttpResponse.json(result);
    }),

    // manufacturer orders
    http.get(`${BASE_URL}/purchaseorder/:id/manufacturerorders`, async ({ params }) => {
        const id = params.id as string;
        const po = fakePurchaseOrderDetails.find(p => `${p.id}` === id);
        if (!po) {
            return new HttpResponse(`Purchase order not found`, { status: 400 });
        }

        const result = [generateFakeManufacturerOrder(po)];
        await wait(500);
        return HttpResponse.json(result);
    }),

    // manufacturer order statuses
    http.get(`${BASE_URL}/manufacturer/:id/order-statuses`, async () => {
        const result = fakeManufacturerOrderStatuses;
        await wait(500);
        return HttpResponse.json(result);
    }),
];
