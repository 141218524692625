import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import useShippingMethodOptions from 'features/purchases/helpers/useShippingMethodOptions';
import { Manufacturer } from 'features/settings/models/Manufacturer';
import { ManufacturerCustomerSettings } from 'features/settings/models/ManufacturerCustomerSettings';
import settingsApi from 'features/settings/settings.api';
import React from 'react';
import EditSection from '../EditSection/EditSection';
import './SupplierSettingsEditable.scss';

export default function SupplierSettingsEditable({ manufacturer }: { manufacturer: Manufacturer }) {
    const shippingMethodOptions = useShippingMethodOptions(manufacturer);

    const [updateCustomerMutation, updateCustomerMutationState] =
        settingsApi.useManufacturerCustomerSettingsUpdateMutation();

    const manufacturerCustomerSettingsUpdate = async (editModel: ManufacturerCustomerSettings) => {
        await updateCustomerMutation({
            manufacturerId: manufacturer.id ?? 0,
            settings: editModel,
        });
    };

    return (
        <PropertyContainer className="SupplierSettingsEditable">
            {manufacturer && (
                <EditSection
                    title={manufacturer.name}
                    model={manufacturer.customer_settings}
                    onSave={manufacturerCustomerSettingsUpdate}
                    isSaving={updateCustomerMutationState.isLoading}
                >
                    {({ editModel, updateField, isEditing, isSaving }) => (
                        <PropertyContainer layout="table">
                            <PropertyDisplay
                                label="Email"
                                verticalAlign="top"
                                value={
                                    <a
                                        className="SupplierSettingsEditable__Email Link"
                                        href={`mailto:${manufacturer.email}`}
                                    >
                                        {manufacturer.email}
                                    </a>
                                }
                            />

                            <PropertyEditSelect
                                label="Default shipping method"
                                value={editModel.default_shipping_method_id ?? ''}
                                options={shippingMethodOptions}
                                allowBlank={true}
                                onChange={(val: string) => {
                                    const id = parseInt(val, 10);
                                    updateField?.({
                                        default_shipping_method_id: Number.isNaN(id) ? null : id,
                                    });
                                }}
                                disabled={isSaving}
                                readonly={!isEditing}
                            />
                        </PropertyContainer>
                    )}
                </EditSection>
            )}
        </PropertyContainer>
    );
}
