import z from 'zod';
import { DealSchema } from './Deal';
import { WorkflowSchema } from './Workflow';
import { WorkflowStatusSchema } from './WorkflowStatus';

export const DealDetailSchema = DealSchema.extend({
    context: z.object({
        ...DealSchema.shape.context.shape,
        status: WorkflowStatusSchema,
        workflow: WorkflowSchema,
    }),
});

export type DealDetail = z.infer<typeof DealDetailSchema>;
