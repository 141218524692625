import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type PurchasesState = {
    lastUsedManufacturerId?: number;
};

const getInitialState = (): PurchasesState => {
    const lastUsedManufacturerId = localStorage.getItem('lastUsedManufacturerId') ?? '';
    return {
        lastUsedManufacturerId: parseInt(lastUsedManufacturerId, 10) || undefined,
    };
};

const slice = createSlice({
    name: 'purchases',
    initialState: getInitialState(),
    reducers: {
        setLastUsedManufacturerId(state, action: PayloadAction<number | undefined>) {
            if (action.payload) {
                localStorage.setItem('lastUsedManufacturerId', `${action.payload}`);
            } else {
                localStorage.removeItem('lastUsedManufacturerId');
            }
            state.lastUsedManufacturerId = action.payload;
        },
    },
    // extraReducers: builder => {},
});

export const { setLastUsedManufacturerId } = slice.actions;

export default slice.reducer;

export const selectLastUsedManufacturerId = (state: RootState) => {
    let id = state.purchases.lastUsedManufacturerId;
    if (!id || !state.settings.manufacturers?.some(m => m.id === id)) {
        // default to the first manufacturer id
        // there should always be at least one manufacturer otherwise the app is in a bad state
        id = state.settings.manufacturers?.[0]?.id;
    }
    if (id) {
        return id;
    }
    throw new Error('selectLastUsedManufacturerId: No manufacturers found');
};
