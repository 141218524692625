import { Drawer } from '@mui/material';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import React, { useRef } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MyDrawer.scss';

/** Drawer menu which becomes a bottom sheet on mobile */
export default function MyDrawer({
    isOpen = true,
    onClose,
    children,
    className,
    header,
    anchor = 'bottom',
    hideBackdrop = false,
    isLoading = false,
    isError = false,
}: {
    isOpen?: boolean;
    onClose?: () => void;
    children: React.ReactNode;
    className?: string;
    header?: React.ReactFragment;
    anchor?: 'bottom' | 'right';
    hideBackdrop?: boolean;
    isLoading?: boolean;
    isError?: boolean;
}) {
    const modalRootTop = useRef(document.querySelector('#modal-root-top'));

    return (
        <Drawer
            open={isOpen}
            onClose={onClose}
            className={coalesceClassNames('MyDrawer', `MyDrawer--anchor-${anchor}`, className)}
            anchor={anchor}
            hideBackdrop={hideBackdrop}
            container={modalRootTop.current}
        >
            <div
                className={coalesceClassNames(
                    'MyDrawer__Drawer',
                    className && `${className}__Drawer`,
                )}
            >
                {header && (
                    <div className="MyDrawer__Drawer__Header">
                        {header}
                        {/* <div className="MyDrawer__Drawer__Header__Title">{title}</div>
                        <MyButton
                            className="MyDrawer__Drawer__Header__CloseButton"
                            onClick={onClose}
                            buttonType="None"
                        >
                            <Icons.Close className="icon" />
                        </MyButton> */}
                    </div>
                )}
                <div className="MyDrawer__Drawer__Body">
                    {isLoading ? (
                        <div className="MyDrawer__Drawer__Loading">
                            <MyLinearProgress />
                        </div>
                    ) : isError ? (
                        <ErrorContent className="MyDrawer__Drawer__Error" />
                    ) : (
                        children
                    )}
                </div>
            </div>
        </Drawer>
    );
}
